import React, { useRef } from "react";
import { useDrop, useDrag, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Card, Box, IconButton, useMediaQuery } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import MDTypography from "shared/components/MDComponents/MDTypography";
import UnifiedDropzone from "shared/components/UIElements/Dropzone/UnifiedDropzone";
import { useTheme } from "@mui/material/styles";

function PlaylistDroppableArea({
  items,
  handleDrop,
  handleRemoveItem,
  onMoveItem,
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  return (
    <DndProvider backend={HTML5Backend}>
      <UnifiedDropzone
        accept="media"
        onDrop={(item) => {
          if (!item.hasOwnProperty("index")) {
            item.uniqueId = `${item.id}-${Date.now()}`;
            handleDrop(item);
          }
        }}
      >
        {items.length === 0 ? (
          <MDTypography
            variant="caption"
            display="block"
            gutterBottom
            sx={{ textAlign: "center", marginTop: 2 }}
          >
            Drag items here to add them to the playlist
          </MDTypography>
        ) : (
          items.map((item, index) => (
            <DraggableItem
              key={item.uniqueId}
              item={item}
              index={index}
              onRemoveItem={handleRemoveItem}
              onMoveItem={onMoveItem}
              isSmallScreen={isSmallScreen}
              isMediumScreen={isMediumScreen}
            />
          ))
        )}
      </UnifiedDropzone>
    </DndProvider>
  );
}

const DraggableItem = ({
  item,
  index,
  onRemoveItem,
  onMoveItem,
  isSmallScreen,
  isMediumScreen,
}) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: "media",
    hover: (draggedItem, monitor) => {
      if (!ref.current) return;
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (draggedItem.index === undefined) return;

      if (draggedItem.index !== index) {
        const threshold = 10;

        if (
          draggedItem.index < index &&
          hoverClientY < hoverMiddleY - threshold
        ) {
          return;
        }
        if (
          draggedItem.index > index &&
          hoverClientY > hoverMiddleY + threshold
        ) {
          return;
        }

        onMoveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "media",
    item: { ...item, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const isVideo = (url) => {
    const videoExtensions = ["mp4", "mpeg", "mov", "mkv"];
    const extension = url.split(".").pop().toLowerCase();
    return videoExtensions.includes(extension);
  };

  return (
    <Card
      ref={ref}
      sx={{
        opacity: isDragging ? 0.5 : 1,
        marginBottom: 1,
        width: isSmallScreen ? "100%" : isMediumScreen ? "80%" : "60%",
      }}
    >
      <Box
        display="flex"
        flexDirection={isSmallScreen ? "column" : "row"}
        alignItems={isSmallScreen ? "stretch" : "center"}
        justifyContent="space-between"
        p={2}
      >
        {isVideo(item.url) ? (
          <Box
            sx={{
              width: isSmallScreen ? "100%" : "80px",
              height: "80px",
              borderRadius: "10px",
              overflow: "hidden",
              marginBottom: isSmallScreen ? 2 : 0,
            }}
          >
            <video
              src={item.signedImageUrl}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              width: isSmallScreen ? "100%" : "80px",
              height: "80px",
              borderRadius: "10px",
              overflow: "hidden",
              backgroundImage: `url(${item.signedImageUrl})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              marginBottom: isSmallScreen ? 2 : 0,
            }}
          />
        )}
        <MDTypography
          sx={{
            flex: 1,
            textAlign: isSmallScreen ? "center" : "left",
            mb: isSmallScreen ? 2 : 0,
          }}
        >
          {item.name}
        </MDTypography>
        <IconButton onClick={() => onRemoveItem(index)} color="error">
          <DeleteIcon />
        </IconButton>
      </Box>
    </Card>
  );
};

export default PlaylistDroppableArea;
