import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Define the fetchLoggedUser thunk
export const fetchLoggedUser = createAsyncThunk(
  "user/fetchLoggedUser",
  async ({ token, subdomain, emailVerified }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/user/me`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "X-Subdomain": subdomain,
            "X-Email-Verified": emailVerified,
          },
        }
      );
      if (!response.ok) {
        if (response.status === 404) {
          return rejectWithValue("User not found");
        }
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// User slice
const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    status: "idle",
    error: null,
  },
  reducers: {
    clearUser: (state) => {
      state.user = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoggedUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLoggedUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(fetchLoggedUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { clearUser } = userSlice.actions;
export default userSlice.reducer;
