import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import VenuesList from "../components/VenuesList";
import DashboardLayout from "shared/components/UIElements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/components/Navigation/DashboardNavbar";
import MDBox from "shared/components/MDComponents/MDBox";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import MDTypography from "shared/components/MDComponents/MDTypography";
import { setSelectedVenue } from "features/venue/store/VenueSlice";
import MDButton from "shared/components/MDComponents/MDButton";
import { fetchVenues, deleteVenue } from "services/VenueService";

const Venues = () => {
  const [venuesData, setVenuesData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [hasVenuesInitially, setHasVenuesInitially] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [venueToDelete, setVenueToDelete] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const subdomain = useSelector((state) => state.subdomain.value);

  useEffect(() => {
    const loadVenues = async () => {
      setIsLoading(true);
      try {
        const venues = await fetchVenues(subdomain);
        if (user.role === "superadmin" || user.role === "owner") {
          if (venues.length === 0) {
            setErrorMessage("No venues found.");
          }
          setVenuesData(venues);
        } else {
          const userVenues = venues.filter((venue) =>
            user.venues.includes(venue._id)
          );
          if (userVenues.length === 0) {
            setErrorMessage("No venues available for your account.");
          }
          setVenuesData(userVenues);
        }
      } catch (error) {
        console.log("Error fetching venues:", error);
        setErrorMessage("An error occurred while fetching venues.");
      } finally {
        setIsLoading(false);
      }
    };

    loadVenues();
  }, [user]);

  const handleSelectVenue = (venue) => {
    dispatch(setSelectedVenue(venue));
  };

  const handleDeleteVenue = async () => {
    try {
      await deleteVenue(venueToDelete, subdomain);
      setVenuesData(venuesData.filter((venue) => venue._id !== venueToDelete));
      closeDeleteConfirm();
    } catch (error) {
      console.error("Error deleting venue:", error);
    }
  };

  const openDeleteConfirm = (venueId) => {
    setVenueToDelete(venueId);
    setDeleteConfirmOpen(true);
  };

  const closeDeleteConfirm = () => {
    setDeleteConfirmOpen(false);
    setVenueToDelete(null);
  };

  if (isLoading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(80vh - 64px)",
          }}
        >
          <CircularProgress />
        </MDBox>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <MDBox py={3}>
        <Grid container></Grid>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h5" fontWeight="medium">
            Venues
          </MDTypography>
          <MDBox mt={5} mb={3} />
        </MDBox>
        <MDBox p={2}>
          {venuesData.length > 0 ? (
            <Grid container spacing={6}>
              {venuesData.map((venue) => (
                <Grid item xs={12} md={6} xl={3} key={venue._id}>
                  <VenuesList
                    items={[venue]}
                    onDelete={openDeleteConfirm}
                    onSelect={handleSelectVenue}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <MDBox
              mt={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: "800px",
              }}
            >
              <MDTypography variant="h5" color="text">
                {errorMessage}
              </MDTypography>
              {(user.role === "superadmin" || user.role === "owner") && (
                <MDBox mt={2}>
                  <MDButton
                    variant="contained"
                    color="dark"
                    onClick={() => navigate(`/newvenue`)}
                  >
                    Create Venue
                  </MDButton>
                </MDBox>
              )}
            </MDBox>
          )}
        </MDBox>
      </MDBox>

      <Dialog
        open={deleteConfirmOpen}
        onClose={closeDeleteConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this venue? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton
            onClick={handleDeleteVenue}
            variant="contained"
            color="error"
          >
            Delete
          </MDButton>
          <MDButton
            onClick={closeDeleteConfirm}
            variant="contained"
            color="dark"
          >
            Cancel
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
};

export default Venues;
