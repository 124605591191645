import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { verifyQRCodeToken } from "services/ScreenService";

const VerifyQRCode = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [statusMessage, setStatusMessage] = useState("Verifying QR Code...");
  const subdomain = useSelector((state) => state.subdomain.value);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await verifyQRCodeToken(token, subdomain);
        if (response.token) {
          // Send the long-term token to the screen endpoint
          await fetch(
            `${process.env.REACT_APP_API_URL}/api/screen/validate-token/${response.screenId}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "X-Subdomain": subdomain,
              },
              body: JSON.stringify({
                token: response.token, // Send token in body
              }),
            }
          );

          setStatusMessage(
            "All done! Your screen has been verified. You can now close this browser and your TV will update shortly."
          );
        } else {
          setStatusMessage("Invalid or expired QR code");
        }
      } catch (error) {
        console.error("Error verifying QR code:", error);
        setStatusMessage("Error verifying QR code");
      }
    };

    if (token) {
      verifyToken();
    }
  }, [token]);

  return <div>{statusMessage}</div>;
};

export default VerifyQRCode;
