export const roleChoices = [
  { id: "user", name: "User" },
  { id: "manager", name: "Manager" },
  { id: "owner", name: "Owner" },
];

export const generatePassword = () => {
  const length = 12;
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
  let password = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    password += charset.charAt(Math.floor(Math.random() * n));
  }
  return password;
};

export const validatePassword = (password) => {
  const passwordStrengthRegex = {
    length: /^.{8,30}$/, // Between 8 and 30 characters
    uppercase: /[A-Z]/,
    lowercase: /[a-z]/,
    number: /[0-9]/,
    special: /[!@#$%^&*(),.?":{}|<>]/,
  };

  return (
    passwordStrengthRegex.length.test(password) &&
    passwordStrengthRegex.uppercase.test(password) &&
    passwordStrengthRegex.lowercase.test(password) &&
    passwordStrengthRegex.number.test(password) &&
    passwordStrengthRegex.special.test(password)
  );
};
