import React from "react";
import { TextField } from "@mui/material";

const ScreenInfo = ({ onScreenInfoChange, screenInfo }) => {
  const handleTitleChange = (event) => {
    onScreenInfoChange({ ...screenInfo, title: event.target.value });
  };

  return (
    <>
      <TextField
        label="Screen Title"
        variant="outlined"
        fullWidth
        value={screenInfo.title}
        onChange={handleTitleChange}
        sx={{ my: 2 }}
        inputProps={{ maxLength: 30 }}
      />
    </>
  );
};

export default ScreenInfo;
