const API_BASE_URL = process.env.REACT_APP_API_URL;

export const fetchUsers = async (subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Subdomain": subdomain,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch users.");
    }
    const data = await response.json();
    return data.users;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const fetchUserByEmail = async (email) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/user/email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });

    if (response.status === 404) {
      console.log("User not found with this email.");
      return null;
    }

    if (!response.ok) {
      console.warn("Failed to fetch user by email. Status:", response.status);
      return null;
    }

    const data = await response.json();
    return data.user;
  } catch (error) {
    console.warn("Error fetching user by email:", error);
    return null;
  }
};

export const createUser = async (userData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage = errorData.message || "Failed to create user.";
      throw new Error(errorMessage);
    }

    const data = await response.json();
    return data.user;
  } catch (error) {
    console.error("Error creating user:", error.message);
    throw error;
  }
};

export const updateUser = async (userId, updatedUserData, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/user/${userId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-Subdomain": subdomain,
      },
      body: JSON.stringify(updatedUserData),
    });
    if (!response.ok) {
      throw new Error("Failed to update user.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
};

export const deleteUser = async (userId, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/user/${userId}`, {
      method: "DELETE",
      headers: {
        "X-Subdomain": subdomain,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to delete user.");
    }
    return response;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};
