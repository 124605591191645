import React, { useState } from "react";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";
import TimeModal from "shared/components/UIElements/Modal/TimeModal";
import MediaDirectory from "features/media/components/MediaDirectory";
import ScheduleDroppableArea from "shared/components/UIElements/Dropzone/DroppableArea";

function ScheduleShowings({ showings, onUpdate }) {
  const [isTimeModalOpen, setTimeModalOpen] = useState(false);
  const [currentDroppedItem, setCurrentDroppedItem] = useState(null);

  const handleDrop = (item) => {
    setCurrentDroppedItem({
      name: item.name,
      mediaId: item.id,
      url: item.url,
      signedImageUrl: item.signedImageUrl,
      uniqueId: `${item.id}-${Date.now()}`,
    });
    setTimeModalOpen(true);
  };

  const handleTimeSubmit = (startTime, endTime) => {
    const newShowing = {
      name: currentDroppedItem.name,
      mediaId: currentDroppedItem.mediaId,
      url: currentDroppedItem.url,
      signedImageUrl: currentDroppedItem.signedImageUrl,
      startTime,
      endTime,
      uniqueId: currentDroppedItem.uniqueId,
    };
    const updatedShowings = [...showings, newShowing].sort((a, b) => {
      const timeA = new Date(`01/01/2020 ${a.startTime}`).getTime();
      const timeB = new Date(`01/01/2020 ${b.startTime}`).getTime();
      return timeA - timeB;
    });
    onUpdate(updatedShowings);
    setTimeModalOpen(false);
  };

  const isTimeOverlap = (startTime, endTime) => {
    const start = new Date(`01/01/2020 ${startTime}`).getTime();
    const end = new Date(`01/01/2020 ${endTime}`).getTime();
    return showings.filter((showing) => {
      const existingStart = new Date(
        `01/01/2020 ${showing.startTime}`
      ).getTime();
      const existingEnd = new Date(`01/01/2020 ${showing.endTime}`).getTime();
      return start <= existingEnd && end >= existingStart;
    });
  };

  const handleRemoveShowing = (index) => {
    const updatedShowings = showings.filter((_, i) => i !== index);
    onUpdate(updatedShowings);
  };

  const handleTitleChange = (index, newName) => {
    const updatedShowings = showings.map((showing, idx) =>
      idx === index ? { ...showing, name: newName } : showing
    );
    onUpdate(updatedShowings);
  };

  return (
    <MDBox p={2}>
      <MDTypography variant="h5">Add showings</MDTypography>
      <MDBox mt={2}>
        <MediaDirectory
          onMediaClick={handleDrop}
          onDrop={handleDrop}
          mediaList={showings}
          setMediaList={onUpdate}
          mediaType="images"
        />
      </MDBox>
      <MDBox mt={2}>
        <ScheduleDroppableArea
          showings={showings}
          onDrop={handleDrop}
          handleRemoveShowing={handleRemoveShowing}
          handleTitleChange={handleTitleChange}
        />
      </MDBox>
      <TimeModal
        open={isTimeModalOpen}
        onSubmit={handleTimeSubmit}
        onCancel={() => setTimeModalOpen(false)}
        mediaName={currentDroppedItem?.name}
        isTimeOverlap={isTimeOverlap}
        showings={showings}
      />
    </MDBox>
  );
}

export default ScheduleShowings;
