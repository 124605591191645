import React from "react";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  FilterButton,
  CreateButton,
  ExportButton,
  TopToolbar,
  TextInput,
} from "react-admin";
import ThreeDotsMenu from "./ThreeDotsMenu";

const UserFilters = [
  <TextInput label="Search by name" source="q" alwaysOn />,
  <TextInput label="Search by email" source="email" />,
  <TextInput label="Search by role" source="role" />,
  <TextInput label="Search by customer" source="tenants" />,
];

const UserListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

const maxLength = 30;

export const UserList = (props) => (
  <List {...props} filters={UserFilters} actions={<UserListActions />}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="name" label="Full Name" />
      <TextField source="email" label="Email Address" />
      <TextField source="role" label="Role" />
      <FunctionField
        label="Customer"
        render={(record) => {
          const tenantsList =
            record.tenants
              ?.map((tenant) => tenant.organizationName)
              .join(", ") || "No tenants assigned";
          return truncateText(tenantsList, maxLength);
        }}
      />
      <FunctionField
        label="Venues"
        render={(record) => {
          const venuesList =
            record.venues?.map((venue) => venue.title).join(", ") ||
            "No venues assigned";
          return truncateText(venuesList, maxLength);
        }}
      />
      <ThreeDotsMenu />
    </Datagrid>
  </List>
);
