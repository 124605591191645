import React from "react";
import DashboardLayout from "shared/components/UIElements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/components/Navigation/DashboardNavbar";
import MDBox from "shared/components/MDComponents/MDBox";
import MediaDirectory from "../components/MediaDirectory";
import { useLocation } from "react-router-dom";

const Media = () => {
  const location = useLocation();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <MediaDirectory
        mediaType="all"
        selectedItemId={location.state?.selectedItemId}
        selectedFolderName={location.state?.folderName}
      />
    </DashboardLayout>
  );
};

export default Media;
