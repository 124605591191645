import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material/styles";

const UserAssign = ({ onUserAssign, users, allUsers }) => {
  const theme = useTheme();
  const [selectedUser, setSelectedUser] = useState("");

  const handleUserSelect = (event) => {
    const userId = event.target.value;
    if (!users.includes(userId)) {
      const updatedUsers = [...users, userId];
      onUserAssign(updatedUsers);
      setSelectedUser("");
    }
  };

  const handleUserRemove = (userId) => {
    const updatedUsers = users.filter((id) => id !== userId);
    onUserAssign(updatedUsers);
  };

  const availableUsers = allUsers.filter((user) => !users.includes(user._id));

  return (
    <>
      <FormControl fullWidth sx={{ my: 2, py: 1 }}>
        <InputLabel>Assign Users</InputLabel>
        <Select
          value={selectedUser}
          onChange={handleUserSelect}
          label="Assign Users"
          sx={{
            height: "50px",
            padding: "10px",
          }}
        >
          {availableUsers.map((user) => (
            <MenuItem
              key={user._id}
              value={user._id}
              sx={{
                height: "40px",
                padding: "10px",
              }}
            >
              {user.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box mt={2}>
        <Typography variant="h6" mb={2}>
          Assigned Users:
        </Typography>
        <List>
          {users.map((userId) => {
            const user = allUsers.find((user) => user._id === userId);
            return (
              <ListItem
                key={userId}
                sx={{
                  mb: 1,
                  padding: "10px",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ListItemText
                  primary={user?.name}
                  sx={{
                    flexGrow: 0,
                    color: theme.palette.text.primary,
                  }}
                />
                <IconButton
                  aria-label="delete"
                  onClick={() => handleUserRemove(userId)}
                  sx={{ marginLeft: "8px" }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </>
  );
};

export default UserAssign;
