import React from "react";
import {
  List,
  Datagrid,
  TextField,
  FilterButton,
  CreateButton,
  ExportButton,
  TopToolbar,
  TextInput,
} from "react-admin";
import ThreeDotsMenu from "../users/ThreeDotsMenu";

// Define the filter fields
const AdminFilters = [
  <TextInput label="Search by name" source="q" alwaysOn />,
  <TextInput label="Search by email" source="email" />,
];

// Custom list actions including Create, Export, and Filter
const AdminListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const AdminList = (props) => (
  <List {...props} filters={AdminFilters} actions={<AdminListActions />}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="role" />
      <ThreeDotsMenu />
    </Datagrid>
  </List>
);
