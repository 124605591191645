import React from "react";
import MDButton from "shared/components/MDComponents/MDButton";
import { Box } from "@mui/material";
import MDTypography from "shared/components/MDComponents/MDTypography";

const DomainNotFound = () => {
  const handleVisitWebsite = () => {
    window.location.href = "https://seriouslyds.com";
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      textAlign="center"
    >
      <MDTypography variant="h3">Domain Not Found</MDTypography>
      <MDTypography variant="body1" mt={2} mb={3}>
        The subdomain you are trying to access does not exist.
        <br />
        If you're interested in signing up, please visit our main website or
        contact support.
      </MDTypography>
      <MDButton variant="contained" color="dark" onClick={handleVisitWebsite}>
        Visit Our Website
      </MDButton>
    </Box>
  );
};

export default DomainNotFound;
