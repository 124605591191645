import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  CardContent,
} from "@mui/material";
import MiniScheduleDetailModal from "features/schedule/components/MiniScheduleDetailModal";
import MiniSlideshowDetailModal from "features/slideshow/components/MiniSlideshowDetailModal";
import MiniPlaylistDetailModal from "features/playlist/components/MiniPlaylistDetailModal";
import { fetchSchedulesByVenue } from "services/ScheduleService";
import { fetchSlideshowsByVenue } from "services/SlideshowService";
import { fetchPlaylistsByVenue } from "services/PlaylistService";
import { useSelector } from "react-redux";

const ScreenAssign = ({ onAssignItem, selectedItem, selectedVenue }) => {
  const [itemType, setItemType] = useState("Schedule");
  const [items, setItems] = useState([]);
  const [selectedItemDetails, setSelectedItemDetails] = useState(null);
  const subdomain = useSelector((state) => state.subdomain.value);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        let itemsData;
        if (itemType === "Schedule") {
          itemsData = await fetchSchedulesByVenue(selectedVenue.id, subdomain);
          setItems(itemsData.schedules);
        } else if (itemType === "Slideshow") {
          itemsData = await fetchSlideshowsByVenue(selectedVenue.id, subdomain);
          setItems(itemsData.slideshows);
        } else if (itemType === "Playlist") {
          itemsData = await fetchPlaylistsByVenue(selectedVenue.id, subdomain);
          setItems(itemsData.playlists);
        }
      } catch (error) {
        console.error(`Error fetching ${itemType.toLowerCase()}s:`, error);
      }
    };

    fetchItems();
  }, [itemType, selectedVenue]);

  const handleItemTypeChange = (event) => {
    setItemType(event.target.value);
    setSelectedItemDetails(null);
    onAssignItem({ itemType: event.target.value, itemId: "" });
  };

  const handleSelectChange = (event) => {
    const itemId = event.target.value;
    const item = items.find((i) => i.id === itemId);
    setSelectedItemDetails(item);
    onAssignItem({ itemType, itemId });
  };

  return (
    <>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Assign Type</InputLabel>
        <Select sx={{ py: 1 }} value={itemType} onChange={handleItemTypeChange}>
          <MenuItem value="Schedule">Schedule</MenuItem>
          <MenuItem value="Slideshow">Slideshow</MenuItem>
          <MenuItem value="Playlist">Playlist</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <Select
          sx={{ py: 1 }}
          value={selectedItem.itemId}
          onChange={handleSelectChange}
          displayEmpty
          label={`Select a ${itemType.toLowerCase()}`}
          renderValue={(selected) =>
            selected === ""
              ? `Select a ${itemType.toLowerCase()}`
              : selectedItemDetails?.title
          }
        >
          <MenuItem value="" disabled>
            Select a {itemType.toLowerCase()}
          </MenuItem>
          {items.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedItemDetails && (
        <Card sx={{ mt: 2 }}>
          <CardContent>
            {itemType === "Schedule" && (
              <MiniScheduleDetailModal schedule={selectedItemDetails} />
            )}
            {itemType === "Slideshow" && (
              <MiniSlideshowDetailModal slideshow={selectedItemDetails} />
            )}
            {itemType === "Playlist" && (
              <MiniPlaylistDetailModal playlist={selectedItemDetails} />
            )}
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default ScreenAssign;
