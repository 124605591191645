import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  EmailField,
  useRedirect,
} from "react-admin";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const AdminShow = (props) => {
  const redirect = useRedirect();

  const handleBackClick = () => {
    redirect("/admin/superadmins");
  };

  return (
    <Show {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton onClick={handleBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <h3 style={{ marginLeft: "10px" }}>Admin Details</h3>
      </div>
      <SimpleShowLayout>
        <TextField source="name" label="Full Name" />
        <EmailField source="email" label="Email Address" />
        <TextField source="role" label="Role" />
      </SimpleShowLayout>
    </Show>
  );
};
