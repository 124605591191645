import { Resource } from "react-admin";
import { UserList } from "./resources/users/UserList";
import { UserEdit } from "./resources/users/UserEdit";
import { UserCreate } from "./resources/users/UserCreate";
import { UserShow } from "./resources/users/UserShow";
import { AdminList } from "./resources/administrators/AdminList";
import { AdminEdit } from "./resources/administrators/AdminEdit";
import { AdminCreate } from "./resources/administrators/AdminCreate";
import { AdminShow } from "./resources/administrators/AdminShow";
import { TenantList } from "./resources/tenants/TenantList";
import { TenantEdit } from "./resources/tenants/TenantEdit";
import { TenantCreate } from "./resources/tenants/TenantCreate";
import { ResourceList } from "./resources/resources/ResourceList";
import { ResourceEdit } from "./resources/resources/ResourceEdit";
import { ResourceShow } from "./resources/resources/ResourceShow";
import { TenantShow } from "./resources/tenants/TenantShow";
import { SubscriptionList } from "./resources/subscriptions/SubscriptionList";
import { SubscriptionEdit } from "./resources/subscriptions/SubscriptionEdit";
import { SubscriptionCreate } from "./resources/subscriptions/SubscriptionCreate";
import { SubscriptionShow } from "./resources/subscriptions/SubscriptionShow";
import { PaymentList } from "./resources/payments/PaymentList";
import { PaymentShow } from "./resources/payments/PaymentShow";

const adminResources = [
  <Resource
    key="administrators"
    name="superadmins"
    list={AdminList}
    edit={AdminEdit}
    create={AdminCreate}
    show={AdminShow}
    options={{ label: "Administrators" }}
  />,
  <Resource
    key="customers"
    name="tenant"
    list={TenantList}
    edit={TenantEdit}
    create={TenantCreate}
    show={TenantShow}
    options={{ label: "Customers" }}
  />,
  <Resource
    key="users"
    name="users"
    list={UserList}
    edit={UserEdit}
    create={UserCreate}
    show={UserShow}
    options={{ label: "Users" }}
  />,

  <Resource
    key="resources"
    name="tenant/resource"
    list={ResourceList}
    edit={ResourceEdit}
    show={ResourceShow}
    options={{ label: "Resources" }}
  />,
  <Resource
    key="subscriptions"
    name="subscription"
    list={SubscriptionList}
    edit={SubscriptionEdit}
    create={SubscriptionCreate}
    show={SubscriptionShow}
    options={{ label: "Subscriptions" }}
  />,
  <Resource
    key="payments"
    name="payment"
    list={PaymentList}
    show={PaymentShow}
    options={{ label: "Billing" }}
  />,
];

export default adminResources;
