// src/api/scheduleService.js
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const fetchSchedulesByVenue = async (venueId, subdomain) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/schedule/venue/${venueId}`,
      {
        headers: {
          "X-Subdomain": subdomain,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch schedules.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching schedules:", error);
    throw error;
  }
};

export const createSchedule = async (scheduleData, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/schedule`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Subdomain": subdomain,
      },
      body: JSON.stringify(scheduleData),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to create new schedule.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error creating schedule:", error);
    throw error;
  }
};

export const deleteSchedule = async (scheduleId, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/schedule/${scheduleId}`, {
      method: "DELETE",
      headers: {
        "X-Subdomain": subdomain,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to delete schedule.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error deleting schedule:", error);
    throw error;
  }
};

export const updateSchedule = async (id, scheduleData, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/schedule/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-Subdomain": subdomain,
      },
      body: JSON.stringify(scheduleData),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to update schedule.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error updating schedule:", error);
    throw error;
  }
};
