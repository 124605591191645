import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchTenant = createAsyncThunk(
  "tenant/fetchTenant",
  async ({ token, subdomain }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/tenant/subdomain/${subdomain}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch tenant data");
      }
      const tenantData = await response.json();
      return tenantData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const tenantSlice = createSlice({
  name: "tenant",
  initialState: {
    tenant: null,
    status: "idle",
    error: null,
  },
  reducers: {
    clearTenant: (state) => {
      state.tenant = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTenant.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTenant.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.tenant = action.payload;
      })
      .addCase(fetchTenant.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { clearTenant } = tenantSlice.actions;
export default tenantSlice.reducer;
