const API_BASE_URL = process.env.REACT_APP_API_URL;

export const fetchScreenById = async (
  screenId,
  token,
  subdomain,
  sessionId
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/screen/${screenId}?populate=assignedItem`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Subdomain": subdomain,
        },
      }
    );
    const data = await response.json();

    if (!response.ok) {
      console.error("Fetch screen failed:", data);

      if (data.message === "Invalid or expired token") {
        throw new Error("INVALID_TOKEN");
      }

      throw new Error(data.message || "Failed to fetch screen.");
    }

    return data;
  } catch (error) {
    console.error("Error fetching screen:", error);

    if (error.message === "Invalid or expired token") {
      throw new Error("INVALID_TOKEN");
    }

    throw error;
  }
};

export const fetchAssignedItem = async (itemType, itemId, subdomain) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/${itemType.toLowerCase()}/${itemId}`,
      {
        headers: {
          "X-Subdomain": subdomain,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch ${itemType.toLowerCase()}.`);
    }
    return await response.json();
  } catch (error) {
    console.error(`Error fetching ${itemType.toLowerCase()}:`, error);
    throw error;
  }
};

export const fetchScreensByVenue = async (venueId, subdomain) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/screen/venue/${venueId}`,
      {
        headers: {
          "X-Subdomain": subdomain,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch screens.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching screens:", error);
    throw error;
  }
};

export const createScreen = async (screenData, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/screen`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Subdomain": subdomain,
      },
      body: JSON.stringify(screenData),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to create new screen.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error creating screen:", error);
    throw error;
  }
};

export const deleteScreen = async (screenId, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/screen/${screenId}`, {
      method: "DELETE",
      headers: {
        "X-Subdomain": subdomain,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to delete screen.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error deleting screen:", error);
    throw error;
  }
};

export const updateScreen = async (screenId, screenData, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/screen/${screenId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-Subdomain": subdomain,
      },
      body: JSON.stringify(screenData),
    });
    if (!response.ok) {
      throw new Error("Failed to update screen.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error updating screen:", error);
    throw error;
  }
};

export const updateSessionId = async (
  screenId,
  sessionId,
  token,
  subdomain
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/screen/${screenId}/update-session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Subdomain": subdomain,
        },
        body: JSON.stringify({ sessionId }),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to update session.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error updating session:", error);
    throw error;
  }
};

export const updateScreenAssignment = async (
  screenId,
  screenData,
  subdomain
) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/screen/${screenId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-Subdomain": subdomain,
      },
      body: JSON.stringify(screenData),
    });
    if (!response.ok) {
      throw new Error("Failed to update screen assignment.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error updating screen assignment:", error);
    throw error;
  }
};

export const verifyQRCodeToken = async (token, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/screen/verify-qr`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Subdomain": subdomain,
      },
      body: JSON.stringify({ token }),
    });
    if (!response.ok) {
      throw new Error("Failed to verify QR code.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error verifying QR code:", error);
    throw error;
  }
};

export const generateQRCode = async (screenId, subdomain) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/screen/${screenId}/generate-qr`,
      {
        method: "GET",
        headers: {
          "X-Subdomain": subdomain,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to generate QR code.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error generating QR code:", error);
    throw error;
  }
};

export const validateScreenToken = async (
  screenId,
  token,
  subdomain,
  sessionId
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/screen/validate-token/${screenId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Subdomain": subdomain,
          "X-Session-Id": sessionId,
        },
        body: JSON.stringify({
          token,
        }),
      }
    );

    const data = await response.json();

    if (!response.ok) {
      console.error("Validate token failed:", data);

      if (data.message === "Invalid or expired token") {
        return { isValid: false, forceUpdate: false };
      }

      if (
        data.message ===
        "Session invalid. This screen is being used in another session."
      ) {
        return { isValid: false, forceUpdate: true };
      }

      throw new Error(data.message || "Failed to validate token.");
    }

    return { isValid: true, forceUpdate: false };
  } catch (error) {
    console.error("Error validating token:", error);
    throw error;
  }
};
