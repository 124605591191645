import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import MDBox from "shared/components/MDComponents/MDBox";
import { useTheme } from "@mui/material";
import MDButton from "shared/components/MDComponents/MDButton";
import MiniScheduleDetailModal from "features/schedule/components/MiniScheduleDetailModal";
import MiniSlideshowDetailModal from "features/slideshow/components/MiniSlideshowDetailModal";
import MiniPlaylistDetailModal from "features/playlist/components/MiniPlaylistDetailModal";

const ItemSelection = ({
  schedules,
  slideshows,
  playlists,
  currentAssignment,
  isAssignClicked,
  onItemSelect,
  onConfirmAssignment,
  onCancelAssign,
}) => {
  const [selectedItem, setSelectedItem] = useState(
    currentAssignment?.itemId || ""
  );
  const [itemType, setItemType] = useState(currentAssignment?.itemType || "");
  const theme = useTheme();
  const darkMode = theme.darkMode;

  const handleItemTypeChange = (event) => {
    setItemType(event.target.value);
    setSelectedItem("");
  };

  const handleItemSelectChange = (event) => {
    const itemId = event.target.value;
    setSelectedItem(itemId);
    let selected;
    if (itemType === "Schedule") {
      selected = schedules.find((item) => item.id === itemId);
    } else if (itemType === "Slideshow") {
      selected = slideshows.find((item) => item.id === itemId);
    } else if (itemType === "Playlist") {
      selected = playlists.find((item) => item.id === itemId);
    }
    onItemSelect({ ...selected, itemType });
  };

  const handleCancel = () => {
    setItemType(currentAssignment?.itemType || "");
    setSelectedItem(currentAssignment?.itemId || "");
    onCancelAssign();
  };

  const selectedDetails =
    itemType === "Schedule"
      ? schedules.find((item) => item.id === selectedItem)
      : itemType === "Slideshow"
        ? slideshows.find((item) => item.id === selectedItem)
        : playlists.find((item) => item.id === selectedItem);

  const shouldRenderCard = selectedItem || isAssignClicked;

  // Check if all lists are empty
  const noItemsAvailable =
    schedules.length === 0 && slideshows.length === 0 && playlists.length === 0;

  if (!shouldRenderCard) {
    return null;
  }

  if (noItemsAvailable) {
    return (
      <Card>
        <CardContent>
          <MDBox textAlign="center">
            <Typography variant="h6">
              You have no items to assign. Go create a{" "}
              <Typography
                variant="h6"
                component={Link}
                to="/newschedule"
                sx={{
                  color: darkMode ? "#90caf9" : "#0d47a1",
                  fontWeight: "bold",
                  textDecoration: "none",
                  "&:hover": {
                    color: "#FF4081",
                  },
                }}
              >
                Schedule
              </Typography>{" "}
              |{" "}
              <Typography
                variant="h6"
                component={Link}
                to="/newslideshow"
                sx={{
                  color: darkMode ? "#90caf9" : "#0d47a1",
                  fontWeight: "bold",
                  textDecoration: "none",
                  "&:hover": {
                    color: "#FF4081",
                  },
                }}
              >
                Slideshow
              </Typography>{" "}
              |{" "}
              <Typography
                variant="h6"
                component={Link}
                to="/newplaylist"
                sx={{
                  color: darkMode ? "#90caf9" : "#0d47a1",
                  fontWeight: "bold",
                  textDecoration: "none",
                  "&:hover": {
                    color: "#FF4081", // Strong contrasting hover color
                  },
                }}
              >
                Playlist
              </Typography>{" "}
              now.
            </Typography>
          </MDBox>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <MDBox>
          {isAssignClicked && (
            <FormControl fullWidth sx={{ my: 2 }}>
              <InputLabel>Select Type</InputLabel>
              <Select
                sx={{ py: 1 }}
                value={itemType}
                onChange={handleItemTypeChange}
                displayEmpty
                renderValue={(selected) => (selected === "" ? "" : selected)}
              >
                <MenuItem value="" disabled>
                  Select type
                </MenuItem>
                {schedules.length > 0 && (
                  <MenuItem value="Schedule">Schedule</MenuItem>
                )}
                {slideshows.length > 0 && (
                  <MenuItem value="Slideshow">Slideshow</MenuItem>
                )}
                {playlists.length > 0 && (
                  <MenuItem value="Playlist">Playlist</MenuItem>
                )}
              </Select>
            </FormControl>
          )}

          {isAssignClicked && itemType && (
            <FormControl fullWidth sx={{ my: 2 }}>
              <Select
                sx={{ py: 1 }}
                fullWidth
                value={selectedItem}
                onChange={handleItemSelectChange}
                displayEmpty
                renderValue={(selected) =>
                  selected === ""
                    ? `Select a ${itemType.toLowerCase()}`
                    : selectedDetails?.title
                }
              >
                <MenuItem value="" disabled>
                  {`Select a ${itemType.toLowerCase()}`}
                </MenuItem>
                {(itemType === "Schedule"
                  ? schedules
                  : itemType === "Slideshow"
                    ? slideshows
                    : playlists
                ).map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {itemType === "Schedule" && selectedDetails && (
            <MiniScheduleDetailModal schedule={selectedDetails} />
          )}
          {itemType === "Slideshow" && selectedDetails && (
            <MiniSlideshowDetailModal slideshow={selectedDetails} />
          )}
          {itemType === "Playlist" && selectedDetails && (
            <MiniPlaylistDetailModal playlist={selectedDetails} />
          )}

          {isAssignClicked && (
            <MDBox display="flex" justifyContent="space-around" mt={2}>
              <MDButton
                variant="contained"
                color="dark"
                onClick={() =>
                  onConfirmAssignment({ ...selectedDetails, itemType })
                }
              >
                Confirm
              </MDButton>
              <MDButton
                variant="contained"
                color="error"
                onClick={handleCancel}
              >
                Cancel
              </MDButton>
            </MDBox>
          )}
        </MDBox>
      </CardContent>
    </Card>
  );
};

export default ItemSelection;
