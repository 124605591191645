const API_BASE_URL = process.env.REACT_APP_API_URL;

export const fetchSlideshows = async (venueId, subdomain) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/slideshow/venue/${venueId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Subdomain": subdomain,
        },
      }
    );
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to fetch slideshows.");
    }
    const data = await response.json();
    return data.slideshows;
  } catch (error) {
    console.error("Error fetching slideshows:", error);
    throw error;
  }
};

export const createSlideshow = async (slideshowData, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/slideshow`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Subdomain": subdomain,
      },
      body: JSON.stringify(slideshowData),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to create slideshow.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error creating slideshow:", error);
    throw error;
  }
};

export const deleteSlideshow = async (id, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/slideshow/${id}`, {
      method: "DELETE",
      headers: {
        "X-Subdomain": subdomain,
      },
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to delete slideshow.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error deleting slideshow:", error);
    throw error;
  }
};

export const fetchSlideshowsByVenue = async (venueId, subdomain) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/slideshow/venue/${venueId}`,
      {
        headers: {
          "X-Subdomain": subdomain,
        },
      }
    );
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to fetch slideshows.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching slideshows:", error);
    throw error;
  }
};

export const updateSlideshow = async (id, slideshowData, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/slideshow/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-Subdomain": subdomain,
      },
      body: JSON.stringify(slideshowData),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to update slideshow.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error updating slideshow:", error);
    throw error;
  }
};
