import React from "react";
import { Grid } from "@mui/material";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";
import CustomAvatar from "shared/components/UIElements/Avatar/CustomAvatar";

const MiniPlaylistDetailModal = ({ playlist }) => {
  return (
    <MDBox p={1}>
      <MDTypography variant="subtitle1" align="center">
        {playlist?.title}
      </MDTypography>
      <MDTypography variant="body2" align="center" sx={{ mb: 2 }}>
        {playlist?.description}
      </MDTypography>

      {/* Miniature Media Items as Avatars */}
      {playlist?.mediaList && (
        <Grid container spacing={1} justifyContent="center">
          {playlist.mediaList.map((mediaItem, index) => (
            <Grid item key={index}>
              {mediaItem && mediaItem.signedUrl && (
                <CustomAvatar
                  src={mediaItem.signedUrl}
                  alt={mediaItem.name}
                  size={80}
                  title={mediaItem.name}
                  url={mediaItem.url} // Pass the URL for type checking
                />
              )}
            </Grid>
          ))}
        </Grid>
      )}
    </MDBox>
  );
};

export default MiniPlaylistDetailModal;
