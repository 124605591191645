import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "shared/components/UIElements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/components/Navigation/DashboardNavbar";
import { useTheme } from "@mui/material/styles";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";
import MDButton from "shared/components/MDComponents/MDButton";
import {
  Card,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Switch,
  FormControlLabel,
} from "@mui/material";
import ScreenInfo from "../components/new-screen/ScreenInfo";
import ScreenAssign from "../components/new-screen/ScreenAssign";
import { useSelector } from "react-redux";
import { createScreen } from "services/ScreenService";
import Validation from "shared/components/UIElements/Validation/Validation";
import MDAlert from "shared/components/MDComponents/MDAlert";
import useFetchTenant from "shared/hooks/useFetchTenant";

const NewScreen = () => {
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const darkMode = theme.darkMode;
  const selectedVenue = useSelector((state) => state.venue.selectedVenue);

  const subdomain = useSelector((state) => state.subdomain.value);
  const navigate = useNavigate();
  const { tenant } = useFetchTenant(subdomain);

  const [screenInfo, setScreenInfo] = useState({
    title: "",
    assignedItem: { itemType: "Schedule", itemId: "" },
  });
  const [showValidation, setShowValidation] = useState(false);
  const [toggleAssignContent, setToggleAssignContent] = useState(false);

  const steps = ["1. Screen Info", "2. Assign Item"];
  const isLastStep = activeStep === steps.length - 1;

  const [limitAlert, setLimitAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });

  const isSubscriptionActive = tenant?.status === "active";
  const hasReachedContentLimit = tenant?.currentScreens >= tenant?.maxScreens;

  const showNotification = useCallback((message, type, setAlertFunction) => {
    setAlertFunction({ show: true, message, type });
  }, []);

  useEffect(() => {
    if (!isSubscriptionActive) {
      showNotification(
        "Your account is inactive. You can manage existing screens, but you cannot create new ones.",
        "error",
        setLimitAlert
      );
    } else if (hasReachedContentLimit) {
      showNotification(
        "You have reached the maximum number of screens allowed. Please delete existing screens or contact support to upgrade.",
        "error",
        setLimitAlert
      );
    }
  }, [isSubscriptionActive, hasReachedContentLimit, showNotification]);

  const handleNext = () => {
    setShowValidation(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const handleScreenInfoChange = (updatedInfo) => {
    setScreenInfo(updatedInfo);
  };

  const handleAssignItem = (assignedItem) => {
    setScreenInfo({ ...screenInfo, assignedItem });
  };

  const handleToggleAssignContent = () => {
    setToggleAssignContent((prevAssignContent) => {
      const newAssignContent = !prevAssignContent;
      if (!newAssignContent) {
        setScreenInfo((prevScreenInfo) => ({
          ...prevScreenInfo,
          assignedItem: { itemType: "Schedule", itemId: "" },
        }));
      }
      return newAssignContent;
    });
  };

  const handleCreateScreen = async () => {
    const { title, assignedItem } = screenInfo;
    if (title && (!toggleAssignContent || assignedItem.itemId)) {
      try {
        const payload = {
          title,
          venue: selectedVenue.id,
        };
        if (toggleAssignContent) {
          payload.assignedItem = assignedItem;
        }
        await createScreen(payload, subdomain);
        navigate(`/screens`);
      } catch (error) {
        console.error("Error creating new screen:", error);
      }
    } else {
      setShowValidation(true);
    }
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <ScreenInfo
            onScreenInfoChange={handleScreenInfoChange}
            screenInfo={screenInfo}
          />
        );
      case 1:
        return (
          <>
            <FormControlLabel
              control={
                <Switch
                  checked={toggleAssignContent}
                  onChange={handleToggleAssignContent}
                  color="primary"
                />
              }
              label="Assign Content"
              sx={{ marginBottom: "20px" }}
            />
            {toggleAssignContent && (
              <ScreenAssign
                onAssignItem={handleAssignItem}
                selectedItem={screenInfo.assignedItem}
                selectedVenue={selectedVenue}
              />
            )}
          </>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Create New Screen
                </MDTypography>
              </MDBox>
              <MDTypography variant="h5" fontWeight="regular" color="secondary">
                Configure the screen details, and assign an item.
              </MDTypography>
            </MDBox>

            {limitAlert.show && (
              <MDBox mb={3} sx={{ position: "relative" }}>
                <MDAlert color={limitAlert.type} dismissible>
                  <MDTypography variant="body2" color="white">
                    {limitAlert.message}
                  </MDTypography>
                </MDAlert>
              </MDBox>
            )}

            <Card>
              <MDBox mt={-3} mb={3} mx={2}>
                <Stepper
                  activeStep={activeStep}
                  alternativeLabel
                  sx={{
                    background: `${
                      darkMode ? "#344767" : "#344767"
                    } !important`,
                  }}
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={2}>
                {showValidation && isLastStep && (
                  <Validation
                    fields={[
                      { name: "Title", value: screenInfo.title },
                      {
                        name: "Assigned Item",
                        value: toggleAssignContent
                          ? screenInfo.assignedItem.itemId
                          : "Optional",
                      },
                    ]}
                  />
                )}
                {getStepContent(activeStep)}
                <MDBox mt={3} display="flex" justifyContent="space-between">
                  {activeStep === 0 ? (
                    <MDBox />
                  ) : (
                    <MDButton
                      variant="contained"
                      color="dark"
                      onClick={handleBack}
                    >
                      Back
                    </MDButton>
                  )}
                  <MDButton
                    variant="contained"
                    color="dark"
                    onClick={isLastStep ? handleCreateScreen : handleNext}
                    disabled={hasReachedContentLimit || !isSubscriptionActive}
                  >
                    {isLastStep ? "Create Screen" : "Next"}
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default NewScreen;
