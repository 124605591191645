const API_BASE_URL = process.env.REACT_APP_API_URL;

export const fetchShowingsBySchedule = async (scheduleId, subdomain) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/showing/schedule/${scheduleId}`,
      {
        headers: {
          "X-Subdomain": subdomain,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch showings.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching showings:", error);
    throw error;
  }
};

export const createShowing = async (showingData, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/showing`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Subdomain": subdomain,
      },
      body: JSON.stringify(showingData),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to create new showing.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error creating showing:", error);
    throw error;
  }
};

export const deleteShowing = async (showingId, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/showing/${showingId}`, {
      method: "DELETE",
      headers: {
        "X-Subdomain": subdomain,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to delete showing.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error deleting showing:", error);
    throw error;
  }
};

export const updateShowing = async (id, showingData, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/showing/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-Subdomain": subdomain,
      },
      body: JSON.stringify(showingData),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to update showing.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error updating showing:", error);
    throw error;
  }
};
