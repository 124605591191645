const API_BASE_URL = process.env.REACT_APP_API_URL;

export const checkSubdomain = async (subdomain) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/tenant/check-subdomain/${subdomain}`,
      { method: "GET" }
    );

    if (response.status === 404) {
      return { exists: false };
    }

    if (response.status === 200) {
      return { exists: true };
    }

    throw new Error("Unexpected response from the server");
  } catch (error) {
    console.error("Error checking subdomain:", error);
    return { exists: false };
  }
};

export const getTenantById = async (tenantId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/tenant/${tenantId}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to fetch tenant");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching tenant:", error);
    throw error;
  }
};

export const updateTenant = async (tenantId, tenantData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/tenant/${tenantId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tenantData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Tenant update failed");
    }

    return await response.json();
  } catch (error) {
    console.error("Error updating tenant:", error);
    throw error;
  }
};

export const deleteTenant = async (tenantId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/tenant/${tenantId}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to delete tenant");
    }

    return await response.json();
  } catch (error) {
    console.error("Error deleting tenant:", error);
    throw error;
  }
};
