/** 
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

import MDAvatar from "shared/components/MDComponents/MDAvatar";
import Venues from "features/venue/pages/Venues";
import Schedules from "features/schedule/pages/Schedules";
import Screens from "features/screen/pages/Screens";
import NewSchedule from "features/schedule/pages/NewSchedule";
import ScreenStream from "./features/screen/pages/ScreenStream";
import Logout from "./auth/components/logout";
import Media from "./features/media/pages/Media";
import NewMedia from "./features/media/pages/NewMedia";
import NewScreen from "./features/screen/pages/NewScreen";

import Icon from "@mui/material/Icon";

import profilePicture from "./assets/images/logos/sds-logo.png";
import CurrentVenueTitle from "./shared/components/UIElements/CustomHeaders/CurrentVenueTitle";
import Users from "./features/user/pages/Users";
import NewVenue from "./features/venue/pages/NewVenue";
import VenueProfile from "./features/venue/pages/VenueProfile";
import LoggedInUserName from "./shared/components/UIElements/CustomHeaders/CurrentUserName";
import NewSlideshow from "./features/slideshow/pages/NewSlideshow";
import Slideshows from "./features/slideshow/pages/Slideshows";
import NewPlaylist from "./features/playlist/pages/NewPlaylist";
import Playlists from "./features/playlist/pages/Playlists";
import VerifyQRCode from "./auth/components/VerifyQR";
import VerifyEmail from "auth/pages/VerifyEmail";
import RegisterTenant from "./features/tenant/pages/RegisterTenant";
import DomainNotFound from "features/tenant/pages/DomainNotFound";
import TenantMismatch from "features/tenant/pages/TenantMismatch";
import AdminDashboard from "./admin/Dashboard";
import dataProvider from "./admin/dataProvider";
import { Admin, CustomRoutes } from "react-admin";
import adminResources from "./admin/adminResources";
import { Route } from "react-router-dom";
import FAQ from "features/help/pages/FAQ";
import Walkthroughs from "features/help/pages/Walkthroughs";
import MyAccountPage from "features/myaccount/pages/MyAccountPage";

const routes = [
  {
    type: "collapse",
    name: <LoggedInUserName />,
    key: "loggedin-user",
    icon: <MDAvatar src={profilePicture} size="xl" />,
    collapse: [
      {
        type: "collapse",
        name: "My Account",
        key: "my-account",
        route: "/my-account",
        component: <MyAccountPage />,
        requiredRoles: ["superadmin", "owner"],
      },
      {
        name: "Logout",
        key: "logout",
        route: "/logout",
        component: <Logout />,
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Venues",
    key: "venues",
    icon: <Icon fontSize="medium">location_city</Icon>,
    collapse: [
      {
        name: "New Venue",
        key: "new-venue",
        route: `/newvenue`,
        component: <NewVenue />,
        requiredRoles: ["superadmin", "owner"],
      },
      {
        name: "All Venues",
        key: "all-venues",
        route: "/venues",
        component: <Venues />,
        requiredRoles: ["superadmin", "owner", "manager", "user"],
      },
    ],
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="medium">people</Icon>,
    collapse: [
      {
        name: "All Users",
        key: "all-users",
        route: "/users",
        component: <Users />,
        requiredRoles: ["superadmin", "owner", "manager"],
      },
    ],
  },
  {
    type: "collapse",
    name: "Help",
    key: "help",
    icon: <Icon fontSize="medium">help</Icon>,
    collapse: [
      {
        name: "FAQ",
        key: "faq",
        route: `/faq`,
        component: <FAQ />,
        requiredRoles: ["superadmin", "owner", "manager", "user"],
      },
      {
        name: "Walkthroughs",
        key: "walkthroughs",
        route: "/walkthroughs",
        component: <Walkthroughs />,
        requiredRoles: ["superadmin", "owner", "manager", "user"],
      },
    ],
  },
  {
    type: "title",
    title: <CurrentVenueTitle />,
    key: "title-pages",
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: `/dashboard`,
    component: <VenueProfile />,
    noCollapse: true,
    requiredRoles: ["superadmin", "owner", "manager", "user"],
  },
  {
    type: "collapse",
    name: "Screens",
    key: "screens",
    icon: <Icon fontSize="medium">tv</Icon>,
    collapse: [
      {
        name: "New Screen",
        key: "new-screen",
        route: `/newscreen`,
        component: <NewScreen />,
        requiredRoles: ["superadmin", "owner", "manager"],
      },
      {
        name: "All Screens",
        key: "all-screens",
        route: `/screens`,
        component: <Screens />,
        requiredRoles: ["superadmin", "owner", "manager", "user"],
      },
    ],
  },
  {
    type: "collapse",
    name: "Schedules",
    key: "schedules",
    icon: <Icon fontSize="medium">schedule</Icon>,
    collapse: [
      {
        name: "New Schedule",
        key: "new-schedule",
        route: `/newschedule`,
        component: <NewSchedule />,
        requiredRoles: ["superadmin", "owner", "manager"],
      },
      {
        name: "All Schedules",
        key: "all-schedules",
        route: `/schedules`,
        component: <Schedules />,
        requiredRoles: ["superadmin", "owner", "manager", "user"],
      },
    ],
  },
  {
    type: "collapse",
    name: "Slideshows",
    key: "slideshows",
    icon: <Icon fontSize="medium">slideshow</Icon>,
    collapse: [
      {
        name: "New Slideshow",
        key: "new-slideshow",
        route: `/newslideshow`,
        component: <NewSlideshow />,
        requiredRoles: ["superadmin", "owner", "manager"],
      },
      {
        name: "All Slideshows",
        key: "all-slideshows",
        route: `/slideshows`,
        component: <Slideshows />,
        requiredRoles: ["superadmin", "owner", "manager", "user"],
      },
    ],
  },

  {
    type: "collapse",
    name: "Playlists",
    key: "playlists",
    icon: <Icon fontSize="medium">smart_display</Icon>,
    collapse: [
      {
        name: "New Playlist",
        key: "new-playlist",
        route: `/newplaylist`,
        component: <NewPlaylist />,
        requiredRoles: ["superadmin", "owner", "manager"],
      },
      {
        name: "All Playlists",
        key: "all-playlists",
        route: `/playlists`,
        component: <Playlists />,
        requiredRoles: ["superadmin", "owner", "manager", "user"],
      },
    ],
  },

  {
    type: "collapse",
    name: "Media",
    key: "media",
    icon: <Icon fontSize="medium">perm_media</Icon>,
    collapse: [
      {
        name: "Upload Media",
        key: "add-media",
        route: `/newmedia`,
        component: <NewMedia />,
        requiredRoles: ["superadmin", "owner", "manager"],
      },
      {
        name: "All Media",
        key: "all-media",
        route: `/media`,
        component: <Media />,
        requiredRoles: ["superadmin", "owner", "manager", "user"],
      },
    ],
  },
  {
    name: "Screen Stream",
    key: "screen-stream",
    route: `/stream/:screenId`,
    component: <ScreenStream />,
    requiredRoles: ["superadmin", "owner", "manager", "user"],
  },
  {
    name: "Verify QR Code",
    key: "verify-qr",
    route: "/verify-qr",
    component: <VerifyQRCode />,
    requiredRoles: ["superadmin", "owner", "manager", "user"],
  },
  {
    name: "Verify Email",
    key: "verify-email",
    route: "/verify-email",
    component: <VerifyEmail />,
  },
  {
    name: "Domain Not Found",
    key: "domain-not-found",
    route: "/domain-not-found",
    component: <DomainNotFound />,
  },
  {
    name: "Tenant Mismatch",
    key: "tenant-mismatch",
    route: "/tenant-mismatch",
    component: <TenantMismatch />,
  },

  {
    name: "Admin Dashboard",
    key: "admin-dashboard",
    route: "/admin/*",
    component: (
      <Admin
        dashboard={AdminDashboard}
        dataProvider={dataProvider}
        basename="/admin"
      >
        {adminResources}
        <CustomRoutes>
          <Route path="/dashboard" element={<AdminDashboard />} />
        </CustomRoutes>
      </Admin>
    ),
    requiredRoles: ["superadmin"],
  },
];

export default routes;
