import React from "react";
import {
  Card,
  CardContent,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";

const formatDate = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const PlaylistOptions = ({
  editing,
  startDate,
  endDate,
  runIndefinitely,
  screenFit,
  onStartDateChange,
  onEndDateChange,
  onRunIndefinitelyChange,
  onScreenFitChange,
}) => (
  <Card>
    <CardContent sx={{ height: 250, overflowY: "auto" }}>
      <MDBox display="flex" flexDirection="column" gap={2}>
        {editing ? (
          <>
            <TextField
              label="Start Date"
              type="date"
              value={formatDate(startDate)}
              onChange={(e) => onStartDateChange(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
            {!runIndefinitely && (
              <TextField
                label="End Date"
                type="date"
                value={formatDate(endDate)}
                onChange={(e) => onEndDateChange(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={runIndefinitely}
                  onChange={(e) => onRunIndefinitelyChange(e.target.checked)}
                />
              }
              label="Run Indefinitely"
            />
            <MDBox display="flex" flexDirection="column" gap={1}>
              <MDTypography variant="subtitle2">
                Change Screen Fit:
              </MDTypography>
              <MDBox display="flex" flexDirection="row" gap={1}>
                <Checkbox
                  checked={screenFit !== "none"}
                  onChange={(e) =>
                    onScreenFitChange(e.target.checked ? "fit" : "none")
                  }
                />
                <Select
                  value={screenFit}
                  onChange={(e) => onScreenFitChange(e.target.value)}
                  fullWidth
                  disabled={screenFit === "none"}
                >
                  <MenuItem value="fit">Fit</MenuItem>
                  <MenuItem value="stretch">Stretch</MenuItem>
                  <MenuItem value="center">Center</MenuItem>
                  <MenuItem value="none">None</MenuItem>
                </Select>
              </MDBox>
            </MDBox>
          </>
        ) : (
          <>
            <MDTypography variant="subtitle1">
              Start Date: {new Date(startDate).toLocaleDateString()}
            </MDTypography>
            {!runIndefinitely && (
              <MDTypography variant="subtitle1">
                End Date: {new Date(endDate).toLocaleDateString()}
              </MDTypography>
            )}
            <FormControlLabel
              control={<Checkbox checked={runIndefinitely} disabled />}
              label="Run Indefinitely"
            />
            <MDTypography variant="subtitle1">
              Screen Fit: {screenFit}
            </MDTypography>
          </>
        )}
      </MDBox>
    </CardContent>
  </Card>
);

export default PlaylistOptions;
