import React from "react";
import { useDrop } from "react-dnd";
import { useTheme } from "@mui/material/styles";
import MDBox from "shared/components/MDComponents/MDBox";

const UnifiedDropzone = ({ accept, onDrop, children }) => {
  const theme = useTheme();
  const isDarkMode = localStorage.getItem("darkMode") === "true";

  const [, drop] = useDrop(() => ({
    accept: accept || "media",
    drop: (item) => onDrop(item),
  }));

  return (
    <MDBox
      ref={drop}
      sx={{
        minHeight: "100px",
        width: "100%",
        background: isDarkMode ? theme.palette.background.default : "#f0f0f0",
        padding: 2,
        color: isDarkMode ? theme.palette.text.primary : "inherit",
        border: "1px dashed",
        borderColor: isDarkMode
          ? theme.palette.grey[700]
          : theme.palette.grey[300],
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {children}
    </MDBox>
  );
};

export default UnifiedDropzone;
