import PlaylistItem from "./PlaylistItem";

const PlaylistList = (props) => {
  const { item, spacing, selectedItemId } = props;

  return (
    <PlaylistItem
      id={item.id}
      image={item.image}
      title={item.title}
      description={item.description}
      venueId={item.venue}
      mediaList={item.mediaList}
      screenFit={item.screenFit}
      startDate={item.startDate}
      endDate={item.endDate}
      runIndefinitely={item.runIndefinitely}
      spacing={spacing}
      isSelected={item.id === selectedItemId}
    />
  );
};

export default PlaylistList;
