import React, { useRef, useState } from "react";
import { useDrop, useDrag, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  IconButton,
  Box,
  Card,
  TextField,
  useMediaQuery,
  Tooltip,
  Alert,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import UnifiedDropzone from "./UnifiedDropzone";
import { useTheme } from "@mui/material/styles";

function SlideshowDroppableArea({
  timelineItems,
  handleDrop,
  handleRemoveItem,
  handleDurationChange,
  onMoveItem,
  handleTitleChange,
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  return (
    <DndProvider backend={HTML5Backend}>
      <UnifiedDropzone
        accept="media"
        onDrop={(item) => {
          if (!item.hasOwnProperty("index")) {
            // Item is being dropped from outside, assign a new unique ID
            item.uniqueId = `${item.id}-${Date.now()}`;
            handleDrop(item);
          }
        }}
      >
        {timelineItems.length === 0 && (
          <MDTypography
            variant="caption"
            display="block"
            gutterBottom
            sx={{ textAlign: "center", marginTop: 2 }}
          >
            Drag items here to add them to the slideshow
          </MDTypography>
        )}
        {timelineItems.map((item, index) => (
          <DraggableItem
            key={item.uniqueId} // Use unique ID as the key
            item={item}
            index={index}
            onRemoveItem={handleRemoveItem}
            onMoveItem={onMoveItem}
            handleDurationChange={handleDurationChange}
            handleTitleChange={handleTitleChange}
            isSmallScreen={isSmallScreen}
            isMediumScreen={isMediumScreen}
          />
        ))}
      </UnifiedDropzone>
    </DndProvider>
  );
}

const DraggableItem = ({
  item,
  index,
  onRemoveItem,
  onMoveItem,
  handleDurationChange,
  handleTitleChange,
  isSmallScreen,
  isMediumScreen,
}) => {
  const ref = useRef(null);
  const [durationInput, setDurationInput] = useState(item.duration.toString());
  const [validationMessage, setValidationMessage] = useState("");

  const [, drop] = useDrop({
    accept: "media",
    hover: (draggedItem, monitor) => {
      if (!ref.current) return;
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (draggedItem.index === undefined) return;

      if (draggedItem.index !== index) {
        const threshold = 10; // Threshold for sensitivity

        if (
          draggedItem.index < index &&
          hoverClientY < hoverMiddleY - threshold
        ) {
          return;
        }
        if (
          draggedItem.index > index &&
          hoverClientY > hoverMiddleY + threshold
        ) {
          return;
        }

        onMoveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "media",
    item: { ...item, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const handleDurationInputChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setDurationInput(value);

      if (
        value === "" ||
        (parseInt(value, 10) >= 5 && parseInt(value, 10) <= 60)
      ) {
        setValidationMessage("");
      } else {
        setValidationMessage("Duration must be between 5 and 60 seconds.");
      }
    } else {
      setValidationMessage("Only numerical values are allowed.");
    }
  };

  const handleDurationInputBlur = () => {
    const value = parseInt(durationInput, 10);
    if (value >= 5 && value <= 60 && value % 5 === 0) {
      handleDurationChange(index, value);
      setValidationMessage("");
    } else {
      setDurationInput(item.duration.toString());
      setValidationMessage("Duration must be in multiples of 5 seconds.");
    }
  };

  const incrementDuration = () => {
    const newDuration = item.duration + 5 > 60 ? 5 : item.duration + 5;
    handleDurationChange(index, newDuration);
    setDurationInput(newDuration.toString());
    setValidationMessage("");
  };

  const decrementDuration = () => {
    const newDuration = item.duration - 5 < 5 ? 60 : item.duration - 5;
    handleDurationChange(index, newDuration);
    setDurationInput(newDuration.toString());
    setValidationMessage("");
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const preventDrag = (e) => {
    e.preventDefault();
  };

  return (
    <Card
      ref={ref}
      sx={{
        opacity: isDragging ? 0.5 : 1,
        marginBottom: 1,
        width: isSmallScreen ? "100%" : isMediumScreen ? "80%" : "60%", // Adjust width based on screen size
      }}
    >
      <MDBox
        display="flex"
        flexDirection={isSmallScreen ? "column" : "row"} // Stack items vertically on small screens
        alignItems={isSmallScreen ? "stretch" : "center"}
        justifyContent="space-between"
        mx={2}
        p={2}
        bgcolor="background.paper"
        boxShadow={1}
      >
        <Box
          sx={{
            cursor: "pointer",
            width: isSmallScreen ? "100%" : "100px",
            height: "100px",
            borderRadius: "10px",
            overflow: "hidden",
            backgroundImage: `url(${item.signedImageUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            marginBottom: isSmallScreen ? 2 : 0, // Add margin on small screens
            transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: "0px 4px 10px rgba(0,0,0,0.3)",
            },
          }}
        />
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems={isSmallScreen ? "stretch" : "flex-start"}
          sx={{ width: "100%", flex: 1, mx: isSmallScreen ? 0 : 2 }}
        >
          <TextField
            value={item.title}
            onChange={(e) => handleTitleChange(index, e.target.value)}
            label="Title"
            sx={{
              marginBottom: 2,
              width: "100%",
            }}
            onMouseDown={stopPropagation}
            onTouchStart={stopPropagation}
            onDragStart={preventDrag}
          />
          <Box display="flex" alignItems="center">
            <Box display="flex" flexDirection="column">
              <IconButton onClick={incrementDuration} size="small">
                <ArrowDropUpIcon />
              </IconButton>
              <IconButton onClick={decrementDuration} size="small">
                <ArrowDropDownIcon />
              </IconButton>
            </Box>
            <TextField
              value={durationInput}
              onChange={handleDurationInputChange}
              onBlur={handleDurationInputBlur}
              inputProps={{
                style: { textAlign: "center" },
                maxLength: 2,
                inputMode: "numeric",
              }}
              sx={{ width: 50, textAlign: "center", mx: 1 }}
              onMouseDown={stopPropagation}
              onTouchStart={stopPropagation}
              onDragStart={preventDrag}
            />
            <MDTypography variant="body2" sx={{ marginRight: 1 }}>
              seconds
            </MDTypography>
            <Tooltip title="Adjust the duration for how long the media will be displayed.">
              <IconButton size="small">
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </Box>
          {validationMessage && (
            <Alert severity="error" sx={{ mt: 1, mb: 2 }}>
              {validationMessage}
            </Alert>
          )}
        </MDBox>
        <IconButton onClick={() => onRemoveItem(index)} color="error">
          <DeleteIcon />
        </IconButton>
      </MDBox>
    </Card>
  );
};

export default SlideshowDroppableArea;
