import React from "react";
import { Grid } from "@mui/material";
import { Create, SimpleForm, TextInput } from "react-admin";
import CustomToolbar from "../../utils/CustomToolbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { useRedirect } from "react-admin";

export const TenantCreate = (props) => {
  const redirect = useRedirect();

  const handleBackClick = () => {
    redirect("/admin/tenant");
  };

  return (
    <Create {...props} resource="tenant">
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton onClick={handleBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <h3 style={{ marginLeft: "10px" }}>Create New Tenant</h3>
      </div>
      <SimpleForm toolbar={<CustomToolbar resource="tenant" />}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput source="organizationName" label="Organization Name" />
          </Grid>
          <Grid item xs={6}>
            <TextInput source="subdomain" label="Subdomain" />
          </Grid>
          <Grid item xs={6}>
            <TextInput source="contactEmail" label="Contact Email" />
          </Grid>
          <Grid item xs={6}>
            <TextInput source="contactPhone" label="Contact Phone" />
          </Grid>
          {/* Optionally add address fields */}
          <Grid item xs={6}>
            <TextInput source="address.street" label="Street" />
          </Grid>
          <Grid item xs={6}>
            <TextInput source="address.city" label="City" />
          </Grid>
          <Grid item xs={6}>
            <TextInput source="address.state" label="State" />
          </Grid>
          <Grid item xs={6}>
            <TextInput source="address.zipCode" label="Zip Code" />
          </Grid>
          <Grid item xs={6}>
            <TextInput source="address.country" label="Country" />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};
