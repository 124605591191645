import React, { useState, useEffect } from "react";
import DashboardLayout from "shared/components/UIElements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/components/Navigation/DashboardNavbar";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";
import FAQEntry from "../components/FAQEntry";
import { fetchFAQs } from "services/FAQService";
import { CircularProgress } from "@mui/material";

const FAQ = () => {
  const [openQuestionIndex, setOpenQuestionIndex] = useState(null);
  const [faqData, setFaqData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadFAQs = async () => {
      try {
        const fetchedFAQs = await fetchFAQs();
        setFaqData(fetchedFAQs);
      } catch (error) {
        setError("Failed to load FAQs");
        console.error("Error fetching FAQs:", error);
      } finally {
        setLoading(false);
      }
    };

    loadFAQs();
  }, []);

  const handleToggle = (index) => {
    setOpenQuestionIndex(openQuestionIndex === index ? null : index);
  };

  if (loading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(80vh - 64px)",
          }}
        >
          <CircularProgress />
        </MDBox>
      </DashboardLayout>
    );
  }

  if (error) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={3} py={2} px={2}>
          <MDTypography variant="h5" color="error">
            {error}
          </MDTypography>
        </MDBox>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={3} py={2} px={2}>
        <MDTypography variant="h5">FAQ</MDTypography>
      </MDBox>
      <MDBox>
        {faqData.map((faq, index) => (
          <FAQEntry
            key={index}
            question={faq.question}
            answer={faq.answer}
            isOpen={openQuestionIndex === index}
            onClick={() => handleToggle(index)}
          />
        ))}
      </MDBox>
    </DashboardLayout>
  );
};

export default FAQ;
