import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import MDTypography from "shared/components/MDComponents/MDTypography";
import ScreenDetailModal from "./ScreenDetailModal";
import { fetchAssignedItem } from "services/ScreenService";
import { deleteScreen } from "services/ScreenService";
import { updateScreenAssignment } from "services/ScreenService";
import { fetchSchedulesByVenue } from "services/ScheduleService";
import { fetchSlideshowsByVenue } from "services/SlideshowService";
import { fetchPlaylistsByVenue } from "services/PlaylistService";
import { useSelector } from "react-redux";

const ScreenItem = (props) => {
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isConfirmationPending, setIsConfirmationPending] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // Fetched item details
  const [schedules, setSchedules] = useState([]);
  const [slideshows, setSlideshows] = useState([]);
  const [playlists, setPlaylists] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [currentAssignment, setCurrentAssignment] = useState(null); // Original assignment
  const [title, setTitle] = useState(props.title); // State for the title
  const itemSize = props.spacing * 5; //Example conversion, adjust as needed.
  const fontSize = Math.max(12, props.spacing / 2); //Dynamically adjust font size
  const subdomain = useSelector((state) => state.subdomain.value);

  const toggleDetailModal = () => setShowDetailModal(!showDetailModal);

  useEffect(() => {
    setCurrentAssignment(props.assignedItem);
  }, [props.assignedItem]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const schedulesData = await fetchSchedulesByVenue(
          props.venue,
          subdomain
        );
        setSchedules(schedulesData.schedules);

        const slideshowsData = await fetchSlideshowsByVenue(
          props.venue,
          subdomain
        );
        setSlideshows(slideshowsData.slideshows);

        const playlistsData = await fetchPlaylistsByVenue(
          props.venue,
          subdomain
        );
        setPlaylists(playlistsData.playlists);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    fetchData();
  }, [props.venue]);

  const openMapHandler = async () => {
    setShowDetailModal(true);
    if (
      currentAssignment &&
      currentAssignment.itemType &&
      currentAssignment.itemId
    ) {
      try {
        const data = await fetchAssignedItem(
          currentAssignment.itemType,
          currentAssignment.itemId,
          subdomain
        );
        setSelectedItem(data);
      } catch (error) {
        console.log(
          `Error fetching ${currentAssignment.itemType.toLowerCase()}:`,
          error
        );
      }
    }
  };

  const truncateTitle = (text, maxLength = 20) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const confirmDeleteHandler = async () => {
    toggleDetailModal();

    try {
      await deleteScreen(props.id, subdomain);
      setIsDeleted(true);
    } catch (error) {
      console.log("Error deleting screen:", error);
    }
  };

  const confirmAssignHandler = async (selectedItem) => {
    setIsConfirmationPending(false);

    if (selectedItem) {
      try {
        await updateScreenAssignment(
          props.id,
          {
            assignedItem: {
              itemType: selectedItem.itemType,
              itemId: selectedItem.id,
            },
            title: props.title,
            venue: props.venue,
          },
          subdomain
        );
        setCurrentAssignment({
          itemType: selectedItem.itemType,
          itemId: selectedItem.id,
        }); // Update original assignment
      } catch (error) {
        console.log(
          `Error assigning ${selectedItem.itemType.toLowerCase()}:`,
          error
        );
      }
    }
  };

  const togglePreviewHandler = () => {
    setShowPreview(!showPreview);
  };

  useEffect(() => {
    if (props.isSelected) {
      setShowDetailModal(true);
    }
  }, [props.isSelected]);

  const handleTitleUpdate = (newTitle) => {
    setTitle(newTitle);
  };

  if (isDeleted) {
    return null;
  }

  return (
    <React.Fragment>
      <ScreenDetailModal
        open={showDetailModal}
        onClose={toggleDetailModal}
        screen={{ id: props.id, title: title }}
        togglePreviewHandler={togglePreviewHandler}
        isConfirmationPending={isConfirmationPending}
        currentAssignment={currentAssignment} // Pass original assignment
        showPreview={showPreview}
        schedules={schedules}
        slideshows={slideshows}
        playlists={playlists}
        onItemSelect={setSelectedItem}
        confirmAssignHandler={confirmAssignHandler}
        onDelete={confirmDeleteHandler}
        venue={props.venue}
        onTitleUpdate={handleTitleUpdate} // Pass the title update handler
      />

      <Box sx={{ width: `${itemSize}px`, m: 0, textAlign: "center" }}>
        <Box
          sx={{
            cursor: "pointer",
            width: "100%",
            height: `${itemSize}px`,
            borderRadius: "10px",
            overflow: "hidden",
            backgroundImage: `url(${props.image})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: "0px 4px 10px rgba(0,0,0,0.3)",
            },
          }}
          onClick={openMapHandler}
        />
        <MDTypography variant="h6" sx={{ fontSize: `${fontSize}px`, mt: 1 }}>
          {truncateTitle(title)}
        </MDTypography>
      </Box>
    </React.Fragment>
  );
};

export default ScreenItem;
