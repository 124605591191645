import React from "react";
import { Grid } from "@mui/material";
import {
  Edit,
  SimpleForm,
  SelectInput,
  NumberInput,
  DateInput,
  useNotify,
  useRedirect,
  useUpdate,
} from "react-admin";
import CustomToolbar from "../../utils/CustomToolbar";

const planChoices = [{ id: "basic", name: "Basic" }];
const billingCycleChoices = [
  { id: "monthly", name: "Monthly" },
  { id: "annual", name: "Annual" },
];

export const SubscriptionEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [update] = useUpdate();

  const handleSubmit = async (data) => {
    try {
      const formData = {
        tenant: data.tenant._id,
        subscriptionPlan: data.subscriptionPlan,
        billingCycle: data.billingCycle,
        pricePerScreen: data.pricePerScreen,
        screens: data.screens,
        startDate: data.startDate,
        endDate: data.endDate,
        status: data.status,
      };
      await update("subscription", { id: data.id, data: formData });
      notify("Subscription updated successfully", { type: "info" });
      redirect("/admin/subscription");
    } catch (error) {
      notify(`Error: ${error.message}`, { type: "error" });
    }
  };

  return (
    <Edit {...props}>
      <SimpleForm
        toolbar={<CustomToolbar resource="subscription" />}
        onSubmit={handleSubmit}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SelectInput
              source="subscriptionPlan"
              choices={planChoices}
              fullWidth
              label="Plan"
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              source="billingCycle"
              choices={billingCycleChoices}
              fullWidth
              label="Billing Cycle"
            />
          </Grid>
          <Grid item xs={6}>
            <NumberInput
              source="pricePerScreen"
              label="Price per Screen"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <NumberInput source="screens" label="Number of Screens" fullWidth />
          </Grid>
          <Grid item xs={6}>
            <DateInput source="startDate" label="Start Date" fullWidth />
          </Grid>
          <Grid item xs={6}>
            <DateInput source="endDate" label="End Date" fullWidth />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              source="status"
              choices={[
                { id: "active", name: "Active" },
                { id: "pending", name: "Pending" },
                { id: "incomplete", name: "Incomplete" },
                { id: "unpaid", name: "Unpaid" },
                { id: "suspended", name: "Suspended" },
                { id: "past_due", name: "Past Due" },
                { id: "canceled", name: "Canceled" },
                { id: "ended", name: "Ended" },
                { id: "expired", name: "Expired" },
              ]}
              fullWidth
              label="Status"
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};
