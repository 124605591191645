import React, { useState, useEffect, useCallback } from "react";
import DashboardLayout from "shared/components/UIElements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/components/Navigation/DashboardNavbar";
import MDBox from "shared/components/MDComponents/MDBox";
import { Select, MenuItem, Divider, TextField } from "@mui/material";
import MDTypography from "shared/components/MDComponents/MDTypography";
import MDButton from "shared/components/MDComponents/MDButton";
import MDDropzone from "shared/components/MDComponents/MDDropzone";
import { CircularProgress } from "@mui/material";

import { useSelector } from "react-redux";

import MDAlert from "shared/components/MDComponents/MDAlert";
import { fetchFolders } from "services/FolderService";
import { createFolder } from "services/FolderService";
import { uploadMedia } from "services/MediaService";
import useFetchTenant from "shared/hooks/useFetchTenant";

const NewMedia = () => {
  const selectedVenue = useSelector((state) => state.venue.selectedVenue);
  const subdomain = useSelector((state) => state.subdomain.value);
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [newFolderName, setNewFolderName] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });
  // eslint-disable-next-line no-unused-vars
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const venueId = selectedVenue.id;
  const { tenant } = useFetchTenant(subdomain);

  const [limitAlert, setLimitAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });

  const isSubscriptionActive = tenant?.status === "active";
  const availableSpace = tenant?.maxMediaSize - tenant?.totalMediaSize;

  const showNotification = useCallback((message, type) => {
    setAlert({ show: true, message, type });
  }, []);

  const showLimitAlert = (message) => {
    setLimitAlert({ show: true, message, type: "error" });
  };

  const fetchFoldersData = useCallback(async () => {
    try {
      const fetchedFolders = await fetchFolders(venueId, subdomain);
      setFolders(fetchedFolders);
      if (fetchedFolders.length > 0 && !selectedFolder) {
        setSelectedFolder(fetchedFolders[0]._id);
      }
    } catch (error) {
      console.error("Error fetching folders:", error);
      showNotification(error.message, "error");
    }
  }, [venueId, selectedFolder]);

  useEffect(() => {
    fetchFoldersData();
  }, [fetchFoldersData]);

  useEffect(() => {
    if (
      folders.length > 0 &&
      !folders.find((folder) => folder._id === selectedFolder)
    ) {
      setSelectedFolder(folders[0]._id);
    }
  }, [folders, selectedFolder]);

  useEffect(() => {
    const ids = folders.map((folder) => folder._id);
    const uniqueIds = new Set(ids);
    if (ids.length !== uniqueIds.size) {
      console.warn("Duplicate IDs detected in folders:", ids);
    }
  }, [folders]);

  useEffect(() => {
    if (!isSubscriptionActive) {
      showNotification(
        "Your account is inactive. You can manage existing media, but you cannot upload new media.",
        "error"
      );
      setSubmitDisabled(true);
    }
  }, [isSubscriptionActive, showNotification]);

  const handleCreateNewFolder = async () => {
    if (!newFolderName) {
      showNotification("Please enter a folder name.", "error");
      return;
    }

    if (/\s/.test(newFolderName)) {
      showNotification("Folder name should not contain spaces.", "error");
      return;
    }

    try {
      await createFolder(venueId, newFolderName, subdomain);
      setNewFolderName("");
      showNotification("Folder created successfully.", "success");
      fetchFoldersData();
    } catch (error) {
      console.error("Error creating folder:", error);
      showNotification(error.message, "error");
    }
  };

  const calculateTotalFileSize = () => {
    const dropzoneInstance = document.querySelector(".dropzone").dropzone;
    const files = dropzoneInstance.getAcceptedFiles();

    const totalSelectedFileSize = files.reduce(
      (total, file) => total + file.size / (1024 * 1024), // Convert bytes to MB
      0
    );

    return totalSelectedFileSize;
  };

  const handleSubmit = async () => {
    setLoading(true);

    // Calculate the total file size when submitting
    const totalSelectedFileSize = calculateTotalFileSize();

    if (totalSelectedFileSize === 0) {
      showNotification("No files selected for upload.", "error");
      setLoading(false);
      return;
    }

    // Check if the total size exceeds available space
    if (totalSelectedFileSize > availableSpace) {
      showLimitAlert(
        `Selected files exceed available storage. Available space: ${availableSpace.toFixed(
          2
        )} MB.`
      );
      setLoading(false);
      return;
    }

    const dropzoneInstance = document.querySelector(".dropzone").dropzone;
    const files = dropzoneInstance.getAcceptedFiles();

    const responseMessage = await sendMediaToServer(files);
    if (responseMessage && responseMessage.length > 0) {
      showNotification(responseMessage, "success");

      // Clear files only after successful upload
      dropzoneInstance.removeAllFiles(true);
    } else {
      showNotification("An unexpected error occurred.", "error");
    }

    setLoading(false);
  };

  const sendMediaToServer = async (files) => {
    try {
      const selectedFolderObject = folders.find(
        (folder) => folder._id === selectedFolder
      );

      if (!selectedFolderObject) {
        showNotification("Selected folder not found.", "error");
        return null;
      }

      const formData = new FormData();
      files.forEach((file) => formData.append("media", file));
      formData.append("venue", venueId);
      formData.append("folder", JSON.stringify(selectedFolderObject));

      const responseMessage = await uploadMedia(formData, subdomain);
      return responseMessage;
    } catch (error) {
      // console.log(error);
      console.error("Error sending media to server:", error);
      return null;
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox p={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Upload Media</MDTypography>
          <Divider />
          <MDBox
            sx={{
              position: "relative",
              maxWidth: "800px",
            }}
          >
            {alert.show && alert.message && (
              <MDAlert
                color={alert.type}
                dismissible
                onClose={() =>
                  setAlert({ show: false, message: "", type: "info" })
                }
              >
                <MDTypography variant="body2" color="white">
                  {alert.message}
                </MDTypography>
              </MDAlert>
            )}

            {limitAlert.show && limitAlert.message && (
              <MDAlert
                color={limitAlert.type}
                dismissible
                onClose={() =>
                  setLimitAlert({ show: false, message: "", type: "info" })
                }
              >
                <MDTypography variant="body2" color="white">
                  {limitAlert.message}
                </MDTypography>
              </MDAlert>
            )}
          </MDBox>
          <MDBox
            display="flex"
            alignItems="center"
            gap={2}
            sx={{
              flexWrap: { xs: "wrap", sm: "nowrap" },
              width: { sm: "auto", md: "100%" },
              maxWidth: "800px",
              justifyContent: { sm: "flex-start" },
              "& > *": {
                flexGrow: 1,
                flexBasis: { xs: "100%", sm: 0 },
                maxWidth: { sm: "50%" },
              },
            }}
          >
            <MDTypography variant="h6" component="div">
              Select Folder:
            </MDTypography>
            <Select
              value={selectedFolder || ""}
              onChange={(e) => setSelectedFolder(e.target.value)}
              fullWidth
              sx={{
                minHeight: "40px",
              }}
            >
              {folders.map((folder) => (
                <MenuItem key={folder._id} value={folder._id}>
                  {folder.name}
                </MenuItem>
              ))}
            </Select>

            <MDTypography variant="h6" component="div">
              or Create New:
            </MDTypography>
            <TextField
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              label="New Folder Name"
              fullWidth
              inputProps={{ maxLength: 30 }}
            />

            <MDButton
              variant="contained"
              color="dark"
              onClick={handleCreateNewFolder}
            >
              Create Folder
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox
          sx={{
            maxWidth: "800px",
            position: "relative",
          }}
        >
          <MDDropzone
            options={{
              url: `${process.env.REACT_APP_API_URL}/api/media`,
              addRemoveLinks: true,
              acceptedFiles: "image/*,video/*",
              maxFiles: 10,
              maxFilesize: 100,
              autoProcessQueue: false,
              params: {
                venue: venueId,
                folder: selectedFolder,
              },
            }}
          />
          {loading && (
            <CircularProgress
              size={50}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          )}
        </MDBox>
        <MDBox mt={2}>
          <MDButton
            variant="contained"
            color="dark"
            onClick={handleSubmit}
            disabled={submitDisabled}
          >
            Submit Media
          </MDButton>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
};

export default NewMedia;
