import React, { useEffect, useState, memo } from "react";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import FormField from "../form-field";
import MDBox from "shared/components/MDComponents/MDBox";
import { FormControlLabel, Checkbox, TextField } from "@mui/material";
import ScreenFitOption from "shared/components/UIElements/Toggles/ScreenFitOptions";

function PlaylistInfo({ onUpdate, playlistInfo }) {
  const [playlistName, setPlaylistName] = useState(playlistInfo?.title || "");
  const [description, setDescription] = useState(
    playlistInfo?.description || ""
  );
  const [startDate, setStartDate] = useState(playlistInfo?.startDate || "");
  const [endDate, setEndDate] = useState(playlistInfo?.endDate || "");
  const [runIndefinitely, setRunIndefinitely] = useState(
    playlistInfo?.runIndefinitely || false
  );
  const [changeScreenFit, setChangeScreenFit] = useState(false);
  const [screenFit, setScreenFit] = useState("none");
  const theme = useTheme();

  useEffect(() => {
    onUpdate({
      title: playlistName,
      description,
      startDate,
      endDate,
      runIndefinitely,
      screenFit: changeScreenFit ? screenFit : "none",
    });
  }, [
    playlistName,
    description,
    startDate,
    endDate,
    runIndefinitely,
    changeScreenFit,
    screenFit,
    onUpdate,
  ]);

  const handleRunIndefinitelyChange = (event) => {
    setRunIndefinitely(event.target.checked);
    if (event.target.checked) {
      setEndDate("");
    }
  };

  return (
    <MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormField
            label="Playlist Name"
            value={playlistName}
            onChange={(e) => setPlaylistName(e.target.value)}
            inputProps={{ maxLength: 30 }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            rows={4}
            inputProps={{
              style: { color: theme.palette.text.primary },
              maxLength: 200,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ScreenFitOption
            changeScreenFit={changeScreenFit}
            setChangeScreenFit={setChangeScreenFit}
            screenFit={screenFit}
            setScreenFit={setScreenFit}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              style: { color: theme.palette.text.primary },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            disabled={runIndefinitely}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              style: { color: theme.palette.text.primary },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={runIndefinitely}
                onChange={handleRunIndefinitelyChange}
              />
            }
            label="Run Indefinitely"
          />
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default memo(PlaylistInfo);
