import React, { useState } from "react";
import MDButton from "shared/components/MDComponents/MDButton";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";

const ManageSubscription = () => {
  const subdomain = useSelector((state) => state.subdomain.value);
  const tenant = useSelector((state) => state.tenant.tenant);
  const [loading, setLoading] = useState(false);

  const handleManageSubscription = async () => {
    setLoading(true); // Set loading to true when the button is clicked
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/stripe/create-portal-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Subdomain": subdomain,
          },
          body: JSON.stringify({
            customerId: tenant.stripeCustomerId,
          }),
        }
      );
      const data = await response.json();
      window.location.href = data.url; // Redirects, no need to reset loading
    } catch (error) {
      console.error("Error redirecting to customer portal:", error);
      setLoading(false); // Only set loading back to false if an error occurs
    }
  };

  return (
    <>
      {loading ? (
        <CircularProgress color="secondary" />
      ) : (
        <MDButton
          variant="contained"
          color="dark"
          onClick={handleManageSubscription}
        >
          Manage Subscription
        </MDButton>
      )}
    </>
  );
};

export default ManageSubscription;
