import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardContent,
  Grid,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton,
  useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import Modal from "shared/components/UIElements/Modal/Modal";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";
import ScreenStream from "../pages/ScreenStream";
import ItemSelection from "./ItemSelection";
import MDButton from "shared/components/MDComponents/MDButton";
import { updateScreen, validateScreenToken } from "services/ScreenService";
import { Link as MuiLink } from "@mui/material";

const ScreenDetailModal = ({
  open,
  onClose,
  screen,
  togglePreviewHandler,
  currentAssignment,
  showPreview,
  schedules,
  slideshows,
  playlists,
  onItemSelect,
  confirmAssignHandler,
  onDelete,
  venue,
  onTitleUpdate,
}) => {
  const [showItemSelection, setShowItemSelection] = useState(false);
  const [isAssignClicked, setIsAssignClicked] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editTitle, setEditTitle] = useState(screen.title);
  const user = useSelector((state) => state.user.user);
  const subdomain = useSelector((state) => state.subdomain.value);
  const theme = useTheme();
  const darkMode = theme.darkMode;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const fetchTokenAndValidate = async () => {
      try {
        // Fetch the token for the screen
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/screen/${screen.id}/token`,
          {
            headers: {
              "X-Subdomain": subdomain,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          const token = data.token;
          setToken(token);

          // Validate the token
          const isValidToken = await validateScreenToken(
            screen.id,
            token,
            subdomain
          );
          setIsAuthenticated(isValidToken);
        } else {
          throw new Error("Failed to fetch token");
        }
      } catch (error) {
        console.error("Error fetching or validating token:", error);
      }
    };

    if (open) {
      fetchTokenAndValidate();
    }
  }, [open, screen.id, subdomain]);

  const openConfirmationDialog = () => setOpenConfirm(true);
  const closeConfirmationDialog = () => setOpenConfirm(false);
  const handleDelete = () => {
    onDelete();
    closeConfirmationDialog();
  };

  const handleAssignClick = () => {
    setIsAssignClicked(!isAssignClicked);
    setShowItemSelection(!showItemSelection);
  };

  const handleCloseModal = () => {
    setShowItemSelection(false);
    if (onClose) onClose();
    if (showPreview) togglePreviewHandler();
    setIsAssignClicked(false);
  };

  const handleConfirmAssignment = (selectedItem) => {
    confirmAssignHandler(selectedItem);
    onItemSelect(selectedItem);
    setShowItemSelection(false);
    handleCloseModal();
  };

  const handleEditToggle = () => {
    setEditing(!editing);
  };

  const handleCancelEdit = () => {
    setEditing(false);
    setEditTitle(screen.title);
  };

  const truncateName = (name, maxLength = 50) => {
    if (name.length > maxLength) {
      return name.substring(0, maxLength) + "...";
    }
    return name;
  };

  const handleSave = async () => {
    try {
      await updateScreen(
        screen.id,
        { title: editTitle, venue: venue },
        subdomain
      );
      screen.title = editTitle;
      onTitleUpdate(editTitle);
      setEditing(false);
    } catch (error) {
      console.error("Error updating screen title:", error);
    }
  };

  const canDelete =
    user &&
    (user.role === "manager" ||
      user.role === "owner" ||
      user.role === "superadmin");

  const getFullUrl = (path) => {
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;
    return `${protocol}//${hostname}${path}`;
  };

  return (
    <Modal show={open} onCancel={handleCloseModal}>
      <MDBox p={2}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {editing ? (
            <TextField
              value={editTitle}
              onChange={(e) => setEditTitle(e.target.value)}
              fullWidth
              inputProps={{ maxLength: 30 }}
            />
          ) : (
            <MDTypography variant="h5">
              {truncateName(screen.title)}
            </MDTypography>
          )}
          {editing && (
            <IconButton
              sx={{
                color: darkMode ? "#90caf9" : "#0d47a1",
                "&:hover": {
                  color: "#FF4081",
                },
              }}
              onClick={handleSave}
              color="primary"
            >
              <SaveIcon />
            </IconButton>
          )}
          <IconButton
            sx={{
              color: darkMode ? "#90caf9" : "#0d47a1",
              "&:hover": {
                color: "#FF4081",
              },
            }}
            onClick={handleEditToggle}
            color="primary"
          >
            {editing ? <CancelIcon /> : <EditIcon />}
          </IconButton>
        </MDBox>

        <Divider sx={{ my: 2 }} />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <MDBox
                  p={2}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <MDTypography variant="h6" textAlign="center">
                    Link:{" "}
                    <MuiLink
                      href={getFullUrl(`/stream/${screen.id}`)}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        color: darkMode ? "#90caf9" : "#0d47a1",
                        fontWeight: "bold",
                        textDecoration: "none",
                        wordBreak: "break-all",
                        "&:hover": {
                          color: "#FF4081",
                        },
                      }}
                    >
                      {getFullUrl(`/stream/${screen.id}`)}
                    </MuiLink>
                  </MDTypography>
                  {showPreview && (
                    <MDBox
                      sx={{
                        width: "100%",
                        height: "200px",
                        overflow: "hidden",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 2,
                      }}
                    >
                      {isAuthenticated ? (
                        <ScreenStream screenId={screen.id} preview={true} />
                      ) : (
                        <MDTypography variant="body1" textAlign="center">
                          TV not authenticated yet. Please go to the URL above
                          and follow the instructions to set up.
                        </MDTypography>
                      )}
                    </MDBox>
                  )}
                  <MDBox display="flex" justifyContent="space-around" mt={2}>
                    {/* <MDButton
                      variant="contained"
                      color="dark"
                      onClick={togglePreviewHandler}
                      sx={{ mx: 1 }}
                    >
                      PREVIEW
                    </MDButton> */}
                    <MDButton
                      variant="contained"
                      color="dark"
                      onClick={handleAssignClick}
                      sx={{ mx: 1 }}
                    >
                      ASSIGN CONTENT
                    </MDButton>
                    {canDelete && (
                      <MDButton
                        variant="contained"
                        color="error"
                        onClick={openConfirmationDialog}
                        sx={{ mx: 1 }}
                      >
                        DELETE
                      </MDButton>
                    )}
                  </MDBox>
                </MDBox>
              </CardContent>
            </Card>

            <Grid item xs={12} mt={2}>
              <ItemSelection
                schedules={schedules}
                slideshows={slideshows}
                playlists={playlists}
                currentAssignment={currentAssignment}
                isAssignClicked={isAssignClicked}
                onItemSelect={onItemSelect}
                onConfirmAssignment={handleConfirmAssignment}
                onCancelAssign={handleAssignClick}
              />
            </Grid>
          </Grid>
        </Grid>
      </MDBox>

      {/* Confirmation Dialog */}
      <Dialog
        open={openConfirm}
        onClose={closeConfirmationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this screen? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleDelete} color="error" variant="contained">
            Delete
          </MDButton>
          <MDButton
            onClick={closeConfirmationDialog}
            color="dark"
            variant="contained"
          >
            Cancel
          </MDButton>
        </DialogActions>
      </Dialog>
    </Modal>
  );
};

export default ScreenDetailModal;
