import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

const ContactFields = ({
  contactPhone,
  setContactPhone,
  address,
  setAddress,
}) => {
  return (
    <>
      <Grid item xs={6}>
        <TextField
          label="Phone"
          value={contactPhone}
          onChange={(e) => setContactPhone(e.target.value)}
          fullWidth
          margin="normal"
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Street"
          value={address.street}
          onChange={(e) => setAddress({ ...address, street: e.target.value })}
          fullWidth
          margin="normal"
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="City"
          value={address.city}
          onChange={(e) => setAddress({ ...address, city: e.target.value })}
          fullWidth
          margin="normal"
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="State"
          value={address.state}
          onChange={(e) => setAddress({ ...address, state: e.target.value })}
          fullWidth
          margin="normal"
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Zip Code"
          value={address.zipCode}
          onChange={(e) => setAddress({ ...address, zipCode: e.target.value })}
          fullWidth
          margin="normal"
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Country"
          value={address.country}
          onChange={(e) => setAddress({ ...address, country: e.target.value })}
          fullWidth
          margin="normal"
        />
      </Grid>
    </>
  );
};

export default ContactFields;
