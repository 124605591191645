import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export default styled(Box)(({ theme, ownerState }) => {
  const { palette, typography, borders, functions } = theme;
  const { color } = ownerState;

  const { white, gradients } = palette;
  const { fontSizeRegular, fontWeightMedium } = typography;
  const { borderRadius } = borders;
  const { pxToRem, linearGradient } = functions;
  const darkMode = theme.darkMode;
  // backgroundColor value
  const backgroundColorValue =
    color === "error"
      ? darkMode
        ? "rgba(255, 0, 0, 0.3)"
        : "rgba(255, 0, 0, 0.7)"
      : color === "success"
        ? darkMode
          ? "rgba(0, 255, 0, 0.3)"
          : "rgba(0, 255, 0, 0.4)"
        : gradients[color]
          ? linearGradient(gradients[color].main, gradients[color].state)
          : linearGradient(gradients.info.main, gradients.info.state);

  // textColor value
  const textColorValue =
    color === "success" && !darkMode ? "#495975" : white.main;

  return {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: pxToRem(60),
    backgroundColor: backgroundColorValue,
    color: textColorValue,
    position: "relative",
    padding: pxToRem(16),
    marginBottom: pxToRem(16),
    borderRadius: borderRadius.md,
    fontSize: fontSizeRegular,
    fontWeight: fontWeightMedium,
  };
});
