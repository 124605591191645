import React, { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
} from "@mui/material";
import MDButton from "shared/components/MDComponents/MDButton";
import { createUser } from "services/UserService";
import Validation from "shared/components/UIElements/Validation/Validation";
import { useSelector } from "react-redux";
import useFetchTenant from "shared/hooks/useFetchTenant";
import MDBox from "shared/components/MDComponents/MDBox";
import MDAlert from "shared/components/MDComponents/MDAlert";
import MDTypography from "shared/components/MDComponents/MDTypography";

const CreateUserDialog = ({ open, onClose, onAddUser }) => {
  const [newUserData, setNewUserData] = useState({
    name: "",
    email: "",
    password: "",
    role: "user",
  });
  const [loading, setLoading] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const subdomain = useSelector((state) => state.subdomain.value);
  const { tenant } = useFetchTenant(subdomain);

  const [limitAlert, setLimitAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });

  const hasReachedContentLimit = tenant?.currentUsers >= tenant?.maxUsers;

  const showNotification = useCallback((message, type, setAlertFunction) => {
    setAlertFunction({ show: true, message, type });
  }, []);

  useEffect(() => {
    if (hasReachedContentLimit) {
      showNotification(
        "You have reached the maximum number of users allowed. Please delete existing users or contact support to upgrade.",
        "error",
        setLimitAlert
      );
    }
  }, [hasReachedContentLimit, showNotification]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUserData({ ...newUserData, [name]: value });
  };

  const isValid = () => {
    const { name, email, password } = newUserData;
    const minLength = 8;
    const maxLength = 30;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordStrengthRegex = {
      length: /^.{8,30}$/,
      uppercase: /[A-Z]/,
      lowercase: /[a-z]/,
      number: /[0-9]/,
      special: /[!@#$%^&*(),.?":{}|<>]/,
    };

    const isPasswordValid =
      passwordStrengthRegex.length.test(password) &&
      passwordStrengthRegex.uppercase.test(password) &&
      passwordStrengthRegex.lowercase.test(password) &&
      passwordStrengthRegex.number.test(password) &&
      passwordStrengthRegex.special.test(password);

    return (
      name.length > 0 &&
      emailRegex.test(email) &&
      isPasswordValid &&
      password.length >= minLength &&
      password.length <= maxLength
    );
  };

  const handleSubmit = async () => {
    if (isValid()) {
      setLoading(true);
      setShowValidation(false);
      try {
        const newUserWithTenant = { ...newUserData, tenant: subdomain };
        const newUser = await createUser(newUserWithTenant);
        onAddUser(newUser);
        onClose();
      } catch (error) {
        console.error("Error creating user:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setShowValidation(true);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create New User</DialogTitle>

      {limitAlert.show && (
        <MDBox sx={{ position: "relative" }}>
          <MDAlert color={limitAlert.type} dismissible>
            <MDTypography variant="body2" color="white">
              {limitAlert.message}
            </MDTypography>
          </MDAlert>
        </MDBox>
      )}

      <DialogContent>
        {showValidation && (
          <Validation
            fields={[
              {
                name: "Name",
                value: newUserData.name,
                minLength: 3,
                maxLength: 30,
              },
              { name: "Email", value: newUserData.email, maxLength: 50 },
              {
                name: "Password",
                value: newUserData.password,
                minLength: 8,
                maxLength: 30,
              },
            ]}
          />
        )}
        <TextField
          autoFocus
          margin="dense"
          name="name"
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          value={newUserData.name}
          onChange={handleInputChange}
          inputProps={{ maxLength: 30 }}
        />
        <TextField
          margin="dense"
          name="email"
          label="Email"
          type="email"
          fullWidth
          variant="outlined"
          value={newUserData.email}
          onChange={handleInputChange}
          inputProps={{ maxLength: 50 }}
        />
        <TextField
          margin="dense"
          name="password"
          label="Password"
          type="password"
          fullWidth
          variant="outlined"
          value={newUserData.password}
          onChange={handleInputChange}
          inputProps={{ minLength: 8, maxLength: 30 }}
        />
        <TextField
          select
          margin="dense"
          label="Role"
          name="role"
          value={newUserData.role}
          onChange={handleInputChange}
          fullWidth
          SelectProps={{
            sx: {
              height: 42,
              "& .MuiSelect-select": {
                paddingTop: "10px",
                paddingBottom: "10px",
              },
            },
          }}
        >
          <MenuItem value="user">User</MenuItem>
          <MenuItem value="manager">Manager</MenuItem>
          <MenuItem value="owner">Owner</MenuItem>
        </TextField>
      </DialogContent>
      <DialogActions>
        <MDButton
          onClick={handleSubmit}
          variant="contained"
          color="dark"
          disabled={loading || hasReachedContentLimit}
        >
          {loading ? "Creating..." : "Create"}
        </MDButton>
        <MDButton variant="contained" color="dark" onClick={onClose}>
          Cancel
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateUserDialog;
