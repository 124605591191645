import React from "react";
import { Show, SimpleShowLayout, TextField, EmailField } from "react-admin";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useRedirect } from "react-admin";

export const TenantShow = (props) => {
  const redirect = useRedirect();

  const handleBackClick = () => {
    redirect("/admin/tenant");
  };

  return (
    <Show {...props} resource="tenant">
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton onClick={handleBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <h3 style={{ marginLeft: "10px" }}>Tenant Details</h3>
      </div>
      <SimpleShowLayout>
        <TextField source="organizationName" label="Organization Name" />
        <TextField source="subdomain" label="Subdomain" />
        <EmailField source="contactEmail" label="Contact Email" />
        <TextField source="contactPhone" label="Contact Phone" />
        <TextField source="status" label="Service" />
        {/* Optionally show address fields */}
        <TextField source="address.street" label="Street" />
        <TextField source="address.city" label="City" />
        <TextField source="address.state" label="State" />
        <TextField source="address.zipCode" label="Zip Code" />
        <TextField source="address.country" label="Country" />
      </SimpleShowLayout>
    </Show>
  );
};
