import { useState, useEffect, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearUser, fetchLoggedUser } from "features/user/store/UserSlice";
import { performLogout } from "auth/components/logout";

export function useAuthManager(subdomainValid) {
  const {
    isAuthenticated,
    isLoading: authLoading,
    getAccessTokenSilently,
    loginWithRedirect,
    user,
    audience,
  } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const subdomain = useSelector((state) => state.subdomain.value);

  // Special routes where we skip auth logic
  const specialRoutes = useMemo(
    () => [
      "/register",
      "/404",
      "/register-user",
      "/tenant-mismatch",
      "/domain-not-found",
      "/registration-success",
      "/payment-cancelled",
      "/payment-success",
      "/admin",
      "/admin/*",
    ],
    []
  );

  const isStreamRoute = /^\/stream\/.+/.test(window.location.pathname);

  const handleLogout = () => {
    performLogout();
  };

  useEffect(() => {
    if (!subdomainValid) return;
    // Handle auth state only if NOT on a special route
    if (!specialRoutes.includes(window.location.pathname) && !isStreamRoute) {
      const manageAuthState = async () => {
        try {
          if (isAuthenticated && user) {
            const accessToken = await getAccessTokenSilently({ audience });
            localStorage.setItem("auth_token", accessToken);

            const resultAction = await dispatch(
              fetchLoggedUser({
                token: accessToken,
                subdomain,
                emailVerified: user.email_verified,
              })
            );

            if (fetchLoggedUser.rejected.match(resultAction)) {
              if (resultAction.payload === "User not found") {
                await fetch(
                  `${process.env.REACT_APP_API_URL}/api/user/signup`,
                  {
                    method: "POST",
                    headers: {
                      Authorization: `Bearer ${accessToken}`,
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      auth0Id: user.sub,
                      email: user.email,
                      name: user.name,
                      role: "user",
                    }),
                  }
                );
                await dispatch(
                  fetchLoggedUser({ token: accessToken, subdomain })
                );
              } else {
                throw new Error(resultAction.payload);
              }
            }
          } else if (!authLoading) {
            // User is not authenticated
            localStorage.removeItem("auth_token");
            dispatch(clearUser());
          }
        } catch (error) {
          console.error(
            "Error during auth management:",
            error.message || error
          );
          localStorage.removeItem("auth_token");

          // Handle tenant mismatch and other errors
          if (error.message === "tenant_mismatch") {
            console.error(
              "Tenant mismatch detected. Redirecting to tenant mismatch page."
            );
            navigate("/tenant-mismatch"); // Redirect to the tenant mismatch page
          } else {
            handleLogout();
          }
        } finally {
          setLoading(false);
        }
      };

      manageAuthState();
    } else {
      setLoading(false); // Stop loading on special routes
    }
  }, [
    isAuthenticated,
    authLoading,
    getAccessTokenSilently,
    dispatch,
    user,
    audience,
    navigate,
    subdomain,
    subdomainValid,
    isStreamRoute,
    specialRoutes,
  ]);

  useEffect(() => {
    if (!subdomainValid) return;
    // Handle Auth0 error from the URL and prevent re-authentication loop
    const params = new URLSearchParams(window.location.search);
    const error = params.get("error");
    const errorDescription = params.get("error_description");

    // Handle tenant mismatch directly from URL
    if (error === "access_denied" && errorDescription === "tenant_mismatch") {
      console.error(
        "Tenant mismatch detected. Redirecting to tenant mismatch page."
      );
      navigate("/tenant-mismatch"); // Redirect to the tenant mismatch page
      setLoading(false); // Prevent further actions
      return;
    }

    // Check if the user is authenticated, if not redirect to login, but skip for special routes
    if (
      !isAuthenticated &&
      !authLoading &&
      !specialRoutes.includes(window.location.pathname) &&
      !isStreamRoute
    ) {
      const returnToPath = window.location.pathname + window.location.search;
      loginWithRedirect({
        audience,
        redirect_uri: window.location.origin,
        appState: { returnTo: returnToPath },
      });
    }
  }, [
    isAuthenticated,
    authLoading,
    loginWithRedirect,
    audience,
    navigate,
    subdomainValid,
    isStreamRoute,
    specialRoutes,
  ]);

  return { loading, authLoading };
}
