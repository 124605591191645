import { Box } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import MDButton from "shared/components/MDComponents/MDButton";
import MDTypography from "shared/components/MDComponents/MDTypography";

const RegistrationSuccess = () => {
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const subdomain = params.get("subdomain");

  const handleGoToLogin = () => {
    // Redirect to the login page for the user's subdomain
    window.location.href = `https://${subdomain}.seriouslyds.com/venues`;
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      textAlign="center"
    >
      <MDTypography variant="h3" fontWeight="bold">
        Congratulations on Signing Up!
      </MDTypography>
      <MDTypography variant="body1" mt={2} mb={3}>
        Your application is ready and can be accessed at:
        <br />
        <a href={`https://${subdomain}.seriouslyds.com`}>
          {subdomain}.seriouslyds.com
        </a>
      </MDTypography>
      <MDButton variant="contained" color="dark" onClick={handleGoToLogin}>
        Go to Login
      </MDButton>
    </Box>
  );
};

export default RegistrationSuccess;
