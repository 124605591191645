import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import {
  Create,
  SimpleForm,
  SelectInput,
  NumberInput,
  useNotify,
  useRedirect,
  useCreate,
  useDataProvider,
  DateInput,
} from "react-admin";
import CustomToolbar from "../../utils/CustomToolbar";

const planChoices = [{ id: "basic", name: "Basic" }];
const billingCycleChoices = [
  { id: "monthly", name: "Monthly" },
  { id: "annual", name: "Annual" },
];

export const SubscriptionCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [create] = useCreate();
  const dataProvider = useDataProvider();
  const [tenants, setTenants] = useState([]);

  useEffect(() => {
    dataProvider
      .getList("tenant", { pagination: { page: 1, perPage: 50 } })
      .then(({ data }) => {
        const tenantChoices = data.map((tenant) => ({
          id: tenant.id,
          name: tenant.organizationName,
        }));
        setTenants(tenantChoices);
      })
      .catch((error) => {
        notify(`Error fetching tenants: ${error.message}`, { type: "error" });
      });
  }, [dataProvider, notify]);

  const handleSubmit = async (data) => {
    try {
      const formData = {
        tenant: data.tenant,
        subscriptionPlan: data.plan,
        billingCycle: data.billingCycle,
        pricePerScreen: data.price,
        screens: data.screens,
        startDate: data.startDate,
        endDate: data.endDate,
      };
      await create("subscription", { data: formData });
      notify("Subscription created successfully", { type: "info" });
      redirect("/admin/subscription");
    } catch (error) {
      notify(`Error: ${error.message}`, { type: "error" });
    }
  };

  return (
    <Create {...props} resource="subscription">
      <SimpleForm
        toolbar={<CustomToolbar resource="subscription" />}
        onSubmit={handleSubmit}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SelectInput
              source="tenant"
              choices={tenants}
              fullWidth
              label="Customer"
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              source="plan"
              choices={planChoices}
              fullWidth
              label="Plan"
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              source="billingCycle"
              choices={billingCycleChoices}
              fullWidth
              label="Billing Cycle"
            />
          </Grid>
          <Grid item xs={6}>
            <NumberInput source="price" label="Price per Screen" fullWidth />
          </Grid>
          <Grid item xs={6}>
            <NumberInput source="screens" label="Number of Screens" fullWidth />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};
