import ScreenItem from "./ScreenItem";

const ScreenList = (props) => {
  const { item, spacing, selectedItemId } = props;

  return (
    <ScreenItem
      key={item.id}
      id={item.id}
      tvId={item.tvId}
      image={item.image}
      title={item.title}
      assignedItem={item.assignedItem}
      venue={item.venue}
      spacing={spacing}
      isSelected={item.id === selectedItemId}
    />
  );
};

export default ScreenList;
