import React from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MDButton from "shared/components/MDComponents/MDButton";
import MDTypography from "shared/components/MDComponents/MDTypography";

const PageNotFound = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      textAlign="center" // Add textAlign for centering text
    >
      <MDTypography variant="h3" gutterBottom>
        404 - Page Not Found
      </MDTypography>
      <MDTypography variant="body1" mt={2} mb={3}>
        Oops! The page you're looking for doesn't exist.
        <br />
        Please check the URL or return to the homepage.
      </MDTypography>
      <MDButton onClick={goHome} variant="contained" color="dark">
        Go Back Home
      </MDButton>
    </Box>
  );
};

export default PageNotFound;
