import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

// Material Dashboard 2 PRO React components
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";

const VenueItem = ({
  id,
  image,
  signedUrl,
  title,
  screens,
  onDelete,
  onSelect,
}) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);

  const handleVenueSelect = () => {
    onSelect({
      id,
      title,
      image,
      screens,
      // ... other venue details
    });

    navigate(`/dashboard`);
  };

  const handleDelete = (event) => {
    event.stopPropagation();
    onDelete(id);
  };

  const canDelete =
    user &&
    (user.role === "manager" ||
      user.role === "superadmin" ||
      user.role === "owner");

  const screenCount = Array.isArray(screens) ? screens.length : 0;

  return (
    <Card
      sx={{
        cursor: "pointer",
        "&:hover .image-container": {
          transform: "translate3d(0, -50px, 0)",
        },
        "&:hover .actions-container": {
          opacity: 1,
          visibility: "visible",
        },
      }}
    >
      <MDBox
        position="relative"
        borderRadius="lg"
        mt={-3}
        mx={2}
        className="card-header"
        sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
      >
        <MDBox
          position="relative"
          className="image-container"
          sx={{
            transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)",
          }}
        >
          <MDBox
            component="img"
            src={signedUrl}
            alt={title}
            borderRadius="lg"
            shadow="md"
            width="100%"
            height="200px"
            position="relative"
            zIndex={1}
            sx={{
              objectFit: "cover",
            }}
          />
          <MDBox
            borderRadius="lg"
            shadow="md"
            width="100%"
            height="100%"
            position="absolute"
            left={0}
            top="0"
            sx={{
              backgroundImage: `url(${signedUrl})`,
              transform: "scale(0.94)",
              filter: "blur(12px)",
              backgroundSize: "cover",
            }}
          />
        </MDBox>
        <MDBox
          position="absolute"
          top="80%"
          left="0"
          right="0"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className="actions-container"
          sx={{
            opacity: 0,
            visibility: "hidden",
            transition: "opacity 300ms, visibility 300ms",
            px: 4,
          }}
        >
          <MDTypography
            onClick={handleVenueSelect}
            variant="button"
            fontWeight="light"
            color="info"
            sx={{ flexGrow: 1 }}
          >
            Go to Venue
          </MDTypography>
          {canDelete && (
            <MDBox display="flex" alignItems="center">
              <IconButton onClick={handleDelete} sx={{ color: "white" }}>
                <DeleteIcon />
              </IconButton>
            </MDBox>
          )}
        </MDBox>
      </MDBox>
      <MDBox textAlign="center" px={3}>
        <MDTypography variant="h5" fontWeight="regular" sx={{ mt: 4 }}>
          {title}
        </MDTypography>
        <MDTypography variant="body2" color="text" sx={{ mt: 1.5, mb: 1 }}>
          {screenCount} {screenCount === 1 ? "Screen" : "Screens"}
        </MDTypography>
      </MDBox>
      <Divider />
    </Card>
  );
};

VenueItem.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  signedUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  screens: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default VenueItem;
