import React from "react";
import { Card, TextField, IconButton, Box, useMediaQuery } from "@mui/material";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";
import UnifiedDropzone from "./UnifiedDropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material/styles";

const ScheduleDroppableArea = ({
  onDrop,
  showings,
  handleRemoveShowing,
  handleTitleChange,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  return (
    <UnifiedDropzone accept="media" onDrop={onDrop}>
      {showings.length === 0 && (
        <MDTypography
          variant="caption"
          display="block"
          gutterBottom
          sx={{ textAlign: "center", marginTop: 2 }}
        >
          Drag items here to add them to the schedule
        </MDTypography>
      )}
      {showings.length > 0 &&
        showings.map((showing, index) => (
          <Card
            key={showing.uniqueId}
            sx={{
              opacity: 1,
              marginBottom: 1,
              width: isSmallScreen ? "100%" : isMediumScreen ? "80%" : "60%",
            }}
          >
            <MDBox
              display="flex"
              flexDirection={isSmallScreen ? "column" : "row"}
              alignItems={isSmallScreen ? "stretch" : "center"}
              justifyContent="space-between"
              mx={2}
              p={2}
              bgcolor="background.paper"
              boxShadow={1}
            >
              <Box
                sx={{
                  cursor: "pointer",
                  width: isSmallScreen ? "100%" : "100px",
                  height: "100px",
                  borderRadius: "10px",
                  overflow: "hidden",
                  backgroundImage: `url(${showing.signedImageUrl})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  marginBottom: isSmallScreen ? 2 : 0,
                  transition:
                    "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0px 4px 10px rgba(0,0,0,0.3)",
                  },
                }}
              />
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems={isSmallScreen ? "stretch" : "flex-start"}
                sx={{ width: "100%", flex: 1, mx: isSmallScreen ? 0 : 2 }}
              >
                <TextField
                  value={showing.name}
                  onChange={(e) => handleTitleChange(index, e.target.value)}
                  label="Title"
                  sx={{
                    marginBottom: 2,
                    width: "100%",
                  }}
                />

                <MDTypography sx={{ marginRight: 2 }}>
                  {showing.startTime} - {showing.endTime}
                </MDTypography>
              </MDBox>
              <IconButton
                onClick={() => handleRemoveShowing(index)}
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            </MDBox>
          </Card>
        ))}
    </UnifiedDropzone>
  );
};

export default ScheduleDroppableArea;
