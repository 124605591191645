import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  NumberField,
  useRedirect,
} from "react-admin";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const ResourceShow = (props) => {
  const redirect = useRedirect();

  const handleBackClick = () => {
    redirect("/admin/tenant/resource");
  };

  return (
    <Show {...props} resource="tenant/resource">
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton onClick={handleBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <h3 style={{ marginLeft: "10px" }}>Resource Details</h3>
      </div>
      <SimpleShowLayout>
        <TextField source="organizationName" label="Organization" />
        <NumberField source="currentScreens" label="Current Screens" />
        <NumberField source="maxScreens" label="Max Screens" />
        <NumberField source="currentVenues" label="Current Venues" />
        <NumberField source="maxVenues" label="Max Venues" />
        <NumberField source="currentUsers" label="Current Users" />
        <NumberField source="maxUsers" label="Max Users" />
        <NumberField
          source="currentContentItems"
          label="Current Content Items"
        />
        <NumberField source="maxContentItems" label="Max Content Items" />
        <NumberField source="totalMediaSize" label="Current Media Size (MB)" />
        <NumberField source="maxMediaSize" label="Max Media Size (MB)" />
      </SimpleShowLayout>
    </Show>
  );
};
