import React, { useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  FunctionField,
  FilterButton,
  CreateButton,
  ExportButton,
  TopToolbar,
  TextInput,
  Button,
  SelectInput,
} from "react-admin";
import ThreeDotsMenu from "../users/ThreeDotsMenu";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { deleteTenant } from "services/TenantService"; // API to delete the tenant

// Custom styles for status bubbles
const statusColors = {
  active: "green",
  pending: "#ffc107",
  inactive: "grey",
  denied: "red",
};

const StatusBubble = ({ record }) => {
  const status = record.status;
  const color = statusColors[status] || "grey";
  return (
    <Box
      component="span"
      sx={{
        backgroundColor: color,
        color: "white",
        padding: "4px 8px",
        borderRadius: "12px",
        fontSize: "0.875rem",
        textTransform: "capitalize",
      }}
    >
      {status}
    </Box>
  );
};

const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return "";
  const cleaned = phoneNumber.replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phoneNumber;
};

const statusChoices = [
  { id: "active", name: "Active" },
  { id: "inactive", name: "Inactive" },
  { id: "terminated", name: "Terminated" },
];

const TenantFilters = [
  <TextInput label="Search by name" source="q" alwaysOn />,
  <SelectInput
    label="Search by status"
    source="status"
    choices={statusChoices}
  />,
];

const TenantListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const TenantList = (props) => {
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [tenantToDelete, setTenantToDelete] = useState(null);

  const handleDeleteTenant = async () => {
    if (tenantToDelete) {
      try {
        await deleteTenant(tenantToDelete.id); // Call the delete API
        setDeleteConfirmOpen(false);
        setTenantToDelete(null);
        // Optionally refresh the list here after deleting
      } catch (error) {
        console.error("Failed to delete tenant:", error);
      }
    }
  };

  const openDeleteConfirm = (tenant) => {
    setTenantToDelete(tenant);
    setDeleteConfirmOpen(true);
  };

  const closeDeleteConfirm = () => {
    setDeleteConfirmOpen(false);
    setTenantToDelete(null);
  };

  return (
    <>
      <List
        {...props}
        filters={TenantFilters}
        resource="tenant"
        actions={<TenantListActions />}
      >
        <Datagrid rowClick="show" bulkActionButtons={false}>
          <TextField source="organizationName" label="Customer Name" />
          <TextField source="subdomain" label="Subdomain" />
          <EmailField source="contactEmail" label="Contact Email" />
          <FunctionField
            label="Contact Phone"
            render={(record) => formatPhoneNumber(record.contactPhone)}
          />
          <FunctionField
            label="Service"
            render={(record) => <StatusBubble record={record} />}
          />
          <ThreeDotsMenu resource="tenant" onDelete={openDeleteConfirm} />
        </Datagrid>
      </List>

      <Dialog
        open={deleteConfirmOpen}
        onClose={closeDeleteConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this customer? This action will
            delete all customer records, cancel any active subscriptions, and
            cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteTenant}
            variant="contained"
            color="error"
          >
            Delete
          </Button>
          <Button onClick={closeDeleteConfirm}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
