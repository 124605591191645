import React from "react";
import { useSelector } from "react-redux";
import MDTypography from "../../MDComponents/MDTypography";

const CurrentVenueTitle = () => {
  const selectedVenue = useSelector((state) => state.venue.selectedVenue);
  const venueTitle = selectedVenue ? selectedVenue.title : null;
  return (
    <MDTypography
      color="white"
      display="block"
      variant="caption"
      fontWeight="bold"
      textTransform="uppercase"
      mt={2}
      mb={1}
    >
      {venueTitle || ""}
    </MDTypography>
  );
};

export default CurrentVenueTitle;
