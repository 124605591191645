import ScheduleItem from "./ScheduleItem";

const ScheduleList = (props) => {
  const { item, spacing, selectedItemId } = props;

  return (
    <ScheduleItem
      id={item.id}
      image={item.image}
      title={item.title}
      description={item.description}
      venueId={item.venue}
      banner={item.banner}
      screenFit={item.screenFit}
      mediaLength={item.mediaLength}
      daysOfWeek={item.daysOfWeek}
      showings={item.showings}
      spacing={spacing}
      isSelected={item.id === selectedItemId}
    />
  );
};

export default ScheduleList;
