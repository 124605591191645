import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

const PasswordFields = ({
  password,
  setPassword,
  verifyPassword,
  setVerifyPassword,
  setPasswordValid,
}) => {
  const [passwordError, setPasswordError] = useState("");
  const [verifyPasswordError, setVerifyPasswordError] = useState("");

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    if (!isPasswordValid(value)) {
      setPasswordError(
        "Password must be 8-30 characters long, with at least one uppercase letter, one lowercase letter, one number, and one special character."
      );
      setPasswordValid(false);
    } else {
      setPasswordError("");
      setPasswordValid(true);
    }
  };

  const handleVerifyPasswordChange = (e) => {
    const value = e.target.value;
    setVerifyPassword(value);

    if (value !== password) {
      setVerifyPasswordError("Passwords do not match.");
    } else {
      setVerifyPasswordError("");
    }
  };

  return (
    <>
      <Grid item xs={6}>
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={handlePasswordChange}
          fullWidth
          required
          margin="normal"
          helperText={passwordError}
          error={Boolean(passwordError)}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Verify Password"
          type="password"
          value={verifyPassword}
          onChange={handleVerifyPasswordChange}
          fullWidth
          required
          margin="normal"
          helperText={verifyPasswordError}
          error={Boolean(verifyPasswordError)}
        />
      </Grid>
    </>
  );
};

const isPasswordValid = (password) => {
  const passwordStrengthRegex = {
    length: /^.{8,30}$/, // Between 8 and 30 characters
    uppercase: /[A-Z]/,
    lowercase: /[a-z]/,
    number: /[0-9]/,
    special: /[!@#$%^&*(),.?":{}|<>]/,
  };

  return (
    passwordStrengthRegex.length.test(password) &&
    passwordStrengthRegex.uppercase.test(password) &&
    passwordStrengthRegex.lowercase.test(password) &&
    passwordStrengthRegex.number.test(password) &&
    passwordStrengthRegex.special.test(password)
  );
};

export default PasswordFields;
