// admin/AdminProvider.js
import React, { createContext, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdminData } from "../admin/store/adminSlice";

const AdminContext = createContext();

export const useAdminData = () => useContext(AdminContext);

const AdminProvider = ({ children }) => {
  const dispatch = useDispatch();
  const adminData = useSelector((state) => state.admin);

  useEffect(() => {
    if (adminData.status === "idle") {
      dispatch(fetchAdminData());
    }
  }, [dispatch, adminData.status]);

  return (
    <AdminContext.Provider value={adminData}>{children}</AdminContext.Provider>
  );
};

export default AdminProvider;
