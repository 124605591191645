import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Stepper, Step, StepLabel, Card, Grid } from "@mui/material";
import MDBox from "shared/components/MDComponents/MDBox";
import MDButton from "shared/components/MDComponents/MDButton";
import MDTypography from "shared/components/MDComponents/MDTypography";
import BasicDetails from "../components/registration/BasicDetails";
import Validation from "shared/components/UIElements/Validation/Validation";
import { checkSubdomain, updateTenant } from "services/TenantService";
import { createUser, fetchUserByEmail } from "services/UserService";

const RegisterTenant = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const [subdomain, setSubdomain] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [address, setAddress] = useState({
    street: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  });
  const [error, setError] = useState("");
  const [token, setToken] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [showValidation, setShowValidation] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);

  const [isSubdomainValid, setIsSubdomainValid] = useState(true); // Add this
  const [isEmailValid, setIsEmailValid] = useState(true); // Add this
  const [isCheckingSubdomain, setIsCheckingSubdomain] = useState(false); // Track if checking subdomain
  const [isCheckingEmail, setIsCheckingEmail] = useState(false); // Track if checking email

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tokenParam = params.get("token");
    const tenantIdParam = params.get("tenantId");
    if (!tokenParam || !tenantIdParam) {
      navigate("/404");
    } else {
      setToken(tokenParam);
      setTenantId(tenantIdParam); // Save tenantId for the update request
    }
  }, [location.search, navigate]);

  // Check if subdomain exists when it changes
  useEffect(() => {
    if (subdomain.trim()) {
      setIsCheckingSubdomain(true);
      checkSubdomain(subdomain)
        .then((response) => {
          setIsSubdomainValid(!response.exists);
        })
        .catch((err) => {
          setError("Failed to check subdomain availability.");
        })
        .finally(() => {
          setIsCheckingSubdomain(false);
        });
    }
  }, [subdomain]);

  // Check if email exists when it changes
  useEffect(() => {
    if (contactEmail.trim()) {
      setIsCheckingEmail(true);
      fetchUserByEmail(contactEmail)
        .then((response) => {
          setIsEmailValid(!response); // Email is valid if the user does not exist
        })
        .catch((err) => {
          setError("Failed to check email availability.");
        })
        .finally(() => {
          setIsCheckingEmail(false);
        });
    }
  }, [contactEmail]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValid()) {
      const tenantData = {
        subdomain,
        organizationName,
        contactEmail,
        contactPhone,
        address,
        status: "active",
      };

      try {
        const tenantResponse = await updateTenant(tenantId, tenantData);
        if (tenantResponse) {
          const userData = {
            email: contactEmail,
            name,
            password,
            role: "owner",
            tenant: tenantData.subdomain,
          };

          try {
            const userResponse = await createUser(userData);
            if (userResponse) {
              navigate(`/registration-success?subdomain=${subdomain}`);
            }
          } catch (userError) {
            setError(userError.message || "User creation failed.");
          }
        }
      } catch (err) {
        setError(err.message || "Registration failed.");
      }
    } else {
      setError("Please ensure passwords match and meet the strength criteria.");
      setShowValidation(true);
    }
  };

  const isValid = () => {
    return (
      subdomain.trim() &&
      organizationName.trim() &&
      name.trim() &&
      contactEmail.trim() &&
      password.trim() &&
      verifyPassword.trim() &&
      password === verifyPassword &&
      passwordValid &&
      isSubdomainValid && // Add this check
      isEmailValid // Add this check
    );
  };

  const flavorText = (
    <MDBox mt={3} mb={3} mx={5} textAlign="center">
      <MDTypography variant="body1">
        Thank you for choosing SeriouslyDS for your digital signage needs. We’re
        excited to help you get started! If you have any questions, feel free to
        reach out to customer support at support@seriouslyds.com
      </MDTypography>
      <MDTypography variant="body1" mt={2}>
        Fill out the information below to set up your account.
      </MDTypography>
    </MDBox>
  );

  const steps = ["Basic Details", "Additional Setup"];

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            {flavorText}
            <MDTypography variant="subtitle2">
              Fields marked with an asterisk (*) are required.
            </MDTypography>
            <BasicDetails
              subdomain={subdomain}
              setSubdomain={setSubdomain}
              organizationName={organizationName}
              setOrganizationName={setOrganizationName}
              name={name}
              setName={setName}
              password={password}
              setPassword={setPassword}
              verifyPassword={verifyPassword}
              setVerifyPassword={setVerifyPassword}
              contactEmail={contactEmail}
              setContactEmail={setContactEmail}
              contactPhone={contactPhone}
              setContactPhone={setContactPhone}
              address={address}
              setAddress={setAddress}
              showValidation={showValidation}
              setPasswordValid={setPasswordValid}
            />
          </>
        );
      case 1:
        return null; // Placeholder for future steps
      default:
        return "Unknown step";
    }
  };

  return (
    <MDBox mt={5} mb={9}>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={8}>
          <MDBox mt={6} mb={8} textAlign="center">
            <MDBox mb={1}>
              <MDTypography variant="h3" fontWeight="bold">
                Welcome to SeriouslyDS!
              </MDTypography>
            </MDBox>
            <MDTypography variant="h5" fontWeight="regular" color="secondary">
              Fill out the information below to set up your application.
            </MDTypography>
          </MDBox>
          <Card>
            <MDBox mt={-3} mb={3} mx={2}>
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                sx={{ background: "#344767 !important" }}
              >
                {steps.map((label, index) => (
                  <Step key={index}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </MDBox>
            <MDBox p={2}>
              {getStepContent(activeStep)}
              {error && (
                <MDTypography variant="body2" color="error" mt={2}>
                  {error}
                </MDTypography>
              )}
              {showValidation && (
                <Validation
                  fields={[
                    { name: "Subdomain", value: subdomain },
                    { name: "Organization Name", value: organizationName },
                    { name: "User Name", value: name },
                    { name: "Contact Email", value: contactEmail },
                    { name: "Password", value: password },
                  ]}
                />
              )}
              <MDBox mt={3} display="flex" justifyContent="space-between">
                <MDBox />
                <MDButton
                  variant="contained"
                  color="dark"
                  onClick={handleSubmit}
                  disabled={
                    !isValid() || isCheckingSubdomain || isCheckingEmail // Disable if invalid or checking
                  }
                >
                  Register
                </MDButton>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default RegisterTenant;
