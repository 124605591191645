// src/pages/Unauthorized.js

import React from "react";
import MDButton from "shared/components/MDComponents/MDButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { performLogout } from "auth/components/logout";

const Unauthorized = () => {
  const handleLogout = () => {
    performLogout();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      textAlign="center"
    >
      <Typography variant="h1">Unauthorized Access</Typography>
      <Typography variant="body1" mb={3}>
        You do not have permission to view this page.
      </Typography>
      <MDButton variant="contained" color="dark" onClick={() => handleLogout()}>
        Go to Login
      </MDButton>
    </Box>
  );
};

export default Unauthorized;
