import React from "react";
import { Box } from "@mui/material";

const CustomAvatar = ({ src, alt, name, size = 100, url }) => {
  const isVideo = (url) => {
    const videoExtensions = ["mp4", "mpeg", "mov", "mkv"];
    const extension = url.split(".").pop().toLowerCase();
    return videoExtensions.includes(extension);
  };

  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: "50%",
        overflow: "hidden",
        display: "inline-block",
        position: "relative",
      }}
      name={name}
      aria-label={alt}
    >
      {isVideo(url) ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#000", // Optional: to improve visibility of the video
          }}
        >
          <video
            src={src}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Box>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundImage: `url(${src})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {/* Hidden img tag for accessibility */}
          <img src={src} alt={alt} style={{ display: "none" }} />
        </div>
      )}
    </div>
  );
};

export default CustomAvatar;
