import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import {
  Edit,
  SimpleForm,
  SelectInput,
  NumberInput,
  useDataProvider,
  useNotify,
  useRedirect,
  TextInput,
} from "react-admin";
import CustomToolbar from "../../utils/CustomToolbar";

// Options for max media size in GB and MB with their corresponding values in MB
const mediaSizeOptions = [
  { id: 500, name: "500 MB (500 MB)" },
  { id: 1024, name: "1 GB (1024 MB)" },
  { id: 2048, name: "2 GB (2048 MB)" },
  { id: 5120, name: "5 GB (5120 MB)" }, // Default option
  { id: 10240, name: "10 GB (10240 MB)" },
  { id: 25600, name: "25 GB (25600 MB)" },
  { id: 51200, name: "50 GB (51200 MB)" },
];

export const ResourceEdit = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const [selectedMediaSize, setSelectedMediaSize] = useState(null);

  const handleMediaSizeChange = (e) => {
    setSelectedMediaSize(e.target.value);
  };

  const handleSubmit = async (values) => {
    try {
      const { maxScreens, maxVenues, maxContentItems, maxUsers } = values;
      const maxMediaSize = selectedMediaSize || values.maxMediaSize;

      const formData = {
        maxScreens,
        maxVenues,
        maxContentItems,
        maxMediaSize,
        maxUsers,
      };

      await dataProvider.update("tenant/resource", {
        id: values.id,
        data: formData,
      });

      notify("Resources updated successfully", { type: "info" });
      redirect("list", "tenant/resource");
    } catch (error) {
      notify(`Error updating resources: ${error.message}`, { type: "error" });
    }
  };

  return (
    <Edit {...props} resource="tenant/resource">
      <SimpleForm
        toolbar={<CustomToolbar resource="tenant/resource" />}
        onSubmit={handleSubmit}
        redirect="list"
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextInput
              source="organizationName"
              label="Organization"
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <NumberInput source="maxScreens" label="Max Screens" />
          </Grid>
          <Grid item xs={6}>
            <NumberInput source="maxVenues" label="Max Venues" />
          </Grid>
          <Grid item xs={6}>
            <NumberInput source="maxUsers" label="Max Users" />
          </Grid>
          <Grid item xs={6}>
            <NumberInput source="maxContentItems" label="Max Content Items" />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              source="maxMediaSize"
              label="Max Media Size"
              choices={mediaSizeOptions}
              onChange={handleMediaSizeChange}
            />
            {selectedMediaSize && (
              <Typography variant="body2">
                Selected value in MB: {selectedMediaSize} MB
              </Typography>
            )}
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};
