import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import store from "./store";
import ErrorBoundary from "shared/util/errorBoundary"; // Custom error boundary

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "./context/ComponentContext";
import { VenueProvider } from "./context/VenueContext";
import Auth0ProviderWithHistory from "./context/auth0-provider-with-history";
import * as Sentry from "@sentry/react";

// Initialize Sentry
Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <Auth0ProviderWithHistory>
          <VenueProvider>
            <Sentry.ErrorBoundary fallback={<p>An error has occurred.</p>}>
              <ErrorBoundary>
                <App />
              </ErrorBoundary>
            </Sentry.ErrorBoundary>
          </VenueProvider>
        </Auth0ProviderWithHistory>
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </Provider>
);
