import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import ScreenStreamPlaylist from "../components/screen-stream/ScreenStreamPlaylist";
import ScreenStreamSchedule from "../components/screen-stream/ScreenStreamSchedule";
import ScreenStreamSlideshow from "../components/screen-stream/ScreenStreamSlideshow";
import {
  fetchScreenById,
  generateQRCode,
  validateScreenToken,
  updateSessionId,
} from "services/ScreenService";
import logo from "assets/images/logos/sds-logo.png";
import "../components/screen-stream/ScreenStream.css";
import { Box, Modal, Button } from "@mui/material";
import { useSelector } from "react-redux";
import useFetchTenant from "shared/hooks/useFetchTenant";
import isEqual from "lodash/isEqual";
import CenteredLoading from "shared/components/UIElements/Loading/CenteredLoading";

const ScreenStream = ({ screenId: propScreenId, preview = false }) => {
  const { screenId: paramsScreenId } = useParams();
  const [screenId, setScreenId] = useState(null);
  const [assignment, setAssignment] = useState(null);
  const [assignedItem, setAssignedItem] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [qrCode, setQrCode] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [isSessionInvalid, setIsSessionInvalid] = useState(false);
  const [showSessionPrompt, setShowSessionPrompt] = useState(false);
  const [showInactiveModal, setShowInactiveModal] = useState(false);
  const pollingInterval = 60000; // Poll every 60 seconds
  const subdomain = useSelector((state) => state.subdomain.value);
  const { tenant } = useFetchTenant(subdomain);

  useEffect(() => {
    const screenId = propScreenId || paramsScreenId;
    setScreenId(screenId);
  }, [propScreenId, paramsScreenId]);

  const fetchQRCode = async () => {
    try {
      if (screenId) {
        const response = await generateQRCode(screenId, subdomain);
        setQrCode(response.qrCode);
      }
    } catch (error) {
      console.error("Error fetching QR code:", error);
    }
  };

  const fetchSessionId = () => {
    let sessionId = localStorage.getItem("sessionId");
    if (!sessionId) {
      sessionId = Array.from(crypto.getRandomValues(new Uint8Array(16)))
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("");
      localStorage.setItem("sessionId", sessionId);
    }
    return sessionId;
  };

  const fetchToken = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/screen/${screenId}/token`,
        {
          headers: {
            "X-Subdomain": subdomain,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setToken(data.token);
      } else {
        throw new Error("Failed to fetch token");
      }
    } catch (error) {
      console.error("Error fetching token:", error);
      fetchQRCode(); // Show QR code for re-authentication
    }
  };

  const validateToken = async (screenId, token) => {
    try {
      const sessionId = fetchSessionId();
      const response = await validateScreenToken(
        screenId,
        token,
        subdomain,
        sessionId
      );
      if (response.forceUpdate) {
        setShowSessionPrompt(true);
      }
      return response.isValid;
    } catch (error) {
      if (
        error.message ===
        "Session invalid. This screen is being used in another session."
      ) {
        setIsSessionInvalid(true);
      } else {
        console.error("Error validating token:", error);
      }
      return false;
    }
  };

  const handleConfirmNewSession = async () => {
    try {
      const sessionId = fetchSessionId();
      await updateSessionId(screenId, sessionId, token, subdomain);
      setShowSessionPrompt(false);
      setIsSessionInvalid(false);
    } catch (error) {
      console.error("Error updating session:", error);
    }
  };

  const handleCancelSession = () => {
    window.location.reload();
  };

  useEffect(() => {
    // Check tenant status before loading screen
    if (tenant?.status !== "active") {
      setShowInactiveModal(true);
      return;
    }

    const loadScreen = async () => {
      if (!screenId || isSessionInvalid) return;

      try {
        if (!token) {
          await fetchToken();
        }
        if (token) {
          const isValidToken = await validateToken(screenId, token);
          if (!isValidToken) {
            throw new Error("INVALID_TOKEN");
          }
          setIsAuthenticated(true);

          const data = await fetchScreenById(screenId, token, subdomain);
          const newAssignment = data.screen.assignedItem;

          if (!isEqual(newAssignment, assignment)) {
            setIsLoading(true);
            setAssignment(newAssignment);
            setAssignedItem(null); // Set to null to re-trigger assigned item load if needed
            setIsLoading(false);
          }
        }
      } catch (error) {
        if (
          error.message === "INVALID_TOKEN" ||
          error.message.includes("Session invalid")
        ) {
          setIsSessionInvalid(true);
          fetchQRCode();
        } else {
          console.log("Error fetching screen:", error);
          setAssignment(null);
          setAssignedItem(null);
          setIsLoading(false);
        }
      }
    };

    loadScreen();
    const intervalId = setInterval(loadScreen, pollingInterval);
    return () => clearInterval(intervalId);
  }, [
    screenId,
    assignment,
    pollingInterval,
    token,
    isSessionInvalid,
    tenant?.status,
  ]);

  useEffect(() => {
    const fetchAssignedItem = async () => {
      if (!assignment || !assignment.itemId || !assignment.itemType) {
        setAssignedItem(null);
        return;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/${assignment.itemType.toLowerCase()}/${assignment.itemId}`,
          { headers: { "X-Subdomain": subdomain } }
        );

        if (response.ok) {
          const data = await response.json();
          let newAssignedItem = data[assignment.itemType.toLowerCase()];

          if (newAssignedItem) {
            const sanitizedAssignedItem = assignedItem
              ? JSON.parse(JSON.stringify(assignedItem))
              : null;
            const sanitizedNewAssignedItem = JSON.parse(
              JSON.stringify(newAssignedItem)
            );

            sanitizedAssignedItem?.mediaList?.forEach(
              (item) => delete item.signedUrl
            );
            sanitizedNewAssignedItem?.mediaList?.forEach(
              (item) => delete item.signedUrl
            );

            if (!isEqual(sanitizedNewAssignedItem, sanitizedAssignedItem)) {
              setIsLoading(true);
              setAssignedItem(newAssignedItem);
              setIsLoading(false);
            }
          }
        } else {
          throw new Error(
            `Failed to fetch ${assignment.itemType.toLowerCase()}.`
          );
        }
      } catch (error) {
        setAssignedItem(null);
        setIsLoading(false);
      }
    };

    fetchAssignedItem();
    const intervalId = setInterval(fetchAssignedItem, pollingInterval);
    return () => clearInterval(intervalId);
  }, [assignment, assignedItem, pollingInterval]);

  const memoizedAssignedItem = useMemo(() => {
    return assignedItem;
  }, [assignedItem]);

  return (
    <>
      {showSessionPrompt && (
        <Modal
          open={showSessionPrompt}
          onClose={() => setShowSessionPrompt(false)}
          aria-labelledby="session-modal-title"
          aria-describedby="session-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 300,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <h2 id="session-modal-title">New Session Detected</h2>
            <p id="session-modal-description">
              This screen is currently being used in another session. Would you
              like to override it with this session?
            </p>
            <Button onClick={handleConfirmNewSession}>Confirm</Button>
            <Button onClick={handleCancelSession}>Cancel</Button>
          </Box>
        </Modal>
      )}

      {tenant?.status !== "active" ? (
        <Box
          sx={{
            backgroundColor: "black",
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
          }}
        ></Box>
      ) : !isAuthenticated ? (
        qrCode ? (
          <Box
            className="auth-container"
            sx={{
              backgroundColor: "black",
              color: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              textAlign: "center",
            }}
          >
            <h2>Scan the QR code to authenticate this screen</h2>
            <img src={qrCode} alt="QR Code" style={{ marginTop: "20px" }} />
          </Box>
        ) : (
          <CenteredLoading />
        )
      ) : isAuthenticated && !assignedItem ? (
        <Box className="fade-container" sx={{ backgroundColor: "black" }}>
          <img
            src={logo}
            alt="No Assignment"
            className="dummy-image"
            style={{
              maxWidth: "30%",
              maxHeight: "30%",
              objectFit: "contain",
              position: "absolute",
            }}
          />
        </Box>
      ) : assignment.itemType === "Schedule" ? (
        <ScreenStreamSchedule
          screenId={screenId}
          schedule={memoizedAssignedItem}
          preview={preview}
        />
      ) : assignment.itemType === "Slideshow" ? (
        <ScreenStreamSlideshow
          screenId={screenId}
          slideshow={memoizedAssignedItem}
          preview={preview}
        />
      ) : (
        <ScreenStreamPlaylist
          screenId={screenId}
          playlist={memoizedAssignedItem}
          preview={preview}
        />
      )}
    </>
  );
};

export default ScreenStream;
