import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import FolderItem from "./FolderItem";

const FolderList = ({
  folders,
  spacing,
  onFolderClick,
  onRename,
  onDelete,
}) => {
  return (
    <Grid container spacing={Math.max(1, Math.floor(spacing / 10))}>
      {folders.map((folder) => (
        <Grid item key={folder.id}>
          <FolderItem
            id={folder.id}
            name={folder.name}
            description={folder.description}
            createdAt={folder.createdAt}
            mediaItems={folder.mediaItems}
            venue={folder.venue}
            spacing={spacing}
            onClick={() => onFolderClick(folder)}
            onRename={onRename}
            onDelete={onDelete}
            folders={folders}
          />
        </Grid>
      ))}
    </Grid>
  );
};

FolderList.propTypes = {
  folders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  spacing: PropTypes.number.isRequired,
  onFolderClick: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default FolderList;
