import React from "react";
import { MenuItem, Select } from "@mui/material";
import { useSelector } from "react-redux";

const RoleDropdown = ({ user, currentRole, onChange }) => {
  const { user: currentUser } = useSelector((state) => state.user);

  const getAvailableRoles = () => {
    if (currentUser.role === "superadmin") {
      return ["owner", "manager", "user"];
    }
    if (currentUser.role === "owner") {
      return ["owner", "manager", "user"];
    }
    if (currentUser.role === "manager") {
      return currentRole === "unauthorized" || currentRole === "user"
        ? ["user"]
        : [currentRole];
    }
    return ["user"];
  };

  // Handle the case where the role is unauthorized and should be upgraded
  const adjustedRole = currentRole === "unauthorized" ? "" : currentRole;

  const isDisabled =
    currentUser.role === "superadmin" && currentRole === "superadmin";

  return (
    <Select
      value={adjustedRole}
      onChange={(e) => onChange(user, e.target.value)}
      fullWidth
      disabled={
        isDisabled ||
        (currentUser.role === "manager" && user._id === currentUser._id)
      }
      sx={{
        height: 40,
        width: 200,
        textAlign: "center",
        fontSize: "0.875rem",
        "& .MuiSelect-select": {
          paddingTop: "10px",
          paddingBottom: "10px",
        },
      }}
    >
      {getAvailableRoles().map((role) => (
        <MenuItem key={role} value={role}>
          {role}
        </MenuItem>
      ))}
    </Select>
  );
};

export default RoleDropdown;
