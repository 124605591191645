import React, { useState, useEffect } from "react";
import DashboardLayout from "shared/components/UIElements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/components/Navigation/DashboardNavbar";
import MDBox from "shared/components/MDComponents/MDBox";
import WalkthroughStep from "../components/WalkthroughStep";
import { CircularProgress } from "@mui/material";
import MDTypography from "shared/components/MDComponents/MDTypography";

const Walkthroughs = () => {
  const [openStepIndex, setOpenStepIndex] = useState(null);
  const [walkthroughData, setWalkthroughData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadWalkthroughs = async () => {
      try {
        // Simulate fetching walkthrough data (replace this with a real API call if necessary)
        const fetchedData = [
          {
            title: "Setting up a new Venue",
            description:
              "A step-by-step guide to setting up a venue in the app.",
            videoUrl:
              "https://www.loom.com/embed/baa70f2c666a41bdbe1a0a5e794f0e8a?sid=6ebe2258-47ea-491c-816b-216e6900d5dd",
          },
          {
            title: "Uploading Media",
            description: "A step-by-step guide to uploading media.",
            videoUrl:
              "https://www.loom.com/embed/ae2317ed03894beabc737a385afe66a6?sid=9e5de4e2-18d8-4474-b6c2-eeed48be5be6",
          },
          {
            title: "Creating a Schedule",
            description: "A step-by-step guide to creating a new schedule.",
            videoUrl:
              "https://www.loom.com/embed/c5742956960543d39108778766794358?sid=9133e919-0b5b-47a4-80ee-2343e6cf80b9",
          },
          {
            title: "Creating a Slideshow",
            description: "A step-by-step guide to creating a new slideshow.",
            videoUrl: "https://example.com/dummy-video2.mp4",
          },
          {
            title: "Creating a Playlist",
            description: "A step-by-step guide to creating a new playlist.",
            videoUrl: "https://example.com/dummy-video2.mp4",
          },
          {
            title: "Creating and Assigning Content to a Screen",
            description:
              "A step-by-step guide to creating a screen and assigning content to it.",
            videoUrl: "https://example.com/dummy-video2.mp4",
          },
        ];
        setWalkthroughData(fetchedData);
      } catch (error) {
        setError("Failed to load walkthroughs");
        console.error("Error loading walkthroughs:", error);
      } finally {
        setLoading(false);
      }
    };

    loadWalkthroughs();
  }, []);

  const handleToggle = (index) => {
    setOpenStepIndex(openStepIndex === index ? null : index);
  };

  if (loading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(80vh - 64px)",
          }}
        >
          <CircularProgress />
        </MDBox>
      </DashboardLayout>
    );
  }

  if (error) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={3} py={2} px={2}>
          <MDTypography variant="h5" color="error">
            {error}
          </MDTypography>
        </MDBox>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5}>
        {walkthroughData.map((walkthrough, index) => (
          <WalkthroughStep
            key={index}
            title={walkthrough.title}
            description={walkthrough.description}
            videoUrl={walkthrough.videoUrl}
            isOpen={openStepIndex === index}
            onClick={() => handleToggle(index)}
          />
        ))}
      </MDBox>
    </DashboardLayout>
  );
};

export default Walkthroughs;
