import React, { useEffect, useState } from "react";
import "./ScreenStream.css";
import { Box } from "@mui/material";
import logo from "assets/images/logos/sds-logo.png";

const ScreenStreamPlaylist = ({ screenId, playlist, preview }) => {
  const [mediaList, setMediaList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (playlist && playlist.mediaList) {
      setMediaList(playlist.mediaList);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [playlist]);

  const getVideoStyle = (fit) => {
    switch (fit) {
      case "fit":
        return {
          maxWidth: "100%",
          maxHeight: "100%",
          objectFit: "contain",
        };
      case "stretch":
        return {
          width: "100%",
          height: "100%",
          objectFit: "fill",
        };
      case "center":
        return {
          maxWidth: "100%",
          maxHeight: "100%",
          objectFit: "none",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        };
      case "none":
      default:
        return {
          height: "100vh",
          width: "100vw",
          objectFit: "cover",
        };
    }
  };

  if (mediaList.length === 0) {
    return (
      <Box className="fade-container" sx={{ backgroundColor: "black" }}>
        <img
          src={logo}
          alt="No Media"
          className="dummy-image"
          style={{
            maxWidth: "30%",
            maxHeight: "30%",
            objectFit: "contain",
            position: "absolute",
          }}
        />
      </Box>
    );
  }

  return (
    <Box className="fade-container" sx={{ backgroundColor: "black" }}>
      <video
        src={mediaList[currentIndex]?.signedUrl || logo}
        autoPlay
        muted
        controls
        onEnded={() => setCurrentIndex((currentIndex + 1) % mediaList.length)}
        className="stream-video"
        style={getVideoStyle(playlist.screenFit)}
      />
    </Box>
  );
};

export default ScreenStreamPlaylist;
