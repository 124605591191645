import React from "react";
import { Button } from "@mui/material";
import { SaveButton, Toolbar, useRedirect } from "react-admin";

const CustomToolbar = ({ resource }) => {
  const redirect = useRedirect();

  return (
    <Toolbar>
      <SaveButton label="Save" />
      <Button
        variant="contained"
        color="secondary"
        onClick={() => redirect("list", resource)}
        style={{ marginLeft: 16 }}
      >
        Cancel
      </Button>
    </Toolbar>
  );
};

export default CustomToolbar;
