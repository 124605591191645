import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";
import MDBox from "shared/components/MDComponents/MDBox";
import MDButton from "shared/components/MDComponents/MDButton";
import MDTypography from "shared/components/MDComponents/MDTypography";
import DashboardLayout from "shared/components/UIElements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/components/Navigation/DashboardNavbar";
import { useSelector, useDispatch } from "react-redux";
import SlideshowInfo from "../components/new-slideshow/SlideshowInfo";
import SlideshowMedia from "../components/new-slideshow/SlideshowMedia";
import { createSlideshow } from "services/SlideshowService";
import Validation from "shared/components/UIElements/Validation/Validation";
import MDAlert from "shared/components/MDComponents/MDAlert";
import { fetchMediaByVenue } from "services/MediaService";
import useFetchTenant from "shared/hooks/useFetchTenant";

function NewSlideshow() {
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const darkMode = theme.darkMode;
  const selectedVenue = useSelector((state) => state.venue.selectedVenue);
  const navigate = useNavigate();
  const subdomain = useSelector((state) => state.subdomain.value);
  const { tenant } = useFetchTenant(subdomain);

  const [slideshowInfo, setSlideshowInfo] = useState({
    title: "",
    description: "",
    startDate: "",
    endDate: "",
    runIndefinitely: false,
    screenFit: "none",
  });
  const [mediaList, setMediaList] = useState([]);
  const [showValidation, setShowValidation] = useState(false);
  const [mediaAlert, setMediaAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });
  const [limitAlert, setLimitAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });
  const supportedImageFormats = ["png", "jpeg", "jpg", "webp", "pdf"];

  const steps = useMemo(() => ["1. Slideshow Info", "2. Media Selection"], []);
  const isLastStep = activeStep === steps.length - 1;

  const isSubscriptionActive = tenant?.status === "active";
  const hasReachedContentLimit =
    tenant?.currentContentItems >= tenant?.maxContentItems;

  const showNotification = useCallback((message, type, setAlertFunction) => {
    setAlertFunction({ show: true, message, type });
  }, []);

  useEffect(() => {
    if (!isSubscriptionActive) {
      showNotification(
        "Your account is inactive. You can manage existing slideshows, but you cannot create new ones.",
        "error",
        setLimitAlert
      );
    } else if (hasReachedContentLimit) {
      showNotification(
        "You have reached the maximum number of content items allowed. Please delete existing content or contact support to upgrade.",
        "error",
        setLimitAlert
      );
    }
  }, [isSubscriptionActive, hasReachedContentLimit, showNotification]);

  useEffect(() => {
    const fetchVenueMedia = async () => {
      try {
        const data = await fetchMediaByVenue(selectedVenue.id, subdomain);

        const imageMedia = data.media.filter((item) => {
          const extension = item.url.split(".").pop().toLowerCase();
          return supportedImageFormats.includes(extension);
        });

        if (imageMedia.length === 0) {
          showNotification(
            "No image media is available for this venue. Please upload image media before creating a slideshow.",
            "error",
            setMediaAlert
          );
        } else {
          setMediaAlert({ show: false, message: "", type: "info" });
        }
      } catch (error) {
        showNotification(
          "There was an error fetching media for the venue.",
          "error",
          setMediaAlert
        );
        console.error("Error fetching media for venue:", error);
      }
    };

    fetchVenueMedia();
  }, [selectedVenue.id, subdomain]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    setShowValidation(false);
  };

  const handleBack = () => setActiveStep(activeStep - 1);

  const handleCreateSlideshow = async () => {
    if (isValid()) {
      const { endDate, ...slideshowForSubmission } = slideshowInfo;

      // Transform the mediaList to match the backend's expected structure
      const transformedMediaList = mediaList.map(
        ({ id, signedImageUrl, uniqueId, ...rest }) => ({ media: id, ...rest })
      );

      const slideshowData = {
        slideshow: {
          ...slideshowForSubmission,
          venue: selectedVenue.id,
          mediaList: transformedMediaList,
        },
      };

      if (endDate) {
        slideshowData.slideshow.endDate = endDate;
      }

      try {
        await createSlideshow(slideshowData, subdomain);
        navigate(`/slideshows`);
      } catch (error) {
        console.log("Error submitting slideshow:", error);
      }
    } else {
      setShowValidation(true);
    }
  };

  const isValid = () => {
    const { title, description, startDate, endDate, runIndefinitely } =
      slideshowInfo;
    return (
      title &&
      description &&
      startDate &&
      (endDate || runIndefinitely) &&
      mediaList.length > 0
    );
  };

  const updateSlideshowInfo = useCallback((info) => {
    setSlideshowInfo(info);
  }, []);

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <SlideshowInfo
            onUpdate={updateSlideshowInfo}
            slideshowInfo={slideshowInfo}
          />
        );
      case 1:
        return (
          <SlideshowMedia setMediaList={setMediaList} mediaList={mediaList} />
        );
      default:
        return null;
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Create New Slideshow
                </MDTypography>
              </MDBox>
              <MDTypography variant="h5" fontWeight="regular" color="secondary">
                A Slideshow is a sequence of still images, perfect for
                showcasing image-based content. Set a custom duration for each
                image to control how long it's displayed.
              </MDTypography>
            </MDBox>

            {mediaAlert.show && (
              <MDBox mb={3} sx={{ position: "relative" }}>
                <MDAlert color={mediaAlert.type} dismissible>
                  <MDTypography variant="body2" color="white">
                    No image media is available for this venue. Please{" "}
                    <MDTypography
                      variant="body1"
                      component={Link}
                      to="/newmedia"
                      sx={{
                        color: darkMode ? "#00BFFF" : "#00BFFF",
                        textDecoration: "none",
                        "&:hover": {
                          color: darkMode ? "#ff4081" : "#344767",
                        },
                      }}
                    >
                      upload image media
                    </MDTypography>{" "}
                    before creating a slideshow.
                  </MDTypography>
                </MDAlert>
              </MDBox>
            )}

            {limitAlert.show && (
              <MDBox mb={3} sx={{ position: "relative" }}>
                <MDAlert color={limitAlert.type} dismissible>
                  <MDTypography variant="body2" color="white">
                    {limitAlert.message}
                  </MDTypography>
                </MDAlert>
              </MDBox>
            )}

            <Card>
              <MDBox mt={-3} mb={3} mx={2}>
                <Stepper
                  activeStep={activeStep}
                  alternativeLabel
                  sx={{
                    background: `${
                      darkMode ? "#344767" : "#344767"
                    } !important`,
                  }}
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={2}>
                {getStepContent(activeStep)}
                {showValidation && isLastStep && (
                  <Validation
                    fields={[
                      { name: "Title", value: slideshowInfo.title },
                      { name: "Description", value: slideshowInfo.description },
                      { name: "Start Date", value: slideshowInfo.startDate },
                      {
                        name: "End Date or Run Indefinitely",
                        value:
                          slideshowInfo.endDate ||
                          slideshowInfo.runIndefinitely,
                      },
                      { name: "Media", value: mediaList.length > 0 },
                    ]}
                  />
                )}
                <MDBox mt={3} display="flex" justifyContent="space-between">
                  {activeStep === 0 ? (
                    <MDBox />
                  ) : (
                    <MDButton
                      variant="contained"
                      color="dark"
                      onClick={handleBack}
                    >
                      Back
                    </MDButton>
                  )}
                  <MDButton
                    variant="contained"
                    color="dark"
                    onClick={isLastStep ? handleCreateSlideshow : handleNext}
                    disabled={hasReachedContentLimit || !isSubscriptionActive}
                  >
                    {isLastStep ? "Create Slideshow" : "Next"}
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default NewSlideshow;
