import React from "react";

import VenueItem from "./VenueItem";
import { Card } from "@mui/material";
import "./VenuesList.css";

const VenuesList = (props) => {
  if (props.items.length === 0) {
    return (
      <div className="center">
        <Card>
          <h2>No venues found.</h2>
        </Card>
      </div>
    );
  }

  return (
    <ul className="venues-list">
      {props.items.map((venue) => (
        <VenueItem
          key={venue.id}
          id={venue.id}
          image={venue.backgroundImage}
          signedUrl={venue.signedBackgroundUrl}
          title={venue.title}
          address={venue.address}
          screens={venue.screens}
          description={venue.description}
          schedules={venue.schedules}
          onDelete={props.onDelete}
          onSelect={props.onSelect}
        />
      ))}
    </ul>
  );
};

export default VenuesList;
