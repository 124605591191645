import React, { useState } from "react";
import {
  Grid,
  Button,
  TextField as MuiTextField,
  IconButton,
} from "@mui/material";
import {
  Create,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  useCreate,
} from "react-admin";
import CustomToolbar from "../../utils/CustomToolbar";
import { generatePassword, validatePassword } from "../../utils/validation";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const AdminCreate = (props) => {
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const notify = useNotify();
  const [create] = useCreate();
  const redirect = useRedirect();

  const handleGeneratePassword = () => {
    const newPassword = generatePassword();
    setPassword(newPassword);
    notify(`Password generated: ${newPassword}`, { type: "info" });
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    if (!validatePassword(newPassword)) {
      setPasswordError(
        "Password must be between 8-30 characters and include uppercase, lowercase, number, and special character."
      );
    } else {
      setPasswordError("");
    }
  };

  const handleBackClick = () => {
    redirect("/admin/superadmins");
  };

  const handleSubmit = async (data) => {
    try {
      const formData = {
        ...data,
        password: password,
      };

      await create("superadmins", { data: formData });
      notify("Admin created successfully", { type: "info" });
      redirect("/admin/superadmins");
    } catch (error) {
      notify(`Error: ${error.message}`, { type: "error" });
    }
  };

  return (
    <Create {...props} resource="superadmins">
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton onClick={handleBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <h3 style={{ marginLeft: "10px" }}>Create New Admin</h3>
      </div>
      <SimpleForm
        toolbar={<CustomToolbar resource="superadmins" />}
        onSubmit={handleSubmit}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextInput source="name" fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextInput source="email" fullWidth />
          </Grid>

          {/* Password Field */}
          <Grid item xs={6}>
            <MuiTextField
              label="Password"
              value={password}
              onChange={handlePasswordChange}
              fullWidth
              error={!!passwordError}
              helperText={passwordError}
            />
          </Grid>

          {/* Generate Password Button */}
          <Grid item xs={12}>
            <Button variant="contained" onClick={handleGeneratePassword}>
              Generate Password
            </Button>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};
