import React from "react";
import { performLogout } from "auth/components/logout";
import MDButton from "shared/components/MDComponents/MDButton";
import { Box } from "@mui/material";
import MDTypography from "shared/components/MDComponents/MDTypography";

const TenantMismatch = () => {
  const handleLogout = () => {
    performLogout();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      textAlign="center"
    >
      <MDTypography variant="h3">Unauthorized Access</MDTypography>
      <MDTypography variant="body1" mt={2} mb={3}>
        You are not authorized to access this site.
        <br />
        If you believe this is a mistake, please log out and try again or
        contact support.
      </MDTypography>
      <MDButton variant="contained" color="dark" onClick={handleLogout}>
        Go to Login
      </MDButton>
    </Box>
  );
};

export default TenantMismatch;
