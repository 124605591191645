import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Tooltip,
  Menu,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useTheme,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import MDTypography from "shared/components/MDComponents/MDTypography";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import MDButton from "shared/components/MDComponents/MDButton";
import { deleteFolder, updateFolder } from "services/FolderService";
import { useSelector } from "react-redux";

const FolderItem = ({
  id,
  name,
  spacing,
  onClick,
  description,
  venue,
  mediaItems,
  onRename,
  onDelete,
  folders,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(name);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState("");
  const [deleteError, setDeleteError] = useState("");
  const theme = useTheme();
  const itemSize = spacing * 5;
  const fontSize = Math.max(12, spacing / 2);
  const subdomain = useSelector((state) => state.subdomain.value);
  const darkMode = theme.darkMode;

  const handleContextMenu = (event) => {
    event.preventDefault();
    if (name.toLowerCase() !== "misc") {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRename = () => {
    setIsEditing(true);
    handleClose();
  };

  const handleDelete = async () => {
    if (!selectedFolderId) {
      setDeleteError("Please select a folder to move media to.");
      return;
    }

    try {
      await deleteFolder(id, selectedFolderId, subdomain);
      setIsDeleted(true);
      onDelete(id, selectedFolderId);
    } catch (error) {
      console.error("Error deleting folder: ", error);
    }
    handleClose();
  };

  const handleRenameSubmit = async () => {
    const mediaItemIds = mediaItems.map((item) => item._id);
    const updatedFolder = {
      name: newName,
      description,
      venue,
      ...(mediaItemIds.length > 0 && { mediaItems: mediaItemIds }),
    };
    try {
      await updateFolder(id, updatedFolder, subdomain);
      onRename(id, newName);
    } catch (error) {
      console.error("Error renaming folder: ", error);
    }
    setIsEditing(false);
  };

  const openConfirmationDialog = () => {
    setOpenConfirm(true);
    handleClose();
  };

  const closeConfirmationDialog = () => {
    setOpenConfirm(false);
    setDeleteError("");
  };

  const truncateName = (name, maxLength = 15) => {
    if (name.length > maxLength) {
      return name.substring(0, maxLength) + "...";
    }
    return name;
  };

  if (isDeleted) {
    return null;
  }

  const menuItems = [
    <MenuItem key="rename" onClick={handleRename}>
      Rename
    </MenuItem>,
    <MenuItem key="delete" onClick={openConfirmationDialog}>
      Delete
    </MenuItem>,
  ];

  return (
    <React.Fragment>
      <Box
        onContextMenu={handleContextMenu}
        sx={{
          width: `${itemSize}px`,
          textAlign: "center",
          cursor: "pointer",
        }}
      >
        <Tooltip>
          <Box
            onClick={onClick}
            sx={{
              width: `${itemSize}px`,
              height: `${itemSize}px`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 4px 10px rgba(0,0,0,0.3)",
              },
            }}
          >
            <FolderOpenIcon
              sx={{
                width: "100%",
                height: "100%",
                color: darkMode ? "white" : "black",
              }}
            />
          </Box>
        </Tooltip>
        {isEditing ? (
          <TextField
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleRenameSubmit();
              }
            }}
            autoFocus
            sx={{ fontSize: `${fontSize}px`, mt: 1 }}
            inputProps={{ maxLength: 30 }}
          />
        ) : (
          <MDTypography variant="h6" sx={{ fontSize: `${fontSize}px`, mt: 1 }}>
            {truncateName(name)}
          </MDTypography>
        )}
      </Box>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {name.toLowerCase() !== "misc" && menuItems}
      </Menu>

      <Dialog
        open={openConfirm}
        onClose={closeConfirmationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this folder? All media inside this
            folder will be moved to the selected folder. This action cannot be
            undone.
          </DialogContentText>
          {deleteError && (
            <MDTypography color="error" sx={{ mt: 1 }}>
              {deleteError}
            </MDTypography>
          )}
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Move media to folder</InputLabel>
            <Select
              value={selectedFolderId}
              onChange={(e) => setSelectedFolderId(e.target.value)}
              fullWidth
              label="Move media to folder"
              sx={{ height: "56px" }}
            >
              {folders
                .filter((folder) => folder.id !== id)
                .map((folder) => (
                  <MenuItem key={folder.id} value={folder.id}>
                    {folder.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <MDButton
            onClick={handleDelete}
            color="error"
            variant="contained"
            disabled={!selectedFolderId}
          >
            Delete
          </MDButton>
          <MDButton
            onClick={closeConfirmationDialog}
            color="dark"
            variant="contained"
          >
            Cancel
          </MDButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

FolderItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  venue: PropTypes.string,
  mediaItems: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      folder: PropTypes.string.isRequired,
      venue: PropTypes.string.isRequired,
    })
  ).isRequired,
  spacing: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  folders: PropTypes.array.isRequired,
};

export default FolderItem;
