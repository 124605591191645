import React from "react";
import { useSelector } from "react-redux";
import MDTypography from "../../MDComponents/MDTypography";

const truncateName = (name, maxLength = 8) => {
  if (!name) return "";
  return name.length > maxLength ? name.substring(0, maxLength) + "..." : name;
};

const LoggedInUserTitle = () => {
  const loggedInUser = useSelector((state) => state.user.user);
  const userName = loggedInUser ? truncateName(loggedInUser.name) : null;

  return (
    <MDTypography
      color="white"
      display="block"
      variant="caption"
      fontWeight="bold"
      textTransform="uppercase"
      mt={2}
      mb={1}
    >
      {userName || "Logged-in User"}
    </MDTypography>
  );
};

export default LoggedInUserTitle;
