const API_BASE_URL = process.env.REACT_APP_API_URL;

export const performGlobalSearch = async (query, subdomain, venueId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/search`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Subdomain": subdomain,
      },
      body: JSON.stringify({ query, venueId }),
    });
    if (!response.ok) {
      throw new Error("Failed to perform search.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error performing search:", error);
    throw error;
  }
};
