import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTenant } from "features/tenant/store/TenantSlice";

const useFetchTenant = (subdomain) => {
  const dispatch = useDispatch();
  const [tenant, setTenant] = useState(null);
  const tenantState = useSelector((state) => state.tenant);

  useEffect(() => {
    const fetchTenantData = async () => {
      try {
        const resultAction = await dispatch(fetchTenant({ subdomain }));
        if (fetchTenant.fulfilled.match(resultAction)) {
          setTenant(resultAction.payload);
        } else {
          console.error("Failed to fetch tenant data", resultAction.error);
        }
      } catch (error) {
        console.error("Error fetching tenant:", error);
      }
    };

    fetchTenantData();
  }, [dispatch, subdomain]);

  return { tenant, ...tenantState };
};

export default useFetchTenant;
