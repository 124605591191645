const API_BASE_URL = process.env.REACT_APP_API_URL;

/**
 * Calls the backend to create a new Stripe checkout session
 * @param {string} customerId - The Stripe customer ID
 * @param {string} subscriptionId - The subscription ID in your database
 * @param {string} tenantId - The tenant ID
 * @param {string} billingCycle - Billing cycle for the subscription (e.g., "monthly", "annual")
 * @param {number} screens - Number of screens
 * @param {number} pricePerScreen - Price per screen
 * @returns {Object} - Returns the session object with a URL to redirect the user
 */
export const retryCheckoutSession = async (
  customerId,
  subscriptionId,
  tenantId,
  billingCycle = "monthly",
  screens = 5,
  pricePerScreen = 15
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/stripe/retry-checkout-session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerId,
          subscriptionId,
          tenantId,
          billingCycle,
          screens,
          pricePerScreen,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to create new checkout session.");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error creating new checkout session:", error);
    throw error;
  }
};
