import React from "react";
import { Box, Typography, CardMedia } from "@mui/material";

const Banner = ({
  position,
  text,
  imageUrl,
  justifyContent = "center",
  enlarge = false,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: justifyContent,
        position: "absolute",
        [position]: 0,
        width: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.8)", // Slightly darker background for better visibility
        color: "white",
        padding: "10px",
        zIndex: 1, // Ensure it stays on top
      }}
    >
      <Typography
        variant="h2"
        sx={{
          color: "white !important",
          whiteSpace: "nowrap",
          marginRight: 2,
          textShadow: "0px 0px 8px rgba(0, 0, 0, 0.7)", // Adding text shadow for better contrast
        }}
      >
        {text}
      </Typography>
      {imageUrl && (
        <CardMedia
          component="img"
          src={imageUrl}
          sx={{
            width: "auto",
            maxHeight: enlarge ? "33vh" : 100, // Enlarge to 1/3rd of the screen height if `enlarge` prop is true
            marginLeft: 2,
          }}
        />
      )}
    </Box>
  );
};

export default Banner;
