import React from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
} from "@mui/material";

const ScreenFilterControls = ({
  searchQuery,
  onSearchChange,
  sortOption,
  onSortChange,
  spacing,
  onSpacingChange,
}) => {
  return (
    <Grid container alignItems="center" spacing={2}>
      {/* Search Bar */}
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="Search Screens"
          variant="outlined"
          value={searchQuery}
          onChange={onSearchChange}
        />
      </Grid>

      {/* Sort By Dropdown */}
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth>
          <InputLabel>Sort By</InputLabel>
          <Select
            value={sortOption}
            label="Sort By"
            onChange={onSortChange}
            sx={{
              height: "40px", // Ensure Select fills the FormControl
            }}
          >
            <MenuItem value="title">Title</MenuItem>
            {/* Add other sort options here */}
          </Select>
        </FormControl>
      </Grid>

      {/* Slider */}
      <Grid item xs={12} sm={4}>
        <Slider
          value={spacing}
          onChange={onSpacingChange}
          aria-labelledby="spacing-slider"
          min={30}
          max={50}
          sx={{
            "& .MuiSlider-rail": {
              opacity: 1,
              bgcolor: "#c0c0c0", // Example: a light gray color
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ScreenFilterControls;
