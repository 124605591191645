import { configureStore } from "@reduxjs/toolkit";
import userReducer from "features/user/store/UserSlice";
import venueReducer from "features/venue/store/VenueSlice";
import subdomainReducer from "features/tenant/store/SubdomainSlice";
import adminReducer from "./admin/store/adminSlice";
import tenantReducer from "features/tenant/store/TenantSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    venue: venueReducer,
    subdomain: subdomainReducer,
    admin: adminReducer,
    tenant: tenantReducer,
  },
});

export default store;
