import React, { useEffect, useState, useMemo } from "react";
import Modal from "shared/components/UIElements/Modal/Modal";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Alert,
} from "@mui/material";
import MDButton from "shared/components/MDComponents/MDButton";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const TimeModal = ({
  open,
  onSubmit,
  onCancel,
  mediaName,
  isTimeOverlap,
  startTime: initialStartTime,
  endTime: initialEndTime,
  currentIndex,
}) => {
  const parseTime = (timeString) => {
    const [time, period] = timeString.split(" ");
    const [hour, minute] = time.split(":").map(Number);
    return { hour, minute, period };
  };

  const initialStart = useMemo(() => {
    return initialStartTime
      ? parseTime(initialStartTime)
      : { hour: 12, minute: 0, period: "AM" };
  }, [initialStartTime]);

  const initialEnd = useMemo(() => {
    return initialEndTime
      ? parseTime(initialEndTime)
      : { hour: 12, minute: 0, period: "AM" };
  }, [initialEndTime]);

  const [startHour, setStartHour] = useState(initialStart.hour);
  const [startMinute, setStartMinute] = useState(initialStart.minute);
  const [startPeriod, setStartPeriod] = useState(initialStart.period);
  const [endHour, setEndHour] = useState(initialEnd.hour);
  const [endMinute, setEndMinute] = useState(initialEnd.minute);
  const [endPeriod, setEndPeriod] = useState(initialEnd.period);
  const [overlapMessage, setOverlapMessage] = useState("");
  const [validationMessage, setValidationMessage] = useState("");

  useEffect(() => {
    if (open) {
      setStartHour(initialStart.hour);
      setStartMinute(initialStart.minute);
      setStartPeriod(initialStart.period);
      setEndHour(initialEnd.hour);
      setEndMinute(initialEnd.minute);
      setEndPeriod(initialEnd.period);
      setOverlapMessage("");
      setValidationMessage("");
    }
  }, [open, initialStart, initialEnd]);

  const handleCancel = () => {
    if (typeof onCancel === "function") {
      onCancel();
    }
  };

  const handleTimeChange = (type, value) => {
    if (type !== "startPeriod" && type !== "endPeriod") {
      if (!/^\d*$/.test(value)) {
        setValidationMessage("Please enter valid time values.");
        return;
      }
    }

    const intValue = value === "" ? "" : parseInt(value, 10);

    const adjustPeriod = (period) => (period === "AM" ? "PM" : "AM");

    if (type === "startHour") {
      if (startHour === 11 && intValue === 12) {
        setStartPeriod(adjustPeriod(startPeriod));
      } else if (startHour === 12 && intValue === 11) {
        setStartPeriod(adjustPeriod(startPeriod));
      }
      setStartHour(intValue);
    } else if (type === "startMinute") {
      setStartMinute(intValue);
    } else if (type === "startPeriod") {
      setStartPeriod(value);
    } else if (type === "endHour") {
      if (endHour === 11 && intValue === 12) {
        setEndPeriod(adjustPeriod(endPeriod));
      } else if (endHour === 12 && intValue === 11) {
        setEndPeriod(adjustPeriod(endPeriod));
      }
      setEndHour(intValue);
    } else if (type === "endMinute") {
      setEndMinute(intValue);
    } else if (type === "endPeriod") {
      setEndPeriod(value);
    }

    setValidationMessage("");
  };

  const formatTime = (hour, minute, period) => {
    const formattedMinute = minute < 10 ? `0${minute}` : minute;
    return `${hour}:${formattedMinute} ${period}`;
  };

  const validateTimes = () => {
    const startTotalMinutes =
      (startHour % 12) * 60 + startMinute + (startPeriod === "PM" ? 720 : 0);
    const endTotalMinutes =
      (endHour % 12) * 60 + endMinute + (endPeriod === "PM" ? 720 : 0);

    if (startTotalMinutes === endTotalMinutes) {
      setValidationMessage(
        "Start time and end time cannot be the same. Please choose a different end time."
      );
      return false;
    }

    if (endTotalMinutes < startTotalMinutes) {
      setValidationMessage(
        "End time must be after start time. Please choose a valid end time."
      );
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (
      startHour === "" ||
      isNaN(startHour) ||
      startHour < 1 ||
      startHour > 12 ||
      startMinute === "" ||
      isNaN(startMinute) ||
      startMinute < 0 ||
      startMinute > 59 ||
      endHour === "" ||
      isNaN(endHour) ||
      endHour < 1 ||
      endHour > 12 ||
      endMinute === "" ||
      isNaN(endMinute) ||
      endMinute < 0 ||
      endMinute > 59
    ) {
      setValidationMessage("Please enter valid time values.");
      return;
    }

    if (!validateTimes()) {
      return;
    }

    const startTime = formatTime(startHour, startMinute, startPeriod);
    const endTime = formatTime(endHour, endMinute, endPeriod);
    const overlaps = isTimeOverlap(startTime, endTime, currentIndex);

    if (overlaps.length > 0) {
      const overlapMessages = overlaps
        .map(
          (overlap) =>
            `'${overlap.name}' at ${overlap.startTime} - ${overlap.endTime}`
        )
        .join(" and ");
      setOverlapMessage(
        `The time overlaps with ${overlapMessages}. Please choose another time.`
      );
    } else {
      onSubmit(startTime, endTime);
    }
  };

  const truncateName = (name, maxLength = 15) => {
    if (name && name.length > maxLength) {
      return name.substring(0, maxLength) + "...";
    }
    return name || "";
  };

  const renderTimeSelectors = (hour, minute, period, type) => (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Box display="flex" flexDirection="column" alignItems="center" mr={1}>
        <IconButton
          onClick={() =>
            handleTimeChange(
              `${type}Hour`,
              hour === "" ? 1 : hour < 12 ? hour + 1 : 1
            )
          }
        >
          <ArrowDropUpIcon />
        </IconButton>
        <TextField
          value={hour}
          onChange={(e) => handleTimeChange(`${type}Hour`, e.target.value)}
          inputProps={{
            min: 1,
            max: 12,
            style: { textAlign: "center" },
            maxLength: 2,
            inputMode: "numeric",
          }}
          sx={{ width: 40 }}
        />
        <IconButton
          onClick={() =>
            handleTimeChange(
              `${type}Hour`,
              hour === "" ? 12 : hour > 1 ? hour - 1 : 12
            )
          }
        >
          <ArrowDropDownIcon />
        </IconButton>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        ml={1}
        mr={2}
      >
        <IconButton
          onClick={() =>
            handleTimeChange(
              `${type}Minute`,
              minute === "" ? 0 : minute < 45 ? minute + 15 : 0
            )
          }
        >
          <ArrowDropUpIcon />
        </IconButton>
        <TextField
          value={minute}
          onChange={(e) => handleTimeChange(`${type}Minute`, e.target.value)}
          inputProps={{
            min: 0,
            max: 59,
            step: 15,
            style: { textAlign: "center" },
            maxLength: 2,
            inputMode: "numeric",
          }}
          sx={{ width: 40 }}
        />
        <IconButton
          onClick={() =>
            handleTimeChange(
              `${type}Minute`,
              minute === "" ? 0 : minute > 0 ? minute - 15 : 45
            )
          }
        >
          <ArrowDropDownIcon />
        </IconButton>
      </Box>
      <Select
        value={period}
        onChange={(e) => handleTimeChange(`${type}Period`, e.target.value)}
        sx={{ width: 60 }}
      >
        <MenuItem value="AM">AM</MenuItem>
        <MenuItem value="PM">PM</MenuItem>
      </Select>
    </Box>
  );

  if (!open) return null;

  return (
    <Modal
      show={open}
      onClose={handleCancel}
      dialogStyle={{ maxWidth: "400px" }}
    >
      <Box p={1} display="flex" flexDirection="column" alignItems="center">
        <h2>{truncateName(mediaName)}</h2>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box mt={2} mb={2} width="100%">
            {renderTimeSelectors(startHour, startMinute, startPeriod, "start")}
          </Box>
          <Box mb={2} width="100%">
            {renderTimeSelectors(endHour, endMinute, endPeriod, "end")}
          </Box>
        </LocalizationProvider>
        {overlapMessage && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {overlapMessage}
          </Alert>
        )}
        {validationMessage && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {validationMessage}
          </Alert>
        )}
        <Box display="flex" justifyContent="space-between" mt={4} width="100%">
          <MDButton variant="contained" color="dark" onClick={handleSubmit}>
            Submit
          </MDButton>
          <MDButton variant="contained" color="error" onClick={handleCancel}>
            Cancel
          </MDButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default TimeModal;
