import React from "react";
import { Card, CardContent, TextField } from "@mui/material";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";

const PlaylistDescription = ({ description, editing, onDescriptionChange }) => (
  <Card>
    <CardContent sx={{ height: 250, overflowY: "auto" }}>
      <MDBox p={2}>
        {editing ? (
          <TextField
            value={description}
            onChange={(e) => onDescriptionChange(e.target.value)}
            multiline
            rows={6}
            fullWidth
            inputProps={{ maxLength: 200 }}
          />
        ) : (
          <MDTypography variant="subtitle1" align="center">
            {description}
          </MDTypography>
        )}
      </MDBox>
    </CardContent>
  </Card>
);

export default PlaylistDescription;
