import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  FunctionField,
  DateField,
  NumberField,
  useRedirect,
} from "react-admin";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const SubscriptionShow = (props) => {
  const redirect = useRedirect();

  const handleBackClick = () => {
    redirect("/admin/subscription");
  };

  return (
    <Show {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton onClick={handleBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <h3 style={{ marginLeft: "10px" }}>Subscription Details</h3>
      </div>
      <SimpleShowLayout>
        <FunctionField
          label="Customer"
          render={(record) => record.tenant?.organizationName || "N/A"}
        />
        <TextField source="subscriptionPlan" label="Plan" />
        <NumberField source="screens" label="Number of Screens" />
        <FunctionField
          label="Price per Screen"
          render={(record) => `$${record.pricePerScreen.toFixed(2)}`}
        />
        <TextField source="billingCycle" label="Billing Cycle" />
        <TextField source="status" label="Status" />
        <DateField source="startDate" label="Start Date" />
        <DateField source="endDate" label="End Date" />
        <DateField source="pastDueDate" label="Past Due Date" />
      </SimpleShowLayout>
    </Show>
  );
};
