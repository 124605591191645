import React from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
} from "@mui/material";

const MediaFilterControls = ({
  searchQuery,
  setSearchQuery,
  sortOption,
  setSortOption,
  selectedFolder,
  setSelectedFolder,
  folders,
  spacing,
  setSpacing,
  viewingFolders, // new prop to determine if we're viewing folders
}) => {
  const sortOptions = [
    { value: "name-az", label: "Name A-Z" },
    { value: "name-za", label: "Name Z-A" },
  ];

  return (
    <Grid container alignItems="center" spacing={2}>
      {/* Search Bar */}
      {!viewingFolders && (
        <Grid item xs={12} sm={viewingFolders ? 6 : 4}>
          <TextField
            fullWidth
            label="Search Multimedia"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Grid>
      )}

      {/* Folder Dropdown */}
      <Grid item xs={12} sm={viewingFolders ? 6 : 4}>
        <FormControl fullWidth>
          <InputLabel>Folder</InputLabel>
          <Select
            value={selectedFolder ? selectedFolder.id : ""}
            label="Folder"
            onChange={(e) => {
              const selectedFolder = folders.find(
                (folder) => folder.id === e.target.value
              );
              setSelectedFolder(selectedFolder);
            }}
            sx={{ height: "40px" }}
          >
            <MenuItem value="">All Folders</MenuItem>
            {folders.map((folder) => (
              <MenuItem key={folder.id} value={folder.id}>
                {folder.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {!viewingFolders && (
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Sort By</InputLabel>
            <Select
              value={sortOption || ""}
              label="Sort By"
              onChange={(e) => setSortOption(e.target.value)}
              sx={{ height: "40px" }}
            >
              <MenuItem value="">None</MenuItem>
              {sortOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      {/* Slider */}
      <Grid item xs={12} sm={4}>
        <Slider
          value={spacing}
          onChange={(e, newValue) => setSpacing(newValue)}
          aria-labelledby="spacing-slider"
          min={10}
          max={30}
          sx={{ "& .MuiSlider-rail": { opacity: 1, bgcolor: "#c0c0c0" } }}
        />
      </Grid>
    </Grid>
  );
};

export default MediaFilterControls;
