import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SubdomainField from "./SubdomainField";
import EmailAndUsername from "./EmailAndUsername";
import PasswordFields from "./PasswordFields";
import ContactFields from "./ContactFields";
import { TextField } from "@mui/material";

const BasicDetails = ({
  subdomain,
  setSubdomain,
  organizationName,
  setOrganizationName,
  name,
  setName,
  password,
  setPassword,
  verifyPassword,
  setVerifyPassword,
  contactEmail,
  setContactEmail,
  contactPhone,
  setContactPhone,
  address,
  setAddress,
  showValidation,
  setPasswordValid,
  userRegistration = false,
}) => {
  return (
    <Box>
      {!userRegistration && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Organization Name"
              value={organizationName}
              onChange={(e) => setOrganizationName(e.target.value)}
              fullWidth
              required
              margin="normal"
            />
          </Grid>

          <Grid item xs={6}>
            <SubdomainField
              subdomain={subdomain}
              setSubdomain={setSubdomain}
              organizationName={organizationName}
            />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2}>
        <EmailAndUsername
          contactEmail={contactEmail}
          setContactEmail={setContactEmail}
          name={name}
          setName={setName}
        />
      </Grid>

      <Grid container spacing={2}>
        <PasswordFields
          password={password}
          setPassword={setPassword}
          verifyPassword={verifyPassword}
          setVerifyPassword={setVerifyPassword}
          setPasswordValid={setPasswordValid}
        />
      </Grid>

      {!userRegistration && (
        <Grid container spacing={2}>
          <ContactFields
            contactPhone={contactPhone}
            setContactPhone={setContactPhone}
            address={address}
            setAddress={setAddress}
          />
        </Grid>
      )}
    </Box>
  );
};

export default BasicDetails;
