import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedVenue: null,
  venues: [],
};

// Check if there's a selectedVenue in localStorage and parse it if it's valid JSON
const storedSelectedVenue = localStorage.getItem("selectedVenue");
if (storedSelectedVenue) {
  try {
    initialState.selectedVenue = JSON.parse(storedSelectedVenue);
  } catch (e) {
    console.error("Error parsing selectedVenue from localStorage", e);
    initialState.selectedVenue = null;
  }
}

const venueSlice = createSlice({
  name: "venue",
  initialState,
  reducers: {
    setSelectedVenue(state, action) {
      state.selectedVenue = action.payload;
      localStorage.setItem("selectedVenue", JSON.stringify(action.payload));
    },
    setVenues(state, action) {
      state.venues = action.payload;
    },
    clearVenue(state) {
      state.selectedVenue = null;
      localStorage.removeItem("selectedVenue");
    },
  },
});

export const { setSelectedVenue, setVenues, clearVenue } = venueSlice.actions;
export default venueSlice.reducer;
