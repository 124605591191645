import React, { forwardRef, useRef } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  CardContent,
  DialogActions,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CSSTransition } from "react-transition-group";

import Backdrop from "../Backdrop/Backdrop";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";
import "./Modal.css";

const ModalOverlay = forwardRef((props, ref) => {
  const theme = useTheme();

  const dialogStyle = props.dialogStyle || {};

  const content = (
    <Dialog
      ref={ref}
      open={props.show}
      onClose={props.onCancel}
      aria-labelledby="modal-title"
      maxWidth="md"
      fullWidth
      sx={{
        zIndex: 100,
        "& .MuiDialog-paper": {
          backgroundColor: theme.palette.background.default,
          boxShadow: theme.shadows[5],
          borderRadius: theme.shape.borderRadius,
          overflow: "hidden",
          ...dialogStyle,
        },
      }}
    >
      <MDBox position="relative" width="100%" shadow="xl" borderRadius="xl">
        <CardContent
          sx={{ padding: theme.spacing(0), paddingBottom: "0px !important" }}
        >
          <MDBox p={2}>
            <MDTypography variant="h5" fontWeight="medium">
              {props.title}
            </MDTypography>
            {props.children ? (
              <DialogContent
                className="custom-dialog-content"
                dividers
                sx={{
                  maxHeight: "70vh",
                  overflowY: "auto",
                  margin: theme.spacing(2),
                }}
              >
                {props.children}
              </DialogContent>
            ) : (
              <div></div>
            )}
          </MDBox>
        </CardContent>
      </MDBox>
      {/* <DialogActions>{props.footer}</DialogActions> */}
    </Dialog>
  );
  return ReactDOM.createPortal(content, document.getElementById("modal-hook"));
});

ModalOverlay.propTypes = {
  onCancel: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
};

const Modal = (props) => {
  const nodeRef = useRef(null);

  return (
    <React.Fragment>
      {props.show && <Backdrop onClick={props.onCancel} />}
      <CSSTransition
        nodeRef={nodeRef}
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={200}
        classNames="modal"
      >
        <ModalOverlay ref={nodeRef} {...props} />
      </CSSTransition>
    </React.Fragment>
  );
};

export default Modal;
