import React, { useCallback, useEffect, useState } from "react";
import DashboardLayout from "shared/components/UIElements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/components/Navigation/DashboardNavbar";
import MDBox from "shared/components/MDComponents/MDBox";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import MDTypography from "shared/components/MDComponents/MDTypography";
import DataTable from "shared/components/UIElements/DataTable";
import RoleDropdown from "../components/table-components/RoleDropdown";
import DeleteButton from "../components/table-components/DeleteButton";
import VenueDropdown from "../components/table-components/VenueDropdown";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MDButton from "shared/components/MDComponents/MDButton";
import CreateUserDialog from "../components/CreateUserDialog";
import { useSelector } from "react-redux";
import { fetchUsers, deleteUser, updateUser } from "services/UserService";
import { fetchVenues } from "services/VenueService";
import useFetchTenant from "shared/hooks/useFetchTenant";
import MDAlert from "shared/components/MDComponents/MDAlert";

const Users = () => {
  const [usersData, setUsersData] = useState([]);
  const [venues, setVenues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [createUserDialogOpen, setCreateUserDialogOpen] = useState(false);
  const { user, status: userStatus } = useSelector((state) => state.user);
  const subdomain = useSelector((state) => state.subdomain.value);
  const { tenant } = useFetchTenant(subdomain);

  const [limitAlert, setLimitAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });

  const isSubscriptionActive = tenant?.status === "active";

  const showNotification = useCallback((message, type, setAlertFunction) => {
    setAlertFunction({ show: true, message, type });
  }, []);

  useEffect(() => {
    if (!isSubscriptionActive) {
      showNotification(
        "Your account is inactive. You can manage existing users, but you cannot create new ones.",
        "error",
        setLimitAlert
      );
    }
  }, [isSubscriptionActive, showNotification]);

  useEffect(() => {
    const loadUsersAndVenues = async () => {
      setIsLoading(true);
      try {
        // Fetch both users and venues simultaneously
        const [users, fetchedVenues] = await Promise.all([
          fetchUsers(subdomain),
          fetchVenues(subdomain),
        ]);

        let filteredUsers = users;

        if (user.role === "manager") {
          filteredUsers = users.filter((u) => {
            const isUnauthorized = u.role === "unauthorized";
            const isInSameVenue =
              u.venues &&
              u.venues.some((venue) =>
                user.venues.map((v) => v._id).includes(venue._id)
              );
            const isNotOwner = u.role !== "superadmin" || u.role !== "owner";
            const isCurrentUser = u._id === user._id;

            return (
              (isUnauthorized || isInSameVenue || isCurrentUser) && isNotOwner
            );
          });
        }

        setUsersData(filteredUsers);
        setVenues(fetchedVenues); // Store fetched venues
      } catch (error) {
        console.error("Error fetching users or venues:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (userStatus === "succeeded") {
      loadUsersAndVenues();
    }
  }, [userStatus, user]);

  const openCreateUserDialog = () => setCreateUserDialogOpen(true);
  const closeCreateUserDialog = () => setCreateUserDialogOpen(false);

  const handleAddUser = (newUser) => {
    setUsersData((prevUsers) => [...prevUsers, newUser]);
  };

  const handleRoleChange = async (user, newRole) => {
    try {
      if (!user) {
        console.error("User not found");
        return;
      }

      // Only allow role changes that comply with current user's permissions
      if (user.role === "manager" && newRole === "owner") {
        console.error("Manager cannot change role to owner");
        return;
      }

      const updatedUserData = {
        auth0Id: user.auth0Id,
        name: user.name,
        email: user.email,
        role: newRole,
        avatar: user.avatar,
        venues: newRole === "owner" ? [] : user.venues.map((v) => v._id),
      };

      await updateUser(user._id, updatedUserData, subdomain);
      setUsersData((prevUsers) =>
        prevUsers.map((item) =>
          item._id === user._id ? { ...item, role: newRole } : item
        )
      );
    } catch (error) {
      console.error("Error updating user role:", error);
    }
  };

  const handleVenueChange = async (user, newVenueIds) => {
    try {
      if (!user) {
        console.error("User not found");
        return;
      }

      if (user.role === "owner") {
        console.error("Owner cannot be assigned to a specific venue.");
        return;
      }

      const updatedUserData = {
        auth0Id: user.auth0Id,
        name: user.name,
        email: user.email,
        role: user.role,
        avatar: user.avatar,
        venues: newVenueIds,
      };

      await updateUser(user._id, updatedUserData, subdomain);

      const users = await fetchUsers(subdomain);
      setUsersData(users);
    } catch (error) {
      console.error("Error updating user venues:", error);
    }
  };

  const handleDeleteUser = async () => {
    try {
      await deleteUser(userToDelete, subdomain);
      setUsersData(usersData.filter((user) => user._id !== userToDelete));
      closeDeleteConfirm();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const openDeleteConfirm = (userId) => {
    setUserToDelete(userId);
    setDeleteConfirmOpen(true);
  };

  const closeDeleteConfirm = () => {
    setDeleteConfirmOpen(false);
    setUserToDelete(null);
  };

  const dataTableData = {
    columns: [
      { Header: "Name", accessor: "name", width: "20%" },
      { Header: "Email", accessor: "email", width: "20%" },
      {
        Header: "Venue",
        accessor: "venues",
        width: "20%",
        Cell: ({ row }) => (
          <VenueDropdown
            user={row.original}
            currentVenues={row.original.venues || []}
            venues={venues}
            onChange={handleVenueChange}
          />
        ),
      },
      {
        Header: "Role",
        accessor: "role",
        width: "20%",
        Cell: ({ row }) => (
          <RoleDropdown
            user={row.original}
            currentRole={row.original.role}
            onChange={handleRoleChange}
          />
        ),
      },
      {
        Header: "Delete",
        accessor: "actions",
        width: "20%",
        Cell: ({ row }) => (
          <DeleteButton
            userId={row.original._id}
            onDelete={openDeleteConfirm}
          />
        ),
      },
    ],
    rows: usersData,
  };

  if (isLoading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox
          pt={6}
          pb={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </MDBox>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <MDTypography variant="h5" fontWeight="medium">
              User Management
            </MDTypography>
            <MDButton
              variant="contained"
              color="dark"
              onClick={openCreateUserDialog}
              disabled={!isSubscriptionActive}
            >
              Create New User
            </MDButton>
          </MDBox>
          {limitAlert.show && (
            <MDBox mb={3} sx={{ position: "relative" }}>
              <MDAlert color={limitAlert.type} dismissible>
                <MDTypography variant="body2" color="white">
                  {limitAlert.message}
                </MDTypography>
              </MDAlert>
            </MDBox>
          )}
          <DataTable table={dataTableData} canSearch />
        </Card>
        <CreateUserDialog
          open={createUserDialogOpen}
          onClose={closeCreateUserDialog}
          onAddUser={handleAddUser}
        />
      </MDBox>

      <Dialog
        open={deleteConfirmOpen}
        onClose={closeDeleteConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this user? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton
            onClick={handleDeleteUser}
            variant="contained"
            color="error"
          >
            Delete
          </MDButton>
          <MDButton
            onClick={closeDeleteConfirm}
            variant="contained"
            color="dark"
          >
            Cancel
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
};

export default Users;
