import { useDispatch, useSelector } from "react-redux";
import { clearVenue } from "features/venue/store/VenueSlice";
import { useEffect } from "react";

// This is now just a function that handles logout logic, and can be used inside or outside of components
export const performLogout = () => {
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const baseUrl = window.location.hostname;
  const protocol = window.location.protocol;
  const returnToUrl = `${protocol}//${baseUrl}`;

  console.log("ReturnToUrl: ", returnToUrl);

  const logoutUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/v2/logout?client_id=${clientId}&returnTo=${encodeURIComponent(returnToUrl)}`;
  window.location.href = logoutUrl;
};

// Logout component can still be used independently
const Logout = () => {
  const dispatch = useDispatch();
  const subdomain = useSelector((state) => state.subdomain.value);

  useEffect(() => {
    dispatch(clearVenue());
    performLogout(); // Perform the actual logout
  }, [dispatch, subdomain]);

  return <div>Logging out...</div>;
};

export default Logout;
