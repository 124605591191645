// src/api/playlistService.js
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const fetchPlaylistsByVenue = async (venueId, subdomain) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/playlist/venue/${venueId}`,
      {
        headers: {
          "X-Subdomain": subdomain,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch playlists.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching playlists:", error);
    throw error;
  }
};

export const createPlaylist = async (playlistData, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/playlist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Subdomain": subdomain,
      },
      body: JSON.stringify(playlistData),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to create new playlist.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error creating playlist:", error);
    throw error;
  }
};

export const deletePlaylist = async (playlistId, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/playlist/${playlistId}`, {
      method: "DELETE",
      headers: {
        "X-Subdomain": subdomain,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to delete playlist.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error deleting playlist:", error);
    throw error;
  }
};

export const updatePlaylist = async (id, playlistData, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/playlist/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-Subdomain": subdomain,
      },
      body: JSON.stringify(playlistData),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to update playlist.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error updating playlist:", error);
    throw error;
  }
};
