import { fetchUtils } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
import store from "../store";

const apiUrl = `${process.env.REACT_APP_API_URL}/api`;
const adminApiUrl = `${process.env.REACT_APP_API_URL}/api/admin`;

const httpClient = async (url, options = {}) => {
  const state = store.getState();
  const subdomain = state.subdomain.value;

  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }

  options.headers.set("X-Subdomain", subdomain);

  // console.log("Request URL:", url); // Debugging URL
  // console.log("Request Options:", options); // Debugging options (headers, method, etc.)

  const response = await fetchUtils.fetchJson(url, options);
  return response;
};

const regularProvider = simpleRestProvider(apiUrl, httpClient);
const adminProvider = simpleRestProvider(adminApiUrl, httpClient);

// Customized data provider to route specific resources to the admin API
const dataProvider = {
  ...regularProvider,
  getList: (resource, params) => {
    if (resource === "superadmins" || resource === "users") {
      return adminProvider.getList(resource, params);
    }
    return regularProvider.getList(resource, params);
  },
  getOne: (resource, params) => {
    if (resource === "superadmins" || resource === "users") {
      return adminProvider.getOne(resource, params);
    }
    return regularProvider.getOne(resource, params);
  },
  create: (resource, params) => {
    if (resource === "superadmins" || resource === "users") {
      return adminProvider.create(resource, params);
    }
    return regularProvider.create(resource, params);
  },
  update: (resource, params) => {
    if (resource === "superadmins" || resource === "users") {
      return adminProvider.update(resource, params);
    }
    return regularProvider.update(resource, params);
  },
  delete: (resource, params) => {
    if (resource === "superadmins" || resource === "users") {
      return adminProvider.delete(resource, params);
    }
    return regularProvider.delete(resource, params);
  },
};

export default dataProvider;
