import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSearchParams, useNavigate } from "react-router-dom";

const QRLogin = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (token && !isAuthenticated) {
      loginWithRedirect({
        redirect_uri: `${window.location.origin}/qr-login`,
        appState: { returnTo: `/verify-qr?token=${token}` },
      });
    } else if (isAuthenticated) {
      navigate(`/verify-qr?token=${token}`);
    }
  }, [token, isAuthenticated, loginWithRedirect, navigate]);

  return <div>Redirecting to login...</div>;
};

export default QRLogin;
