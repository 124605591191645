import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import CenteredLoading from "shared/components/UIElements/Loading/CenteredLoading";

const ProtectedRoute = ({ children, requiredRoles }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const [isVerified, setIsVerified] = useState(true);
  const [loading, setLoading] = useState(true);
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (user && isAuthenticated) {
      if (!user.isVerified) {
        setIsVerified(false);
      }
      setLoading(false);
    }
  }, [user, isAuthenticated]);

  if (isLoading || loading) {
    return <CenteredLoading />;
  }

  if (!isVerified) {
    return <Navigate to="/verify-email" replace />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  if (requiredRoles && (!user || !requiredRoles.includes(user.role))) {
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
};

export default ProtectedRoute;
