import React, { useState } from "react";
import {
  Card,
  CardContent,
  Chip,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";
import CustomDayPicker from "shared/components/UIElements/DatePicker/DatePicker";
import MDButton from "shared/components/MDComponents/MDButton";

const ScheduleDaysOfWeek = ({
  daysOfWeek,
  banner,
  screenFit,
  editing,
  onDaysOfWeekChange,
  onBannerChange,
  onScreenFitChange,
}) => {
  const isDarkMode = localStorage.getItem("darkMode") === "true";
  const [dayPickerOpen, setDayPickerOpen] = useState(false);
  const [selectedDays, setSelectedDays] = useState(
    daysOfWeek.map((day) => new Date(day))
  );

  const getChipTextColor = (isDarkMode) => {
    return isDarkMode ? "white" : " #202940";
  };

  const getChipBackgroundColor = (isDarkMode) => {
    return isDarkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)";
  };

  const handleDayPickerOpen = () => {
    setDayPickerOpen(true);
  };

  const handleDayPickerClose = () => {
    setDayPickerOpen(false);
  };

  const handleDayPickerSave = () => {
    const formattedDays = selectedDays.map((day) => formatDate(day));
    onDaysOfWeekChange(formattedDays);
    handleDayPickerClose();
  };

  const formatDate = (date) => {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  return (
    <Card>
      <CardContent sx={{ height: 250, overflowY: "auto" }}>
        <MDTypography variant="h6" align="left">
          Days of the Week:
        </MDTypography>
        <MDBox display="flex" justifyContent="flex-start" mt={1}>
          {editing ? (
            <MDBox
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              <MDButton variant="outlined" onClick={handleDayPickerOpen}>
                Select Days
              </MDButton>
              <Dialog
                open={dayPickerOpen}
                onClose={handleDayPickerClose}
                fullWidth
                maxWidth="sm"
              >
                <DialogTitle>Select Days of the Week</DialogTitle>
                <DialogContent>
                  <CustomDayPicker
                    selectedDays={selectedDays}
                    onSelectDays={setSelectedDays}
                    isDarkMode={isDarkMode}
                  />
                </DialogContent>
                <DialogActions>
                  <MDButton
                    onClick={handleDayPickerSave}
                    variant="contained"
                    color="dark"
                  >
                    Save
                  </MDButton>
                  <MDButton
                    onClick={handleDayPickerClose}
                    variant="contained"
                    color="dark"
                  >
                    Cancel
                  </MDButton>
                </DialogActions>
              </Dialog>
            </MDBox>
          ) : (
            <MDBox display="flex" flexWrap="wrap" gap={1}>
              {daysOfWeek.map((day, index) => (
                <Chip
                  key={index}
                  label={day}
                  style={{
                    color: getChipTextColor(isDarkMode),
                    backgroundColor: getChipBackgroundColor(isDarkMode),
                  }}
                />
              ))}
            </MDBox>
          )}
        </MDBox>
        {editing && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={banner}
                  onChange={(e) => onBannerChange(e.target.checked)}
                />
              }
              label={
                <MDTypography style={{ color: banner ? "inherit" : "grey" }}>
                  Banner
                </MDTypography>
              }
              sx={{ mt: 2, marginBottom: "20px", marginRight: "10px" }}
            />
            <MDBox display="flex" flexDirection="column" gap={1} mt={1}>
              <MDTypography variant="subtitle2">
                Change Screen Fit:
              </MDTypography>
              <MDBox display="flex" flexDirection="row" gap={1}>
                <Checkbox
                  checked={screenFit !== "none"}
                  onChange={(e) =>
                    onScreenFitChange(e.target.checked ? "fit" : "none")
                  }
                />
                <Select
                  value={screenFit}
                  onChange={(e) => onScreenFitChange(e.target.value)}
                  fullWidth
                  disabled={screenFit === "none"}
                >
                  <MenuItem value="fit">Fit</MenuItem>
                  <MenuItem value="stretch">Stretch</MenuItem>
                  <MenuItem value="center">Center</MenuItem>
                  <MenuItem value="none">None</MenuItem>
                </Select>
              </MDBox>
            </MDBox>
          </>
        )}
        {!editing && (
          <>
            <FormControlLabel
              control={<Checkbox checked={banner} disabled />}
              label="Banner"
              sx={{ mt: 2 }}
            />
            <MDTypography variant="subtitle1">
              Screen Fit: {screenFit}
            </MDTypography>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default ScheduleDaysOfWeek;
