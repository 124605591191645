import React from "react";
import { Show, SimpleShowLayout, TextField, FunctionField } from "react-admin";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useRedirect } from "react-admin";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return `${formattedTime}, ${formattedDate}`;
};

const formatAmount = (amount, currency) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(amount / 100);
};

export const PaymentShow = (props) => {
  const redirect = useRedirect();

  const handleBackClick = () => {
    redirect("/admin/payment");
  };

  return (
    <Show {...props} resource="payment">
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton onClick={handleBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <h3 style={{ marginLeft: "10px" }}>Payment Details</h3>
      </div>
      <SimpleShowLayout>
        <TextField source="tenant.organizationName" label="Tenant Name" />
        <FunctionField
          label="Amount Paid"
          render={(record) => formatAmount(record.amountPaid, record.currency)}
        />
        <TextField source="currency" label="Currency" />
        <TextField source="invoiceId" label="Invoice ID" />
        <TextField source="paymentStatus" label="Status" />
        <FunctionField
          label="Payment Date"
          render={(record) => formatDate(record.paymentDate)}
        />
        <TextField source="paymentMethod.cardType" label="Card Type" />
        <TextField
          source="paymentMethod.lastFourDigits"
          label="Last 4 Digits"
        />
        <FunctionField
          label="Invoice URL"
          render={(record) =>
            record.invoiceUrl ? (
              <a
                href={record.invoiceUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download Invoice
              </a>
            ) : (
              "No Invoice Available"
            )
          }
        />
      </SimpleShowLayout>
    </Show>
  );
};
