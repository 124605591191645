// admin/Dashboard.js
import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { useAdminData } from "context/AdminContext";
import CenteredLoading from "shared/components/UIElements/Loading/CenteredLoading";

const AdminDashboard = () => {
  const { users, tenants, status, error } = useAdminData();

  if (status === "loading") {
    return <CenteredLoading />;
  }

  if (status === "failed") {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h4">Admin Dashboard</Typography>
        <Typography variant="body1">Welcome to the admin panel.</Typography>
        <Typography variant="body2">Total Users: {users.length}</Typography>
        <Typography variant="body2">
          Total Customers: {tenants.length}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default AdminDashboard;
