import React, { useEffect } from "react";
import { TextField, Box, Typography } from "@mui/material";
import MDButton from "shared/components/MDComponents/MDButton";

const VenueInfo = ({
  onVenueInfoChange,
  venueInfo,
  imagePreview,
  setImagePreview,
}) => {
  useEffect(() => {
    if (venueInfo.backgroundImage) {
      const imageUrl = URL.createObjectURL(venueInfo.backgroundImage);
      setImagePreview(imageUrl);

      return () => {
        URL.revokeObjectURL(imageUrl);
      };
    }
  }, [venueInfo.backgroundImage, setImagePreview]);

  const handleNameChange = (event) => {
    onVenueInfoChange({ ...venueInfo, name: event.target.value });
  };

  const handleDescriptionChange = (event) => {
    onVenueInfoChange({ ...venueInfo, description: event.target.value });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    onVenueInfoChange({ ...venueInfo, backgroundImage: file });
  };

  return (
    <>
      <TextField
        label="Venue Name"
        variant="outlined"
        fullWidth
        value={venueInfo.name}
        onChange={handleNameChange}
        sx={{ my: 2 }}
        inputProps={{ maxLength: 30 }}
      />
      <TextField
        label="Venue Description"
        variant="outlined"
        fullWidth
        value={venueInfo.description}
        onChange={handleDescriptionChange}
        sx={{ my: 2 }}
        inputProps={{ maxLength: 200 }}
      />
      <Box display="flex" alignItems="center" sx={{ my: 2 }}>
        <MDButton
          variant="contained"
          color="dark"
          component="label"
          sx={{ mr: 2 }}
        >
          Upload Background Image
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={handleImageChange}
          />
        </MDButton>
        {imagePreview && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ maxWidth: "80%", maxHeight: "200px", ml: 2 }}
          >
            <Typography variant="h6" sx={{ mb: 1 }}></Typography>
            <Box
              sx={{
                width: "100%",
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <img
                src={imagePreview}
                alt="Background Preview"
                style={{ width: "auto", height: "100%", objectFit: "contain" }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default VenueInfo;
