import { Box, Container } from "@mui/material";
import React from "react";
import MDTypography from "shared/components/MDComponents/MDTypography";

const PaymentSuccess = () => {
  return (
    <Container maxWidth="sm">
      {" "}
      {/* This ensures tasteful centering and limits the width */}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        textAlign="center"
      >
        <MDTypography variant="h3" fontWeight="bold" gutterBottom>
          Payment Successful!
        </MDTypography>
        <MDTypography variant="body1" mt={2} mb={3}>
          Thank you for your payment! Your subscription is now active.
          <br />
          Please check your email inbox shortly for instructions on setting up
          your account.
          <br />
          If you have any questions or need assistance, feel free to contact our
          support team at
          <a href="mailto:support@seriouslyds.com"> support@seriouslyds.com</a>.
        </MDTypography>
      </Box>
    </Container>
  );
};

export default PaymentSuccess;
