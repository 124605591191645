import React, { useState, useEffect } from "react";
import { Select, MenuItem, Checkbox, ListItemText } from "@mui/material";
import MDBox from "shared/components/MDComponents/MDBox";
import MDButton from "shared/components/MDComponents/MDButton";
import ProfileInfoCard from "shared/components/UIElements/InfoCards/ProfileInfoCard";
import { useDispatch } from "react-redux";
import { setSelectedVenue } from "features/venue/store/VenueSlice";
import { fetchUsers } from "services/UserService";
import { updateVenue } from "services/VenueService";
import { useSelector } from "react-redux";

const AssignedUsers = ({ venue, onUpdate, canEdit }) => {
  const dispatch = useDispatch();
  const [editing, setEditing] = useState(false);
  const [users, setUsers] = useState([]); // All users for selection
  const [selectedUsers, setSelectedUsers] = useState(venue.users);
  const subdomain = useSelector((state) => state.subdomain.value);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const users = await fetchUsers(subdomain);
        setUsers(
          users.filter(
            (user) => user.role !== "superadmin" || user.role !== "owner"
          )
        );
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    loadUsers();
  }, []);

  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancel = () => {
    setEditing(false);
    setSelectedUsers(venue.users); // Reset to initial users
  };

  const handleSave = async () => {
    const updatedVenueData = {
      title: venue.title,
      description: venue.description,
      users: selectedUsers.map((user) => user._id),
    };

    try {
      await updateVenue(venue._id, updatedVenueData, subdomain);
      onUpdate();
      dispatch(setSelectedVenue({ ...venue, users: selectedUsers }));
      setEditing(false);
    } catch (error) {
      console.error("Error updating venue:", error);
    }
  };

  const handleUserChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedUsers(users.filter((user) => value.includes(user._id)));
  };

  const assignedUsersInfo =
    selectedUsers && selectedUsers.length > 0
      ? selectedUsers.reduce((acc, user, index) => {
          acc[`User ${index + 1}`] = `${user.name}`;
          return acc;
        }, {})
      : {
          "No Users Assigned": "No users have been assigned to this venue.",
        };

  return (
    <ProfileInfoCard
      title="Assigned Users"
      description="Users assigned to this venue"
      info={assignedUsersInfo}
      action={
        canEdit
          ? {
              onClick: handleEdit,
              tooltip: "Edit Users",
            }
          : null
      }
      shadow={false}
    >
      {editing && (
        <>
          <MDBox sx={{ width: "100%" }}>
            <Select
              fullWidth
              multiple
              value={selectedUsers.map((user) => user._id)}
              onChange={handleUserChange}
              renderValue={(selected) =>
                users
                  .filter((user) => selected.includes(user._id))
                  .map((user) => user.name)
                  .join(", ")
              }
              sx={{
                height: 40,
                textAlign: "center",
                fontSize: "0.875rem",
                "& .MuiSelect-select": {
                  paddingTop: "10px",
                  paddingBottom: "10px",
                },
              }}
            >
              {users.map((user) => (
                <MenuItem key={user._id} value={user._id}>
                  <Checkbox
                    checked={selectedUsers.some((u) => u._id === user._id)}
                  />
                  <ListItemText primary={user.name} />
                </MenuItem>
              ))}
            </Select>
          </MDBox>
          <MDBox mt={2} display="flex" justifyContent="flex-end">
            <MDButton
              onClick={handleSave}
              variant="contained"
              color="dark"
              size="small"
            >
              Save
            </MDButton>
            <MDButton
              onClick={handleCancel}
              variant="contained"
              color="dark"
              size="small"
              sx={{ ml: 1 }}
            >
              Cancel
            </MDButton>
          </MDBox>
        </>
      )}
    </ProfileInfoCard>
  );
};

export default AssignedUsers;
