import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";
import ScheduleDetailModal from "./ScheduleDetailModal";
import { deleteSchedule } from "services/ScheduleService";
import { useSelector } from "react-redux";

const ScheduleItem = ({
  id,
  image,
  title,
  description,
  venueId,
  mediaLength,
  daysOfWeek,
  banner,
  screenFit,
  showings,
  spacing,
  isSelected,
}) => {
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const itemSize = spacing * 5;
  const fontSize = Math.max(12, spacing / 2); //Dynamically adjust font size
  const subdomain = useSelector((state) => state.subdomain.value);

  const toggleDetailModal = () => setShowDetailModal(!showDetailModal);

  const confirmDeleteHandler = async () => {
    try {
      await deleteSchedule(id, subdomain);
      setIsDeleted(true);
    } catch (error) {
      console.log("Error deleting schedule:", error);
    }
  };

  const truncateTitle = (text, maxLength = 20) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  useEffect(() => {
    if (isSelected) {
      setShowDetailModal(true);
    }
  }, [isSelected]);

  if (isDeleted) {
    return null;
  }

  return (
    <React.Fragment>
      <ScheduleDetailModal
        open={showDetailModal}
        onClose={toggleDetailModal}
        title={title}
        description={description}
        daysOfWeek={daysOfWeek}
        banner={banner}
        screenFit={screenFit}
        showings={showings}
        onDelete={confirmDeleteHandler}
        id={id}
        venueId={venueId}
      />

      <Box
        sx={{ width: `${itemSize}px`, m: 0, textAlign: "center" }}
        id={`schedule-${id}`}
        onClick={toggleDetailModal}
      >
        <Box
          sx={{
            cursor: "pointer",
            width: "100%",
            height: `${itemSize}px`,
            borderRadius: "10px",
            overflow: "hidden",
            backgroundImage: `url(${image})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: "0px 4px 10px rgba(0,0,0,0.3)",
            },
          }}
        />
        <MDTypography variant="h6" sx={{ fontSize: `${fontSize}px`, mt: 1 }}>
          {truncateTitle(title)}
        </MDTypography>
      </Box>

      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        lineHeight={1}
      ></MDBox>
    </React.Fragment>
  );
};

ScheduleItem.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  daysOfWeek: PropTypes.arrayOf(PropTypes.string).isRequired,
  showings: PropTypes.arrayOf(PropTypes.object).isRequired,
  spacing: PropTypes.number.isRequired,
  isSelected: PropTypes.bool,
};

export default ScheduleItem;
