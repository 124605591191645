import React, { useEffect, useState, memo } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  FormControlLabel,
  Switch,
  Chip,
  Tooltip,
  IconButton,
  Box,
  Divider,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import FormField from "../form-field";
import MDBox from "shared/components/MDComponents/MDBox";
import CustomDayPicker from "shared/components/UIElements/DatePicker/DatePicker";
import MDTypography from "shared/components/MDComponents/MDTypography";
import ScreenFitOption from "shared/components/UIElements/Toggles/ScreenFitOptions";
import { useMediaQuery } from "@mui/material";

function ScheduleInfo({ onUpdate, scheduleInfo }) {
  const theme = useTheme();
  const darkMode = theme.darkMode;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [scheduleName, setScheduleName] = useState(scheduleInfo?.title || "");
  const [description, setDescription] = useState(
    scheduleInfo?.description || ""
  );
  const [selectedDays, setSelectedDays] = useState(
    scheduleInfo?.daysOfWeek?.map((day) => new Date(day)) || []
  );
  const [banner, setBanner] = useState(scheduleInfo?.banner || true);
  const [changeScreenFit, setChangeScreenFit] = useState(false);
  const [screenFit, setScreenFit] = useState("none");

  useEffect(() => {
    onUpdate({
      title: scheduleName,
      description: description,
      daysOfWeek: selectedDays.map((day) => formatDate(day)),
      banner: banner,
      screenFit: changeScreenFit ? screenFit : "none",
    });
  }, [
    scheduleName,
    description,
    selectedDays,
    banner,
    changeScreenFit,
    screenFit,
    onUpdate,
  ]);

  const handleDaySelection = (days) => {
    setSelectedDays(days);
  };

  const toggleDaySelection = (day) => {
    setSelectedDays((prevDays) =>
      prevDays.some((d) => d.toDateString() === day.toDateString())
        ? prevDays.filter((d) => d.toDateString() !== day.toDateString())
        : [...prevDays, day]
    );
  };

  const formatDate = (date) => {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  return (
    <MDBox sx={{ px: 2, pb: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} display="flex" alignItems="center">
          <FormField
            label="Schedule Name"
            value={scheduleName}
            onChange={(e) => setScheduleName(e.target.value)}
            inputProps={{ maxLength: 30 }}
          />
        </Grid>
        <Grid item xs={12} display="flex" alignItems="center">
          <FormField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            rows={4}
            inputProps={{
              style: { color: theme.palette.text.primary },
              maxLength: 200,
            }}
          />
        </Grid>
        <Grid item xs={12} display="flex" alignItems="center">
          <FormControlLabel
            control={
              <Switch
                checked={banner}
                onChange={(e) => setBanner(e.target.checked)}
                color="primary"
              />
            }
            label={
              <MDTypography style={{ color: banner ? "inherit" : "grey" }}>
                Banner
              </MDTypography>
            }
            sx={{ marginBottom: "20px", marginRight: "10px" }}
          />
          <Tooltip
            sx={{ alignSelf: "flex-start" }}
            title="Toggle to display the banner on a schedule. This shows the current media item you're on, as well as the upcoming one."
          >
            <IconButton>
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <ScreenFitOption
            changeScreenFit={changeScreenFit}
            setChangeScreenFit={setChangeScreenFit}
            screenFit={screenFit}
            setScreenFit={setScreenFit}
          />
        </Grid>
        <Divider
          sx={{
            width: "100%",
            my: 2,
            borderBottom: "1px solid",
            borderColor: theme.palette.text.primary,
          }}
        />
        {/* Row with "Choose the days" and "Selected Days" */}
        <Grid item xs={12} md={6}>
          <MDTypography variant="subtitle2">
            Choose the days for the schedule:
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={6}></Grid>
        <Grid item xs={12} md={6}>
          {/* Constrained Calendar */}
          <MDBox
            mt={2}
            sx={{
              padding: 2,
              border: "1px solid",
              borderRadius: "8px",
              backgroundColor: darkMode
                ? "rgba(255, 255, 255, 0.1)"
                : "rgba(0, 0, 0, 0.03)",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <CustomDayPicker
              selectedDays={selectedDays}
              onSelectDays={handleDaySelection}
              isDarkMode={darkMode}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6}>
          {/* Box for Selected Days */}
          <MDBox
            mt={2}
            sx={{
              padding: 2,
              border: "1px solid",
              borderColor: theme.palette.text.secondary,
              borderRadius: "8px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              backgroundColor: darkMode
                ? "rgba(255, 255, 255, 0.1)"
                : "rgba(0, 0, 0, 0.03)",
            }}
          >
            <Box display="flex" flexWrap="wrap" justifyContent="center">
              {selectedDays.length === 0 ? (
                <MDTypography variant="body2">
                  No days selected yet.
                </MDTypography>
              ) : (
                selectedDays.map((day, index) => (
                  <Chip
                    key={index}
                    label={formatDate(day)}
                    onClick={() => toggleDaySelection(day)}
                    color="primary"
                    variant="outlined"
                    sx={{
                      color: "rgba(255, 255, 255, 1)",
                      backgroundColor: "#3f51b5 !important",
                      border: `1px solid ${
                        darkMode
                          ? "rgba(255, 255, 255, 0.3)"
                          : "rgba(0, 0, 0, 0.3)"
                      }`,
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                      position: "relative",
                      margin: "4px",
                      transition: "background-color 0.2s ease",
                      "& .MuiChip-label": {
                        color: "rgba(255, 255, 255, 1)",
                      },
                      "&:hover": {
                        backgroundColor: "rgba(63, 81, 181, 0.7) !important",
                      },
                      "&:hover::after": {
                        content: "'✕'",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        fontSize: "2.5rem",
                        color: darkMode
                          ? "rgba(255, 0, 0, 0.589)"
                          : "rgba(255, 0, 0, 0.589)",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        pointerEvents: "none",
                      },
                    }}
                  />
                ))
              )}
            </Box>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default memo(ScheduleInfo);
