import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRedirect,
  useInput,
} from "react-admin";
import Autocomplete from "@mui/material/Autocomplete";
import { roleChoices } from "../../utils/validation";
import CustomToolbar from "../../utils/CustomToolbar";

export const UserEdit = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleSubmit = async (values) => {
    try {
      const { name, role, venues, email } = values;

      const formData = {
        email,
        name,
        role,
        venues: Array.isArray(venues) ? venues : [venues], // Ensure venues is an array
      };

      await dataProvider.update("users", { id: values.id, data: formData });
      notify("User updated successfully", { type: "info" });
      redirect("list", "users");
    } catch (error) {
      notify(`Error updating user: ${error.message}`, { type: "error" });
    }
  };

  return (
    <Edit {...props}>
      <SimpleForm
        toolbar={<CustomToolbar resource="users" />}
        onSubmit={handleSubmit}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextInput source="name" fullWidth label="Full Name" />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              source="role"
              choices={roleChoices}
              fullWidth
              label="Role"
            />
          </Grid>

          <Grid item xs={12}>
            <VenuesDropdown />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

const VenuesDropdown = () => {
  const [venues, setVenues] = useState([]);
  const [loadingVenues, setLoadingVenues] = useState(true);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const record = useRecordContext();

  const {
    field, // Provides form integration
    isRequired,
    fieldState: { error },
    formState: { isSubmitted },
  } = useInput({
    source: "venues", // This ensures it's tied to the form state
    validate: (value) =>
      !value || value.length === 0
        ? "At least one venue must be selected"
        : undefined,
  });

  useEffect(() => {
    const tenant = record?.tenants?.[0];
    if (tenant && tenant._id) {
      const fetchVenuesByTenantId = async (tenantId, tenantSubdomain) => {
        try {
          const { data } = await dataProvider.getList(
            `venue/all/${tenantId}`,
            { pagination: { page: 1, perPage: 50 } },
            {
              headers: {
                "X-Subdomain": tenantSubdomain,
              },
            }
          );
          setVenues(data);
          setLoadingVenues(false);
        } catch (error) {
          notify(`Error fetching venues: ${error.message}`, { type: "error" });
          setLoadingVenues(false);
        }
      };

      fetchVenuesByTenantId(tenant._id, tenant.organizationName);
    }
  }, [record, dataProvider, notify]);

  const handleVenueChange = (event, newValue) => {
    field.onChange(newValue.map((venue) => venue._id)); // Update the form state with the selected venues
  };

  return loadingVenues ? (
    "Loading venues..."
  ) : venues.length === 0 ? (
    "No venues exist for that customer."
  ) : (
    <Autocomplete
      multiple
      options={venues}
      getOptionLabel={(venue) => venue.title}
      value={venues.filter((venue) =>
        field.value ? field.value.includes(venue._id) : []
      )}
      onChange={handleVenueChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Venues"
          required={isRequired}
          error={!!error}
          helperText={error && isSubmitted ? error : ""}
        />
      )}
    />
  );
};
