import React from "react";
import PropTypes from "prop-types";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";

const Validation = ({ fields }) => {
  const isDarkMode = localStorage.getItem("darkMode") === "true";

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordStrengthRegex = {
    length: /^.{8,30}$/, // Between 8 and 30 characters
    uppercase: /[A-Z]/,
    lowercase: /[a-z]/,
    number: /[0-9]/,
    special: /[!@#$%^&*(),.?":{}|<>]/,
  };

  const validationMessages = fields
    .filter(
      (field) =>
        !field.value ||
        (field.name.toLowerCase() === "email" &&
          !emailRegex.test(field.value)) ||
        (field.minLength && field.value.length < field.minLength) ||
        (field.maxLength && field.value.length > field.maxLength) ||
        (field.name.toLowerCase() === "password" &&
          (!passwordStrengthRegex.length.test(field.value) ||
            !passwordStrengthRegex.uppercase.test(field.value) ||
            !passwordStrengthRegex.lowercase.test(field.value) ||
            !passwordStrengthRegex.number.test(field.value) ||
            !passwordStrengthRegex.special.test(field.value)))
    )
    .map((field) => {
      if (!field.value) {
        return `Please provide ${field.name}.`;
      } else if (
        field.name.toLowerCase() === "email" &&
        !emailRegex.test(field.value)
      ) {
        return "Please provide a valid email address.";
      } else if (
        field.name.toLowerCase() === "password" &&
        (!passwordStrengthRegex.length.test(field.value) ||
          !passwordStrengthRegex.uppercase.test(field.value) ||
          !passwordStrengthRegex.lowercase.test(field.value) ||
          !passwordStrengthRegex.number.test(field.value) ||
          !passwordStrengthRegex.special.test(field.value))
      ) {
        return `Password must be 8-30 characters long, with at least one uppercase letter, one lowercase letter, one number, and one special character.`;
      } else if (field.minLength && field.value.length < field.minLength) {
        return `${field.name} must be at least ${field.minLength} characters long.`;
      } else if (field.maxLength && field.value.length > field.maxLength) {
        return `${field.name} must be no more than ${field.maxLength} characters long.`;
      }
      return null;
    })
    .filter(Boolean); // Remove any null values from the array

  return (
    validationMessages.length > 0 && (
      <MDBox
        mb={2}
        p={2}
        borderRadius="4px"
        sx={{
          backgroundColor: isDarkMode
            ? "rgba(255, 0, 0, 0.3)"
            : "rgba(255, 0, 0, 0.7)",
        }}
      >
        {validationMessages.map((message, index) => (
          <MDTypography key={index} variant="body2" color="white">
            {message}
          </MDTypography>
        ))}
      </MDBox>
    )
  );
};

Validation.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any,
      minLength: PropTypes.number, // Optional minimum length
      maxLength: PropTypes.number, // Optional maximum length
    })
  ).isRequired,
};

export default Validation;
