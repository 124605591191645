import React, { useState, useCallback, memo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";
import MDBox from "shared/components/MDComponents/MDBox";
import MDButton from "shared/components/MDComponents/MDButton";
import MDTypography from "shared/components/MDComponents/MDTypography";
import DashboardLayout from "shared/components/UIElements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/components/Navigation/DashboardNavbar";
import { useSelector } from "react-redux";

import ScheduleInfo from "../components/new-schedule/ScheduleInfo";
import ScheduleShowings from "../components/new-schedule/ScheduleShowings";
import { createSchedule } from "services/ScheduleService";
import Validation from "shared/components/UIElements/Validation/Validation";
import MDAlert from "shared/components/MDComponents/MDAlert";
import { fetchMediaByVenue } from "services/MediaService";
import useFetchTenant from "shared/hooks/useFetchTenant";

function NewSchedule() {
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const darkMode = theme.darkMode;
  const selectedVenue = useSelector((state) => state.venue.selectedVenue);
  const subdomain = useSelector((state) => state.subdomain.value);
  const { tenant } = useFetchTenant(subdomain);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;
  const navigate = useNavigate();

  const [mediaAlert, setMediaAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });
  const [limitAlert, setLimitAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });

  const isSubscriptionActive = tenant?.status === "active";
  const hasReachedContentLimit =
    tenant?.currentContentItems >= tenant?.maxContentItems;

  const [scheduleInfo, setScheduleInfo] = useState({
    title: "",
    description: "",
    daysOfWeek: [],
    weekDays: [],
  });
  const [showings, setShowings] = useState([]);
  const [showValidation, setShowValidation] = useState(false);
  const supportedImageFormats = ["png", "jpeg", "jpg", "webp", "pdf"];

  const showNotification = useCallback((message, type, setAlertFunction) => {
    setAlertFunction({ show: true, message, type });
  }, []);

  useEffect(() => {
    if (!isSubscriptionActive) {
      showNotification(
        "Your account is inactive. You can manage existing schedules, but you cannot create new ones.",
        "error",
        setLimitAlert
      );
    } else if (hasReachedContentLimit) {
      showNotification(
        "You have reached the maximum number of content items allowed. Please delete existing content or contact support to upgrade.",
        "error",
        setLimitAlert
      );
    }
  }, [isSubscriptionActive, hasReachedContentLimit, showNotification]);

  useEffect(() => {
    const fetchVenueMedia = async () => {
      try {
        const data = await fetchMediaByVenue(selectedVenue.id, subdomain);
        const imageMedia = data.media.filter((item) => {
          const extension = item.url.split(".").pop().toLowerCase();
          return supportedImageFormats.includes(extension);
        });

        if (imageMedia.length === 0) {
          showNotification(
            "No image media is available for this venue. Please upload image media before creating a schedule.",
            "error",
            setMediaAlert
          );
        } else {
          setMediaAlert({ show: false, message: "", type: "info" });
        }
      } catch (error) {
        showNotification(
          "There was an error fetching media for the venue.",
          "error",
          setMediaAlert
        );
        console.error("Error fetching media for venue:", error);
      }
    };

    fetchVenueMedia();
  }, [selectedVenue.id, subdomain]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    setShowValidation(false);
  };

  const handleBack = () => setActiveStep(activeStep - 1);

  const handleCreateSchedule = async () => {
    if (isValid()) {
      const { weekDays, ...scheduleForSubmission } = scheduleInfo;

      const transformedShowings = showings.map(
        ({ signedImageUrl, uniqueId, ...rest }) => rest
      );

      const scheduleData = {
        schedule: {
          ...scheduleForSubmission,
          venue: selectedVenue.id,
        },
        showings: transformedShowings,
      };

      try {
        await createSchedule(scheduleData, subdomain);
        navigate(`/schedules`);
      } catch (error) {
        console.log("Error submitting schedule:", error);
      }
    } else {
      setShowValidation(true);
    }
  };

  const isValid = () => {
    const { title, description, daysOfWeek } = scheduleInfo;
    return title && description && daysOfWeek.length > 0 && showings.length > 0;
  };

  const updateScheduleInfo = useCallback(
    (info) => {
      setScheduleInfo(info);
    },
    [setScheduleInfo]
  );

  const updateShowings = useCallback(
    (newShowings) => {
      setShowings(newShowings);
    },
    [setShowings]
  );

  function getSteps() {
    return ["1. Schedule Info", "2. Media Selection"];
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <ScheduleInfo
            onUpdate={updateScheduleInfo}
            scheduleInfo={scheduleInfo}
          />
        );
      case 1:
        return (
          <ScheduleShowings onUpdate={updateShowings} showings={showings} />
        );
      default:
        return null;
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Create New Schedule
                </MDTypography>
              </MDBox>
              <MDTypography variant="h5" fontWeight="regular" color="secondary">
                Schedules allow you to organize and display your content at
                specific times and on selected days of the week. Ideal for
                managing when your content appears.
              </MDTypography>
            </MDBox>

            {mediaAlert.show && (
              <MDBox mb={3} sx={{ position: "relative" }}>
                <MDAlert color={mediaAlert.type} dismissible>
                  <MDTypography variant="body2" color="white">
                    No image media is available for this venue. Please{" "}
                    <MDTypography
                      variant="body1"
                      component={Link}
                      to="/newmedia"
                      sx={{
                        color: darkMode ? "#00BFFF" : "#00BFFF",
                        textDecoration: "none",
                        "&:hover": {
                          color: darkMode ? "#ff4081" : "#344767",
                        },
                      }}
                    >
                      upload image media
                    </MDTypography>{" "}
                    before creating a schedule.
                  </MDTypography>
                </MDAlert>
              </MDBox>
            )}

            {limitAlert.show && (
              <MDBox mb={3} sx={{ position: "relative" }}>
                <MDAlert color={limitAlert.type} dismissible>
                  <MDTypography variant="body2" color="white">
                    {limitAlert.message}
                  </MDTypography>
                </MDAlert>
              </MDBox>
            )}

            <Card>
              <MDBox mt={-3} mb={3} mx={2}>
                <Stepper
                  activeStep={activeStep}
                  alternativeLabel
                  sx={{
                    background: `${
                      darkMode ? "#344767" : "#344767"
                    } !important`,
                  }}
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={2}>
                {getStepContent(activeStep)}
                {showValidation && isLastStep && (
                  <Validation
                    fields={[
                      { name: "Title", value: scheduleInfo.title },
                      { name: "Description", value: scheduleInfo.description },
                      {
                        name: "Days of Week",
                        value: scheduleInfo.daysOfWeek.length > 0,
                      },
                      { name: "Showings", value: showings.length > 0 },
                    ]}
                  />
                )}
                <MDBox mt={3} display="flex" justifyContent="space-between">
                  {activeStep === 0 ? (
                    <MDBox />
                  ) : (
                    <MDButton
                      variant="contained"
                      color="dark"
                      onClick={handleBack}
                    >
                      Back
                    </MDButton>
                  )}
                  <MDButton
                    variant="contained"
                    color="dark"
                    onClick={isLastStep ? handleCreateSchedule : handleNext}
                    disabled={hasReachedContentLimit || !isSubscriptionActive}
                  >
                    {isLastStep ? "Create Schedule" : "Next"}
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default memo(NewSchedule);
