import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { checkSubdomain } from "services/TenantService";
import useDebounce from "shared/hooks/useDebounce";
import { Typography } from "@mui/material";

const SubdomainField = ({ subdomain, setSubdomain, organizationName }) => {
  const [subdomainError, setSubdomainError] = useState("");
  const [subdomainSuggestion, setSubdomainSuggestion] = useState("");
  const [fullUrl, setFullUrl] = useState("");
  const debouncedOrgName = useDebounce(organizationName, 500);

  useEffect(() => {
    if (debouncedOrgName) {
      const generatedSubdomain = debouncedOrgName
        .toLowerCase()
        .replace(/\s+/g, "-")
        .replace(/[^a-z0-9-]/g, "");
      setSubdomain(generatedSubdomain);
    }
  }, [debouncedOrgName, setSubdomain]);

  useEffect(() => {
    if (subdomain) {
      setFullUrl(`${subdomain}.seriouslyds.com`);
      checkSubdomain(subdomain).then((res) => {
        if (res.exists) {
          setSubdomainError(
            `The subdomain "${subdomain}.seriouslyds.com" is already taken.`
          );
          suggestAlternativeSubdomain(subdomain);
        } else {
          setSubdomainError("");
          setSubdomainSuggestion("");
        }
      });
    }
  }, [subdomain]);

  const suggestAlternativeSubdomain = async (originalSubdomain) => {
    let suffix = 1;
    let suggestion = originalSubdomain;

    while (suffix < 1000) {
      const newSubdomain = `${originalSubdomain}${suffix}`;
      const response = await checkSubdomain(newSubdomain);
      if (!response.exists) {
        setSubdomainSuggestion(newSubdomain);
        return;
      }
      suffix++;
    }
  };

  return (
    <TextField
      label="Subdomain"
      value={subdomain}
      onChange={(e) => setSubdomain(e.target.value)}
      fullWidth
      required
      margin="normal"
      placeholder={subdomainSuggestion} // Suggest the alternative subdomain here
      InputProps={{
        endAdornment: subdomainSuggestion ? (
          <InputAdornment position="end">
            <Typography variant="body1" style={{ color: "grey" }}>
              {`suggestion: ${subdomainSuggestion}`}
            </Typography>
          </InputAdornment>
        ) : null,
      }}
      helperText={subdomainError || `${fullUrl}`}
      error={Boolean(subdomainError)}
    />
  );
};

export default SubdomainField;
