import React from "react";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";
import { Collapse, Divider, IconButton, useTheme } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const WalkthroughStep = ({ title, description, videoUrl, isOpen, onClick }) => {
  const theme = useTheme();
  const darkMode = theme.darkMode;

  // Check if the URL is a Loom video
  const isLoomVideo = (url) => url.includes("loom.com");

  // Add parameters to hide reactions and controls in Loom videos
  const formattedVideoUrl = isLoomVideo(videoUrl)
    ? `${videoUrl}?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`
    : videoUrl;

  return (
    <MDBox
      onClick={onClick}
      sx={{
        cursor: "pointer",
        borderRadius: "4px",
        transition: "background-color 0.3s ease",
        "&:hover": {
          backgroundColor: darkMode
            ? "rgba(255, 255, 255, 0.05)"
            : "rgba(0, 0, 0, 0.05)",
        },
      }}
    >
      <Divider />
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        onClick={onClick}
      >
        <MDTypography mx={2} variant="h6">
          {title}
        </MDTypography>
        <IconButton
          sx={{
            transition: "transform 0.3s ease",
            transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
          }}
        >
          <ArrowDropDownIcon />
        </IconButton>
      </MDBox>
      <Collapse in={isOpen}>
        <MDTypography mx={2} variant="body1" mt={1}>
          {description}
        </MDTypography>
        <MDBox mt={2} mx={2}>
          {isLoomVideo(formattedVideoUrl) ? (
            <div
              style={{
                position: "relative",
                paddingBottom: "56.25%",
                height: 0,
              }}
            >
              <iframe
                src={formattedVideoUrl}
                title={`Walkthrough video for ${title}`}
                frameBorder="0"
                allowFullScreen
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  borderRadius: "8px",
                  boxShadow: darkMode
                    ? "0px 4px 10px rgba(255, 255, 255, 0.1)"
                    : "0px 4px 10px rgba(0, 0, 0, 0.1)",
                }}
              ></iframe>
            </div>
          ) : (
            <video
              src={videoUrl}
              controls
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "8px",
                boxShadow: darkMode
                  ? "0px 4px 10px rgba(255, 255, 255, 0.1)"
                  : "0px 4px 10px rgba(0, 0, 0, 0.1)",
              }}
            />
          )}
        </MDBox>
      </Collapse>
      <Divider />
    </MDBox>
  );
};

export default WalkthroughStep;
