import React from "react";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";
import MediaDirectory from "features/media/components/MediaDirectory";
import SlideshowDroppableArea from "shared/components/UIElements/Dropzone/SlideshowDroppableArea";

function SlideshowMedia({ setMediaList, mediaList }) {
  const handleDrop = (droppedItem) => {
    const newItem = {
      media: droppedItem.id?.toString() || "",
      title: droppedItem.name || "",
      duration: 5,
      signedImageUrl: droppedItem.signedImageUrl || "",
      uniqueId: droppedItem.uniqueId || `${droppedItem.id}-${Date.now()}`,
    };
    setMediaList((prevItems) => [...prevItems, newItem]);
  };

  const handleDurationChange = (index, duration) => {
    const updatedItems = mediaList.map((item, idx) =>
      idx === index ? { ...item, duration } : item
    );
    setMediaList(updatedItems);
  };

  const handleRemoveItem = (index) => {
    const updatedItems = mediaList.filter((_, idx) => idx !== index);
    setMediaList(updatedItems);
  };

  const moveItem = (dragIndex, hoverIndex) => {
    const draggedItem = mediaList[dragIndex];
    const updatedItems = [...mediaList];
    updatedItems.splice(dragIndex, 1);
    updatedItems.splice(hoverIndex, 0, draggedItem);
    setMediaList(updatedItems);
  };

  const handleTitleChange = (index, title) => {
    const updatedItems = mediaList.map((item, idx) =>
      idx === index ? { ...item, title } : item
    );
    setMediaList(updatedItems);
  };

  return (
    <MDBox p={2}>
      <MDTypography variant="h5">Add Media</MDTypography>
      <MDBox mt={2}>
        <MediaDirectory
          onMediaClick={handleDrop}
          onDrop={handleDrop}
          mediaList={mediaList}
          setMediaList={setMediaList}
          mediaType="images"
        />
      </MDBox>
      <MDBox mt={2}>
        <SlideshowDroppableArea
          timelineItems={mediaList}
          handleDrop={handleDrop}
          handleRemoveItem={handleRemoveItem}
          handleDurationChange={handleDurationChange}
          onMoveItem={moveItem}
          handleTitleChange={handleTitleChange}
        />
      </MDBox>
    </MDBox>
  );
}

export default SlideshowMedia;
