import { createContext, useContext, useState, useEffect } from "react";

const VenueContext = createContext();

export const useVenue = () => useContext(VenueContext);

export const VenueProvider = ({ children }) => {
  const [selectedVenue, setSelectedVenue] = useState(() => {
    // Try to get the venue from sessionStorage
    const storedVenue = sessionStorage.getItem("selectedVenue");
    return storedVenue ? JSON.parse(storedVenue) : null;
  });

  useEffect(() => {
    // Update sessionStorage when selectedVenue changes
    if (selectedVenue) {
      sessionStorage.setItem("selectedVenue", JSON.stringify(selectedVenue));
    } else {
      sessionStorage.removeItem("selectedVenue");
    }
  }, [selectedVenue]);

  // Export a function to clear the venue state
  const clearVenue = () => setSelectedVenue(null);

  return (
    <VenueContext.Provider
      value={{ selectedVenue, setSelectedVenue, clearVenue }}
    >
      {children}
    </VenueContext.Provider>
  );
};
