import React from "react";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";
import { Collapse, Divider, IconButton, useTheme } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const FAQEntry = ({ question, answer, isOpen, onClick }) => {
  const theme = useTheme();
  const darkMode = theme.darkMode;

  return (
    <MDBox
      onClick={onClick}
      sx={{
        cursor: "pointer",
        borderRadius: "4px",
        transition: "background-color 0.3s ease",
        "&:hover": {
          backgroundColor: darkMode
            ? "rgba(255, 255, 255, 0.05)"
            : "rgba(0, 0, 0, 0.05)",
        },
      }}
    >
      <Divider />
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        onClick={onClick}
      >
        <MDTypography mx={2} variant="h6">
          {question}
        </MDTypography>
        <IconButton
          sx={{
            transition: "transform 0.3s ease",
            transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
          }}
        >
          <ArrowDropDownIcon />
        </IconButton>
      </MDBox>
      <Collapse in={isOpen}>
        <MDTypography mx={2} variant="body2" mt={1}>
          {answer}
        </MDTypography>
      </Collapse>
      <Divider />
    </MDBox>
  );
};

export default FAQEntry;
