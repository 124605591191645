// src/components/CustomDayPicker.js
import React, { useState, useEffect } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import "./DatePicker.css";

const CustomDayPicker = ({ selectedDays, onSelectDays, isDarkMode }) => {
  const [internalSelectedDays, setInternalSelectedDays] =
    useState(selectedDays);

  useEffect(() => {
    setInternalSelectedDays(selectedDays);
  }, [selectedDays]);

  const handleDayClick = (day, { selected }) => {
    const newSelectedDays = selected
      ? internalSelectedDays.filter(
          (selectedDay) => selectedDay.toDateString() !== day.toDateString()
        )
      : [...internalSelectedDays, day];
    setInternalSelectedDays(newSelectedDays);
    onSelectDays(newSelectedDays);
  };

  const dayPickerStyles = {
    color: isDarkMode ? "white" : "#344767",
    paddingRight: 0,
    marginRight: 0,
  };

  return (
    <div className="day-picker" style={dayPickerStyles}>
      <DayPicker
        selected={internalSelectedDays}
        onDayClick={handleDayClick}
        mode="multiple"
        modifiersClassNames={{
          selected: "selected-day",
        }}
      />
    </div>
  );
};
export default CustomDayPicker;
