import { createSlice } from "@reduxjs/toolkit";
import { fetchTenant } from "./TenantSlice";

const initialState = {
  value: "",
};

const storedSubdomain = localStorage.getItem("subdomain");
if (storedSubdomain) {
  initialState.value = storedSubdomain;
}

const subdomainSlice = createSlice({
  name: "subdomain",
  initialState,
  reducers: {
    setSubdomain(state, action) {
      state.value = action.payload;
      localStorage.setItem("subdomain", action.payload);
    },
    clearSubdomain(state) {
      state.value = "";
      localStorage.removeItem("subdomain");
    },
  },
});

export const setSubdomainAndFetchTenant = (subdomain, token) => (dispatch) => {
  dispatch(setSubdomain(subdomain));
  dispatch(fetchTenant({ token, subdomain }));
};

export const { setSubdomain, clearSubdomain } = subdomainSlice.actions;
export default subdomainSlice.reducer;
