import React from "react";
import { Box, CircularProgress } from "@mui/material";

const CenteredLoading = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      textAlign="center"
      position="fixed"
      top={0}
      left={0}
      width="100vw"
      bgcolor="background.default"
      zIndex={1000}
    >
      <CircularProgress />
    </Box>
  );
};

export default CenteredLoading;
