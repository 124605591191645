import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Stepper, Step, StepLabel, Card, Grid } from "@mui/material";
import MDBox from "shared/components/MDComponents/MDBox";
import MDButton from "shared/components/MDComponents/MDButton";
import MDTypography from "shared/components/MDComponents/MDTypography";
import BasicDetails from "../components/registration/BasicDetails";
import Validation from "shared/components/UIElements/Validation/Validation";
import { createUser, fetchUserByEmail } from "services/UserService";
import { getTenantById } from "services/TenantService";

const RegisterUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [error, setError] = useState("");
  const [token, setToken] = useState("");
  const [tenant, setTenant] = useState(null);
  const [showValidation, setShowValidation] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tokenParam = params.get("token");
    const tenantIdParam = params.get("tenantId");

    if (!tokenParam || !tenantIdParam) {
      navigate("/404");
    } else {
      setToken(tokenParam);

      getTenantById(tenantIdParam)
        .then((tenantData) => {
          setTenant(tenantData);
        })
        .catch((error) => {
          setError("Failed to fetch tenant data.");
          console.error("Error fetching tenant:", error);
        });
    }
  }, [location.search, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValid()) {
      const userData = {
        email: contactEmail,
        name,
        password,
        role: "owner",
        tenant: tenant.subdomain,
      };

      try {
        const userResponse = await createUser(userData);
        if (userResponse) {
          navigate(`/registration-success?subdomain=${tenant.subdomain}`);
        }
      } catch (userError) {
        setError(userError.message || "User creation failed.");
      }
    } else {
      setError("Please ensure passwords match and meet the strength criteria.");
      setShowValidation(true);
    }
  };

  const isValid = () => {
    return (
      name.trim() &&
      contactEmail.trim() &&
      password.trim() &&
      verifyPassword.trim() &&
      password === verifyPassword &&
      passwordValid
    );
  };

  const flavorText = (
    <MDBox mt={3} mb={3} mx={5} textAlign="center">
      <MDTypography variant="body1">
        Welcome to SeriouslyDS! Complete your registration to start using your
        account.
      </MDTypography>
      <MDTypography variant="body1" mt={2}>
        Fill out the information below to complete your account setup.
      </MDTypography>
    </MDBox>
  );

  const steps = ["Basic Details", "Additional Setup"];

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            {flavorText}
            <MDTypography variant="subtitle2">
              Fields marked with an asterisk (*) are required.
            </MDTypography>
            <BasicDetails
              name={name}
              setName={setName}
              password={password}
              setPassword={setPassword}
              verifyPassword={verifyPassword}
              setVerifyPassword={setVerifyPassword}
              contactEmail={contactEmail}
              setContactEmail={setContactEmail}
              showValidation={showValidation}
              setPasswordValid={setPasswordValid}
              userRegistration={true}
            />
          </>
        );
      case 1:
        return null; // Placeholder for future steps
      default:
        return "Unknown step";
    }
  };

  return (
    <MDBox mt={5} mb={9}>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={8}>
          <MDBox mt={6} mb={8} textAlign="center">
            <MDBox mb={1}>
              <MDTypography variant="h3" fontWeight="bold">
                Complete Your Registration
              </MDTypography>
            </MDBox>
            <MDTypography variant="h5" fontWeight="regular" color="secondary">
              Fill out the information below to complete your registration.
            </MDTypography>
          </MDBox>
          <Card>
            <MDBox mt={-3} mb={3} mx={2}>
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                sx={{ background: "#344767 !important" }}
              >
                {steps.map((label, index) => (
                  <Step key={index}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </MDBox>
            <MDBox p={2}>
              {getStepContent(activeStep)}
              {error && (
                <MDTypography variant="body2" color="error" mt={2}>
                  {error}
                </MDTypography>
              )}
              {showValidation && (
                <Validation
                  fields={[
                    { name: "User Name", value: name },
                    { name: "Contact Email", value: contactEmail },
                    { name: "Password", value: password },
                  ]}
                />
              )}
              <MDBox mt={3} display="flex" justifyContent="space-between">
                <MDBox />
                <MDButton
                  variant="contained"
                  color="dark"
                  onClick={handleSubmit}
                >
                  Register
                </MDButton>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default RegisterUser;
