import React, { useState, useRef, useEffect } from "react";
import DashboardLayout from "shared/components/UIElements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/components/Navigation/DashboardNavbar";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import ManageSubscription from "../components/ManageSubscription";
import ProfileInfoCard from "shared/components/UIElements/InfoCards/ProfileInfoCard";
import MDButton from "shared/components/MDComponents/MDButton";

const MyAccountPage = () => {
  const { user } = useSelector((state) => state.user);
  const [logo, setLogo] = useState(null);
  const imgRef = useRef(null);
  const [overlayWidth, setOverlayWidth] = useState(0);

  const updateOverlayWidth = () => {
    const img = imgRef.current;
    if (img && img.offsetWidth && img.offsetHeight) {
      const ratio = img.naturalWidth / img.naturalHeight;
      const height = img.offsetHeight;
      const computedWidth = Math.min(img.offsetWidth, height * ratio);
      setOverlayWidth(computedWidth);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateOverlayWidth);
    return () => window.removeEventListener("resize", updateOverlayWidth);
  }, []);

  useEffect(() => {
    updateOverlayWidth(); // Initial update when image loads
  }, [logo]);

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (upload) => {
        setLogo(upload.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      {/* <div
        style={{
          position: "relative",
          width: "100%",
          overflow: "hidden",
          maxHeight: "300px",
        }}
      >
        <img
          ref={imgRef}
          src={
            logo ||
            "https://via.placeholder.com/700x300?text=Upload+Your+App+Logo"
          }
          onLoad={updateOverlayWidth}
          style={{
            width: "100%",
            maxHeight: "300px",
            objectFit: "contain",
            objectPosition: "left center",
          }}
          alt="User Logo"
        />
        <MDBox
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            background: "rgba(0, 0, 0, 0.5)",
            padding: "16px",
            width: `${overlayWidth}px`,
            textAlign: "left",
          }}
        >
          <MDTypography variant="h4" color="white">
            {user?.name || "User Name"}
          </MDTypography>
        </MDBox>
      </div>
      <MDBox mt={3} display="flex" justifyContent="left">
        <MDButton component="label" variant="contained" color="dark">
          Upload Logo
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={handleLogoUpload}
          />
        </MDButton>
      </MDBox> */}
      <MDBox mt={3} mb={3}>
        <Grid container spacing={1}>
          {/* User Info Section */}
          <Grid item xs={12} md={4}>
            <ProfileInfoCard
              title="User Information"
              description="Details about your account"
              info={{
                Name: user?.name || "N/A",
                Email: user?.email || "N/A",
                Role: user?.role || "N/A",
              }}
              shadow={false}
            />
          </Grid>

          {/* Subscription Management Section */}
          <Grid item xs={12} md={4}>
            <MDBox>
              <ProfileInfoCard
                title="Subscription Management"
                description="Manage your subscription details directly with Stripe"
                info={{
                  "Subscription Plan": user?.subscriptionPlan || "Basic",
                }}
                shadow={false}
              >
                <MDBox mt={2}>
                  <ManageSubscription />
                </MDBox>
              </ProfileInfoCard>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default MyAccountPage;
