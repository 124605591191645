const API_BASE_URL = process.env.REACT_APP_API_URL;

export const fetchFAQs = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/faqs`, {});
    if (!response.ok) {
      throw new Error("Failed to fetch FAQs.");
    }
    const data = await response.json();
    return data.faqs;
  } catch (error) {
    console.error("Error fetching FAQs:", error);
    throw error;
  }
};
