import { Box } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { retryCheckoutSession } from "services/StripeService";
import MDButton from "shared/components/MDComponents/MDButton";
import MDTypography from "shared/components/MDComponents/MDTypography";

const PaymentCancelled = () => {
  const location = useLocation();

  // Get the necessary parameters from the URL
  const params = new URLSearchParams(location.search);
  const customerId = params.get("customerId");
  const subscriptionId = params.get("subscriptionId");
  const tenantId = params.get("tenantId");
  const screens = params.get("screens");
  const pricePerScreen = params.get("pricePerScreen");
  const billingCycle = params.get("billingCycle");

  // Make sure required params exist
  if (!customerId || !subscriptionId || !tenantId) {
    console.error("Missing required parameters.");
    return <div>Error: Missing required parameters.</div>;
  }

  const handleRetryPayment = async () => {
    try {
      // Call the retryCheckoutSession function from the service
      const session = await retryCheckoutSession(
        customerId,
        subscriptionId,
        tenantId,
        billingCycle || "monthly",
        screens || 5,
        pricePerScreen || 15
      );

      // Redirect to the newly created checkout session
      window.location.href = session.url;
    } catch (error) {
      console.error("Error retrying payment:", error);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      textAlign="center"
    >
      <MDTypography variant="h3" fontWeight="bold">
        Payment Canceled
      </MDTypography>
      <MDTypography variant="body1" mt={2} mb={3}>
        It looks like your payment was canceled. If this was a mistake, you can
        try again below.
      </MDTypography>
      <MDButton variant="contained" color="dark" onClick={handleRetryPayment}>
        Return to Checkout
      </MDButton>
    </Box>
  );
};

export default PaymentCancelled;
