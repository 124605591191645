import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import MDButton from "shared/components/MDComponents/MDButton";

const ConfirmDeleteDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  description,
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{description}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <MDButton onClick={onConfirm} color="error" variant="contained">
        Delete
      </MDButton>
      <MDButton onClick={onClose} color="dark" variant="contained">
        Cancel
      </MDButton>
    </DialogActions>
  </Dialog>
);

export default ConfirmDeleteDialog;
