import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkSubdomain } from "services/TenantService";

export function useSubdomainValidation() {
  const [subdomainValid, setSubdomainValid] = useState(null);
  const [subdomainChecked, setSubdomainChecked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split(".")[0];

    const validateSubdomain = async () => {
      try {
        const { exists } = await checkSubdomain(subdomain);
        setSubdomainValid(exists);
      } catch (error) {
        console.error("Error checking subdomain:", error);
        setSubdomainValid(false); // Fallback if an error occurs
      } finally {
        setSubdomainChecked(true);
      }
    };

    validateSubdomain();
  }, []);

  useEffect(() => {
    // Navigate to "Domain Not Found" only if the check is done and subdomain does not exist
    if (subdomainChecked && subdomainValid === false) {
      navigate("/domain-not-found");
    }
  }, [subdomainValid, subdomainChecked, navigate]);

  return { subdomainValid, subdomainChecked };
}
