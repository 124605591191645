import React from "react";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  TopToolbar,
  CreateButton,
  ExportButton,
  FilterButton,
  TextInput,
  DateField,
  NumberField,
  SelectInput,
} from "react-admin";
import ThreeDotsMenu from "../users/ThreeDotsMenu";
import { Box } from "@mui/material";
import { differenceInDays, parseISO } from "date-fns";

const statusColors = {
  active: "green",
  pending: "#ffc107",
  suspended: "#ff4500",
  past_due: "red",
  canceled: "grey",
  expired: "grey",
};

// Function to render the colored bubble based on status
const StatusBubble = ({ record }) => {
  const status = record.status;
  const color = statusColors[status] || "grey";

  return (
    <Box
      component="span"
      sx={{
        backgroundColor: color,
        color: "white",
        padding: "4px 8px",
        borderRadius: "12px",
        fontSize: "0.875rem",
        textTransform: "capitalize",
      }}
    >
      {status}
    </Box>
  );
};

const statusChoices = [
  { id: "active", name: "Active" },
  { id: "pending", name: "Pending" },
  { id: "incomplete", name: "Incomplete" },
  { id: "unpaid", name: "Unpaid" },
  { id: "suspended", name: "Suspended" },
  { id: "canceled", name: "Canceled" },
  { id: "expired", name: "Expired" },
  { id: "past_due", name: "Past Due" },
  { id: "terminated", name: "Terminated" },
];

const SubscriptionFilters = [
  <TextInput label="Search by tenant name" source="q" alwaysOn />,
  <SelectInput
    label="Search by status"
    source="status"
    choices={statusChoices}
  />,
];

const SubscriptionListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const SubscriptionList = (props) => (
  <List
    {...props}
    filters={SubscriptionFilters}
    actions={<SubscriptionListActions />}
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <FunctionField
        label="Customer"
        render={(record) => record.tenant?.organizationName || "N/A"}
      />
      <TextField source="subscriptionPlan" label="Plan" />
      <NumberField source="screens" label="Number of Screens" />
      <FunctionField
        label="Price per Screen"
        render={(record) => `$${record.pricePerScreen.toFixed(2)}`}
      />
      <TextField source="billingCycle" label="Billing Cycle" />
      <FunctionField
        label="Status"
        render={(record) => <StatusBubble record={record} />}
      />
      <DateField source="startDate" label="Start Date" />
      <DateField source="endDate" label="End Date" />
      <DateField source="pastDueDate" label="Past Due Date" />
      <FunctionField
        label="Days Past Due"
        render={(record) => {
          if (record.pastDueDate) {
            const daysPastDue = differenceInDays(
              new Date(),
              parseISO(record.pastDueDate)
            );
            return daysPastDue > 0 ? daysPastDue : 0;
          }
          return "";
        }}
      />
      <ThreeDotsMenu />
    </Datagrid>
  </List>
);
