import React from "react";
import { MenuItem, Select, Checkbox, ListItemText } from "@mui/material";
import { useSelector } from "react-redux";

const VenueDropdown = ({ user, currentVenues, venues, onChange }) => {
  const { user: currentUser } = useSelector((state) => state.user);

  const isAdminEditingSelf =
    currentUser.role === "manager" && user._id === currentUser._id;

  const handleVenueChange = (event) => {
    const {
      target: { value },
    } = event;
    onChange(user, value);
  };

  const filteredVenues =
    currentUser.role === "manager"
      ? venues.filter((venue) =>
          currentUser.venues.some((v) => v.toString() === venue._id.toString())
        )
      : venues;

  if (user.role === "owner" || user.role === "superadmin") {
    return <span>All Venues</span>;
  }

  return (
    <Select
      multiple
      value={currentVenues ? currentVenues.map((venue) => venue._id) : []}
      onChange={handleVenueChange}
      renderValue={(selected) =>
        venues
          .filter((venue) => selected.includes(venue._id))
          .map((venue) => venue.title)
          .join(", ")
      }
      fullWidth
      disabled={isAdminEditingSelf}
      sx={{
        height: 40,
        width: 200,
        textAlign: "center",
        fontSize: "0.875rem",
        "& .MuiSelect-select": {
          paddingTop: "10px",
          paddingBottom: "10px",
        },
      }}
    >
      {filteredVenues.map((venue) => (
        <MenuItem key={venue._id} value={venue._id}>
          <Checkbox checked={currentVenues.some((v) => v._id === venue._id)} />
          <ListItemText primary={venue.title} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default VenueDropdown;
