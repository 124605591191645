import React, { useEffect, useState } from "react";
import "./ScreenStream.css";
import { Box } from "@mui/material";
import logo from "assets/images/logos/sds-logo.png";

const ScreenStreamSlideshow = ({ screenId, slideshow, preview }) => {
  const [mediaList, setMediaList] = useState([]);
  const [fadeIn, setFadeIn] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    if (
      slideshow &&
      Array.isArray(slideshow.mediaList) &&
      slideshow.mediaList.length > 0
    ) {
      const now = new Date();
      const startDate = new Date(slideshow.startDate);
      const endDate = slideshow.endDate ? new Date(slideshow.endDate) : null;

      // Check if the slideshow is within the active date range
      if (
        now >= startDate &&
        (slideshow.runIndefinitely || (endDate && now <= endDate))
      ) {
        setMediaList(slideshow.mediaList);
        preloadImages(slideshow.mediaList);
      } else {
        console.log("Slideshow is not active or out of date range.");
        setMediaList([]);
        setIsLoading(false);
      }
    } else {
      console.log("Invalid or missing slideshow data.");
      setMediaList([]);
      setIsLoading(false);
    }
  }, [slideshow]);

  const preloadImages = (mediaList) => {
    if (!mediaList || !Array.isArray(mediaList) || mediaList.length === 0) {
      console.log("No media to preload.");
      setIsLoading(false);
      return;
    }

    // Preload images and handle loading state
    const imageUrls = mediaList
      .map((item) => item?.media?.signedUrl)
      .filter(Boolean); // Ensure signedUrl exists
    const loadedImages = imageUrls.map((url) => {
      const img = new Image();
      img.src = url;
      return new Promise((resolve) => (img.onload = resolve));
    });

    Promise.all(loadedImages)
      .then(() => {
        setImagesLoaded(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error preloading images: ", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (imagesLoaded && mediaList.length > 1) {
      const duration = (mediaList[currentIndex]?.duration || 5) * 1000; // Convert seconds to milliseconds
      const timeoutId = setTimeout(() => {
        setFadeIn(false); // Start by fading out the current image
        setTimeout(() => {
          const nextIndex = (currentIndex + 1) % mediaList.length; // Calculate the next image index
          setCurrentIndex(nextIndex);
          setFadeIn(true); // After the image is updated, start fading in
        }, 1000); // Delay to allow fade out before changing images
      }, duration);

      return () => clearTimeout(timeoutId);
    }
  }, [currentIndex, mediaList.length, imagesLoaded]);

  const getImageStyle = (fit) => {
    switch (fit) {
      case "fit":
        return {
          maxWidth: "100%",
          maxHeight: "100%",
          objectFit: "contain",
        };
      case "stretch":
        return {
          width: "100%",
          height: "100%",
          objectFit: "fill",
        };
      case "center":
        return {
          maxWidth: "100%",
          maxHeight: "100%",
          objectFit: "none",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        };
      case "none":
      default:
        return {
          height: "100vh",
          width: "100vw",
          objectFit: "cover",
        };
    }
  };

  return (
    <Box className="fade-container" sx={{ backgroundColor: "black" }}>
      {mediaList.length > 0 ? (
        <img
          src={mediaList[currentIndex]?.media?.signedUrl || logo}
          alt="Current Media"
          className={`stream-image ${fadeIn ? "fade-in" : "fade-out"}`}
          style={getImageStyle(slideshow.screenFit)}
        />
      ) : (
        <img
          src={logo}
          alt="No Media"
          className="dummy-image"
          style={{
            maxWidth: "30%",
            maxHeight: "30%",
            objectFit: "contain",
            position: "absolute",
          }}
        />
      )}
    </Box>
  );
};

export default ScreenStreamSlideshow;
