const API_BASE_URL = process.env.REACT_APP_API_URL;

export const fetchVenues = async (subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/venue`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Subdomain": subdomain,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch venues.");
    }
    const data = await response.json();
    return data.venues;
  } catch (error) {
    console.error("Error fetching venues:", error);
    throw error;
  }
};

export const fetchVenueById = async (venueId, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/venue/${venueId}`, {
      headers: {
        "X-Subdomain": subdomain,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch venue.");
    }
    const data = await response.json();
    return data.venue;
  } catch (error) {
    console.error("Error fetching venue:", error);
    throw error;
  }
};

export const deleteVenue = async (venueId, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/venue/${venueId}`, {
      method: "DELETE",
      headers: {
        "X-Subdomain": subdomain,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to delete venue.");
    }
    return response;
  } catch (error) {
    console.error("Error deleting venue:", error);
    throw error;
  }
};

export const createVenue = async (venueData, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/venue`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Subdomain": subdomain,
      },
      body: JSON.stringify(venueData),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to create new venue.");
    }
    const createdVenue = await response.json();
    return createdVenue.venue;
  } catch (error) {
    console.error("Error creating new venue:", error);
    throw error;
  }
};

export const updateVenueBackgroundImage = async (
  venueId,
  mediaId,
  subdomain
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/venue/background-image/${venueId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "X-Subdomain": subdomain,
        },
        body: JSON.stringify({ backgroundImage: mediaId }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update venue background image.");
    }
  } catch (error) {
    console.error("Error updating venue background image:", error);
    throw error;
  }
};

export const updateVenue = async (venueId, updatedVenueData, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/venue/${venueId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-Subdomain": subdomain,
      },
      body: JSON.stringify(updatedVenueData),
    });

    if (!response.ok) {
      throw new Error("Failed to update venue");
    }

    return await response.json();
  } catch (error) {
    console.error("Error updating venue:", error);
    throw error;
  }
};
