import React, { useEffect, useState } from "react";
import "./ScreenStream.css";
import { Box } from "@mui/material";
import Banner from "shared/components/UIElements/Banner/Banner";
import logo from "assets/images/logos/sds-logo.png";

const ScreenStreamSchedule = ({ screenId, schedule, preview }) => {
  const [showings, setShowings] = useState([]);
  const [fadeIn, setFadeIn] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    if (schedule && Array.isArray(schedule.showings)) {
      setShowings(schedule.showings);
      preloadImages(schedule.showings);
    } else {
      console.log("No valid schedule or showings to process.");
      setShowings([]);
    }
  }, [schedule]);

  const preloadImages = (showings) => {
    if (!showings || !Array.isArray(showings)) {
      console.log("No showings available to preload images.");
      setIsLoading(false);
      return;
    }

    const imageUrls = showings
      .map((showing) => showing?.signedImageUrl)
      .filter(Boolean);
    const loadedImages = imageUrls.map((url) => {
      const img = new Image();
      img.src = url;
      return new Promise((resolve) => (img.onload = resolve));
    });

    Promise.all(loadedImages)
      .then(() => {
        setImagesLoaded(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error loading images: ", error);
        setIsLoading(false);
      });
  };

  const getCurrentTime = () => {
    const now = new Date();
    return now.toTimeString().split(" ")[0]; // Get HH:MM:SS format
  };

  const getCurrentDate = () => {
    return new Date().toLocaleDateString("en-US");
  };

  const convertTo24Hour = (time) => {
    const [timePart, modifier] = time.split(" ");
    let [hours, minutes] = timePart.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}:00`; // Assuming seconds are 00
  };

  const adjustForEndOfDay = (time) => {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    if (hours < 3) {
      return `${hours + 24}:${minutes}:${seconds}`;
    }
    return time;
  };

  const checkCurrentShowing = () => {
    const now = new Date();
    const currentDate = now.toLocaleDateString("en-US");

    if (schedule.daysOfWeek.includes(currentDate)) {
      const currentTime = adjustForEndOfDay(getCurrentTime());
      for (let i = 0; i < showings.length; i++) {
        const { startTime, endTime } = showings[i];
        const start = adjustForEndOfDay(convertTo24Hour(startTime));
        const end = adjustForEndOfDay(convertTo24Hour(endTime));

        if (currentTime >= start && currentTime <= end) {
          return i;
        }
      }
    }
    return -1; // Return -1 if no valid showing is found
  };

  const findNextShowingIndex = () => {
    const currentDate = getCurrentDate();
    if (!schedule.daysOfWeek.includes(currentDate)) {
      return -1; // If current date is not in daysOfWeek, return -1
    }
    const currentTime = adjustForEndOfDay(getCurrentTime());
    let smallestDiff = Number.MAX_SAFE_INTEGER;
    let nextIndex = -1;
    for (let i = 0; i < showings.length; i++) {
      const start = adjustForEndOfDay(convertTo24Hour(showings[i].startTime));
      const diff =
        new Date(`1970/01/01 ${start}`) - new Date(`1970/01/01 ${currentTime}`);
      if (diff > 0 && diff < smallestDiff) {
        smallestDiff = diff;
        nextIndex = i;
      }
    }
    return nextIndex;
  };

  const formatName = (name) => {
    // Remove file extension and capitalize
    const baseName = name.replace(/\.[^/.]+$/, "");
    return baseName.charAt(0).toUpperCase() + baseName.slice(1);
  };

  useEffect(() => {
    if (imagesLoaded && showings.length > 0) {
      const interval = setInterval(() => {
        const newIndex = checkCurrentShowing();
        if (newIndex !== -1) {
          if (currentIndex !== newIndex) {
            setCurrentIndex(newIndex);
            setFadeIn(true);
          }
        } else {
          setFadeIn(false);
          setCurrentIndex(-1); // No valid showing
        }
      }, 1000); // Check every second

      return () => clearInterval(interval);
    }
  }, [showings.length, imagesLoaded]);

  const getImageStyle = (fit) => {
    switch (fit) {
      case "fit":
        return {
          maxWidth: "100%",
          maxHeight: "100%",
          objectFit: "contain",
          imageRendering: "auto",
        };
      case "stretch":
        return {
          width: "100%",
          height: "100%",
          objectFit: "fill",
          imageRendering: "auto",
        };
      case "center":
        return {
          maxWidth: "100%",
          maxHeight: "100%",
          objectFit: "none",
          imageRendering: "auto",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        };
      case "none":
      default:
        return {
          height: "100vh",
          width: "100vw",
          objectFit: "cover",
          imageRendering: "auto",
        };
    }
  };

  const currentShowing = currentIndex !== -1 ? showings[currentIndex] : null;
  const nextShowingIndex =
    currentIndex !== -1 && currentIndex + 1 < showings.length
      ? currentIndex + 1
      : findNextShowingIndex();
  const nextShowing =
    nextShowingIndex !== -1 && nextShowingIndex < showings.length
      ? showings[nextShowingIndex]
      : null;

  return (
    <Box
      className="fade-container"
      sx={{ backgroundColor: "black", position: "relative" }}
    >
      {currentShowing ? (
        <img
          src={currentShowing?.signedImageUrl || logo}
          alt="Current Showing"
          className={`stream-image ${fadeIn ? "fade-in" : "fade-out"}`}
          style={getImageStyle(schedule.screenFit)}
        />
      ) : nextShowing ? (
        <Banner
          position="center"
          text={`Up Next: ${formatName(nextShowing.name)} at ${
            nextShowing.startTime
          }`}
          imageUrl={nextShowing.signedImageUrl}
          enlarge
        />
      ) : (
        <img
          src={logo}
          alt="No Showings"
          className="dummy-image"
          style={{
            maxWidth: "30%",
            maxHeight: "30%",
            objectFit: "contain",
            position: "absolute",
          }}
        />
      )}
      {schedule.banner && currentShowing && (
        <Banner
          position="top"
          text={`Now Playing: ${formatName(currentShowing.name)}`}
          justifyContent="flex-start"
        />
      )}
      {schedule.banner && nextShowing && currentShowing && (
        <Banner
          position="bottom"
          text={`Up Next: ${formatName(nextShowing.name)} at ${
            nextShowing.startTime
          }`}
          imageUrl={nextShowing.signedImageUrl}
          justifyContent="flex-end"
        />
      )}
    </Box>
  );
};

export default ScreenStreamSchedule;
