import { useEffect, useRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Dropzone components
import Dropzone from "dropzone";

// Dropzone styles
import "dropzone/dist/dropzone.css";

// Material Dashboard 2 PRO React components
import MDBox from "../MDBox";

// Custom styles for the MDDropzone
import MDDropzoneRoot from "./MDDropzoneRoot";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "../../../../context/ComponentContext";

function MDDropzone({ options }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const dropzoneRef = useRef(null);

  useEffect(() => {
    Dropzone.autoDiscover = false;

    let dropzoneInstance = null;

    function createDropzone() {
      if (dropzoneRef.current) {
        dropzoneInstance = new Dropzone(dropzoneRef.current, { ...options });
      }
    }

    function removeDropzone() {
      if (dropzoneInstance) {
        dropzoneInstance.destroy();
        dropzoneInstance = null;
      }
    }

    createDropzone();

    return () => removeDropzone();
  }, [options]);

  return (
    <MDDropzoneRoot
      component="form"
      action="/file-upload"
      ref={dropzoneRef}
      className="form-control dropzone"
      ownerState={{ darkMode }}
    >
      <MDBox className="fallback" bgColor="transparent">
        <MDBox component="input" name="file" type="file" multiple />
      </MDBox>
    </MDDropzoneRoot>
  );
}

// Typechecking props for the MDDropzone
MDDropzone.propTypes = {
  options: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default MDDropzone;
