import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import useDebounce from "shared/hooks/useDebounce";
import { fetchUserByEmail } from "services/UserService";

const EmailAndUsername = ({ contactEmail, setContactEmail, name, setName }) => {
  const [emailError, setEmailError] = useState("");
  const debouncedEmail = useDebounce(contactEmail, 500);

  useEffect(() => {
    if (debouncedEmail) {
      setEmailError(""); // Clear error immediately on input change
      fetchUserByEmail(debouncedEmail)
        .then((res) => {
          if (res) {
            setEmailError("This email is already in use. Please use another.");
          }
        })
        .catch((err) => {
          console.error("Error fetching user by email:", err);
        });
    }
  }, [debouncedEmail]);

  useEffect(() => {
    if (debouncedEmail) {
      const generatedName = debouncedEmail.split("@")[0];
      setName(generatedName); // Auto-fill the name field based on email
    }
  }, [debouncedEmail, setName]);

  return (
    <>
      <Grid item xs={6}>
        <TextField
          label="Contact Email"
          value={contactEmail}
          onChange={(e) => setContactEmail(e.target.value)}
          fullWidth
          required
          margin="normal"
          helperText={emailError || "This will be used when signing in."}
          error={Boolean(emailError)}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="User Name"
          value={name}
          onChange={(e) => setName(e.target.value)} // User can manually change the name if desired
          fullWidth
          required
          margin="normal"
          helperText="This will be your visible name in the app."
        />
      </Grid>
    </>
  );
};

export default EmailAndUsername;
