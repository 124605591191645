import React from "react";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const DeleteButton = ({ userId, onDelete }) => {
  return (
    <IconButton onClick={() => onDelete(userId)}>
      <DeleteIcon />
    </IconButton>
  );
};

export default DeleteButton;
