import React from "react";
import {
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Box,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MDTypography from "shared/components/MDComponents/MDTypography";

const ScreenFitOption = ({
  changeScreenFit,
  setChangeScreenFit,
  screenFit,
  setScreenFit,
}) => {
  const handleScreenFitChange = (e) => {
    setScreenFit(e.target.value);
  };

  const handleChangeScreenFitToggle = (e) => {
    setChangeScreenFit(e.target.checked);
    if (!e.target.checked) {
      setScreenFit("none"); // Set to "none" when the toggle is off
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <FormControlLabel
        control={
          <Switch
            checked={changeScreenFit}
            onChange={handleChangeScreenFitToggle}
            color="primary"
          />
        }
        label={
          <MDTypography style={{ color: changeScreenFit ? "inherit" : "grey" }}>
            Change Screen Fit
          </MDTypography>
        }
        sx={{ marginBottom: "20px", marginRight: "10px" }}
      />
      <Select
        value={screenFit}
        onChange={handleScreenFitChange}
        displayEmpty
        disabled={!changeScreenFit}
        sx={{
          minWidth: 150,
          padding: "5px",
          alignSelf: "flex-start",
          ml: 1,
        }}
      >
        <MenuItem value="none">
          <em>None</em>
        </MenuItem>
        <MenuItem value="fit">Fit to Screen</MenuItem>
        <MenuItem value="stretch">Stretch to Screen</MenuItem>
        <MenuItem value="center">Center on Screen</MenuItem>
      </Select>
      <Tooltip
        sx={{ alignSelf: "flex-start" }}
        title="Enable this option to adjust how the content fits on the screen. 'None' will keep the media's original resolution, 'Fit to Screen' will scale the media to fit within the screen, 'Stretch to Screen' will stretch the media to cover the entire screen, and 'Center on Screen' will center the media without scaling."
      >
        <IconButton>
          <HelpOutlineIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default ScreenFitOption;
