import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Thunk to fetch admin data (users and tenants)
export const fetchAdminData = createAsyncThunk(
  "admin/fetchAdminData",
  async (_, { getState, rejectWithValue }) => {
    try {
      // Get the subdomain from the Redux store
      const state = getState();
      const subdomain = state.subdomain.value;

      const headers = {
        "Content-Type": "application/json",
        "X-Subdomain": subdomain, // Add the X-Subdomain header
      };

      const usersResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/user`,
        { headers }
      );
      const tenantsResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/tenant`,
        { headers }
      );

      if (!usersResponse.ok || !tenantsResponse.ok) {
        throw new Error("Failed to fetch admin data");
      }

      const usersData = await usersResponse.json();
      const tenantsData = await tenantsResponse.json();

      return {
        users: usersData.users,
        tenants: tenantsData.tenants,
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Initial state
const initialState = {
  users: [],
  tenants: [],
  status: "idle",
  error: null,
};

// Admin slice
const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAdminData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.users = action.payload.users;
        state.tenants = action.payload.tenants;
      })
      .addCase(fetchAdminData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default adminSlice.reducer;
