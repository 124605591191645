import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Card, CircularProgress, Grid } from "@mui/material";
import MDBox from "shared/components/MDComponents/MDBox";
import MDButton from "shared/components/MDComponents/MDButton";
import MDTypography from "shared/components/MDComponents/MDTypography";
import MediaFilterControls from "./MediaFilterControls";
import FolderList from "features/folder/components/FolderList";
import MediaList from "./MediaList";
import { fetchFolders } from "services/FolderService";

const MediaDirectory = ({
  mediaType,
  selectedItemId,
  selectedFolderName,
  onMediaClick,
}) => {
  const [mediaData, setMediaData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [spacing, setSpacing] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const selectedVenue = useSelector((state) => state.venue.selectedVenue);
  const subdomain = useSelector((state) => state.subdomain.value);

  useEffect(() => {
    if (selectedVenue) {
      fetchDataForVenue(selectedVenue.id);
    }
  }, [selectedVenue]);

  useEffect(() => {
    if (selectedFolderName) {
      const folder = folders.find(
        (folder) => folder.name === selectedFolderName
      );
      if (folder) {
        setSelectedFolder(folder);
      }
    }
  }, [selectedFolderName, folders]);

  const fetchDataForVenue = async (venueId) => {
    setIsLoading(true);
    try {
      const folders = await fetchFolders(venueId, subdomain);

      // Filter media items based on mediaType
      const filteredFolders = folders.map((folder) => {
        const filteredMediaItems = folder.mediaItems.filter((mediaItem) => {
          const extension = mediaItem.url.split(".").pop().toLowerCase();
          if (mediaType === "images") {
            return ["png", "jpeg", "jpg", "webp", "pdf"].includes(extension);
          } else if (mediaType === "videos") {
            return ["mp4", "mpeg", "mov", "mkv"].includes(extension);
          }
          return true; // For main media, allow all types
        });
        return { ...folder, mediaItems: filteredMediaItems };
      });

      setFolders(filteredFolders);
      const allMedia = filteredFolders.flatMap((folder) => folder.mediaItems);
      setMediaData(allMedia);

      setSelectedFolder(null);
      setErrorMessage("");
    } catch (error) {
      setErrorMessage("Network error or other issue while fetching data");
    } finally {
      setIsLoading(false);
    }
  };

  const handleFolderClick = (folder) => {
    setSelectedFolder(folder);
  };

  const handleBackClick = () => {
    setSelectedFolder(null);
  };

  const handleRenameFolder = (folderId, newName) => {
    setFolders((prevFolders) =>
      prevFolders.map((folder) =>
        folder.id === folderId ? { ...folder, name: newName } : folder
      )
    );
  };

  const handleDeleteFolder = (folderId, newFolderId) => {
    setFolders((prevFolders) =>
      prevFolders.filter((folder) => folder.id !== folderId)
    );
    setMediaData((prevMediaData) =>
      prevMediaData.map((media) =>
        media.folder === folderId ? { ...media, folder: newFolderId } : media
      )
    );
  };

  const filteredAndSortedMultimedia = mediaData
    .filter((media) =>
      media.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .filter((media) => !selectedFolder || media.folder === selectedFolder.id)
    .sort((a, b) => {
      if (sortOption === "name-az") {
        return a.name.localeCompare(b.name);
      } else if (sortOption === "name-za") {
        return b.name.localeCompare(a.name);
      }
      return 0;
    });

  if (isLoading) {
    return (
      <MDBox
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(80vh - 64px)",
        }}
      >
        <CircularProgress />
      </MDBox>
    );
  }

  return (
    <MDBox py={3}>
      <Card
        sx={{
          padding: 2,
          maxWidth: "800px",
        }}
      >
        <MediaFilterControls
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          sortOption={sortOption}
          setSortOption={setSortOption}
          selectedFolder={selectedFolder}
          setSelectedFolder={setSelectedFolder}
          folders={folders}
          spacing={spacing}
          setSpacing={setSpacing}
          viewingFolders={!selectedFolder} // Pass the viewing state
        />
      </Card>
      <MDBox p={2} sx={{ mt: 2 }}>
        {selectedFolder && (
          <MDButton variant="contained" color="dark" onClick={handleBackClick}>
            Back
          </MDButton>
        )}
      </MDBox>
      <MDBox p={2}>
        {!selectedFolder && folders.length > 0 && (
          <Grid container spacing={Math.max(1, Math.floor(spacing / 10))}>
            <FolderList
              folders={folders}
              spacing={spacing}
              onFolderClick={handleFolderClick}
              onRename={handleRenameFolder}
              onDelete={handleDeleteFolder}
            />
          </Grid>
        )}

        {selectedFolder && filteredAndSortedMultimedia.length > 0 ? (
          <Grid container spacing={Math.max(1, Math.floor(spacing / 10))}>
            {filteredAndSortedMultimedia.map((media) => (
              <Grid item key={media.id}>
                <MediaList
                  item={media}
                  spacing={spacing}
                  selectedFolder={selectedFolder}
                  selectedItemId={selectedItemId}
                  folders={folders}
                  onMediaClick={onMediaClick}
                />
              </Grid>
            ))}
          </Grid>
        ) : selectedFolder ? (
          <MDBox
            mt={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "800px",
            }}
          >
            <MDTypography variant="h5" color="text">
              No media found for this folder.
            </MDTypography>
            <MDBox mt={2}>
              <MDButton
                variant="contained"
                color="dark"
                onClick={() => navigate(`/newmedia`)}
              >
                Add Media
              </MDButton>
            </MDBox>
          </MDBox>
        ) : (
          !folders && (
            <MDBox
              mt={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: "800px",
              }}
            >
              <MDTypography variant="h5" color="text">
                {errorMessage}
              </MDTypography>
              <MDBox mt={2}>
                <MDButton
                  variant="contained"
                  color="dark"
                  onClick={() => navigate(`/newmedia`)}
                >
                  Add Media
                </MDButton>
              </MDBox>
            </MDBox>
          )
        )}
      </MDBox>
    </MDBox>
  );
};

export default MediaDirectory;
