import React from "react";
import { Grid } from "@mui/material";
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  useDataProvider,
  useNotify,
  useRedirect,
} from "react-admin";
import CustomToolbar from "../../utils/CustomToolbar";

export const TenantEdit = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit = async (values) => {
    try {
      const {
        subdomain,
        organizationName,
        contactEmail,
        contactPhone,
        status,
        address,
      } = values;

      const formData = {
        subdomain,
        organizationName,
        contactEmail,
        contactPhone,
        status,
        address: {
          street: address?.street || "",
          city: address?.city || "",
          state: address?.state || "",
          zipCode: address?.zipCode || "",
          country: address?.country || "",
        },
      };

      // Call the update API
      await dataProvider.update("tenant", {
        id: values.id,
        data: formData,
      });

      notify("Tenant updated successfully", { type: "info" });
      redirect("list", "tenant");
    } catch (error) {
      notify(`Error updating tenant: ${error.message}`, { type: "error" });
    }
  };

  const statusChoices = [
    { id: "active", name: "Active" },
    { id: "inactive", name: "Inactive" },
  ];

  return (
    <Edit {...props} resource="tenant">
      <SimpleForm
        toolbar={<CustomToolbar resource="tenant" />}
        onSubmit={handleSubmit} // Attach the custom handleSubmit
        redirect="list"
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextInput source="organizationName" label="Organization Name" />
          </Grid>

          <Grid item xs={6}>
            <TextInput source="contactEmail" label="Contact Email" />
          </Grid>
          <Grid item xs={6}>
            <TextInput source="contactPhone" label="Contact Phone" />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              source="status"
              choices={statusChoices}
              fullWidth
              label="Service"
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput source="address.street" label="Street" />
          </Grid>
          <Grid item xs={6}>
            <TextInput source="address.city" label="City" />
          </Grid>
          <Grid item xs={6}>
            <TextInput source="address.state" label="State" />
          </Grid>
          <Grid item xs={6}>
            <TextInput source="address.zipCode" label="Zip Code" />
          </Grid>
          <Grid item xs={6}>
            <TextInput source="address.country" label="Country" />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};
