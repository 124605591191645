import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";
import SlideshowDetailModal from "./SlideshowDetailModal";
import { deleteSlideshow } from "services/SlideshowService";
import { useSelector } from "react-redux";

const SlideshowItem = ({
  id,
  image,
  title,
  description,
  mediaList,
  startDate,
  endDate,
  runIndefinitely,
  screenFit,
  spacing,
  venueId,
  isSelected,
}) => {
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const itemSize = spacing * 5;
  const fontSize = Math.max(12, spacing / 2); // Dynamically adjust font size
  const subdomain = useSelector((state) => state.subdomain.value);

  const toggleDetailModal = () => setShowDetailModal(!showDetailModal);

  const confirmDeleteHandler = async () => {
    try {
      await deleteSlideshow(id, subdomain);
      setIsDeleted(true);
    } catch (error) {
      console.error("Error deleting slideshow:", error);
    }
  };

  const truncateTitle = (text, maxLength = 20) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  useEffect(() => {
    if (isSelected) {
      setShowDetailModal(true);
    }
  }, [isSelected]);

  if (isDeleted) {
    return null;
  }

  return (
    <React.Fragment>
      <SlideshowDetailModal
        open={showDetailModal}
        onClose={toggleDetailModal}
        id={id}
        title={title}
        description={description}
        startDate={startDate}
        endDate={endDate}
        runIndefinitely={runIndefinitely}
        mediaList={mediaList}
        onDelete={confirmDeleteHandler}
        screenFit={screenFit}
        venueId={venueId}
      />

      <Box sx={{ width: `${itemSize}px`, m: 0, textAlign: "center" }}>
        <Box
          sx={{
            cursor: "pointer",
            width: "100%",
            height: `${itemSize}px`,
            borderRadius: "10px",
            overflow: "hidden",
            backgroundImage: `url(${image})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: "0px 4px 10px rgba(0,0,0,0.3)",
            },
          }}
          onClick={toggleDetailModal}
        />
        <MDTypography variant="h6" sx={{ fontSize: `${fontSize}px`, mt: 1 }}>
          {truncateTitle(title)}
        </MDTypography>
      </Box>

      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        lineHeight={1}
      ></MDBox>
    </React.Fragment>
  );
};

SlideshowItem.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  runIndefinitely: PropTypes.bool.isRequired,
  mediaList: PropTypes.arrayOf(
    PropTypes.shape({
      media: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        // Add any other properties that 'media' object might have
      }).isRequired,
      duration: PropTypes.number,
    })
  ).isRequired,
  spacing: PropTypes.number.isRequired,
};

export default SlideshowItem;
