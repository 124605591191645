import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardContent,
  CardMedia,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import Modal from "shared/components/UIElements/Modal/Modal";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";
import MDButton from "shared/components/MDComponents/MDButton";
import { updateMedia } from "services/MediaService";

const MediaDetailModal = ({
  open = false,
  onClose,
  id,
  title = "",
  folder = {},
  mediaUrl = "",
  originalUrl = "",
  folders = [],
  onDelete,
  venueId,
  onUpdate,
}) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editTitle, setEditTitle] = useState(title);
  const [editFolder, setEditFolder] = useState(folder.id || "");
  const user = useSelector((state) => state.user.user);
  const subdomain = useSelector((state) => state.subdomain.value);
  const theme = useTheme();
  const darkMode = theme.darkMode;

  useEffect(() => {
    setEditTitle(title);
    setEditFolder(folder.id);
  }, [title, folder.id]);

  const openConfirmationDialog = () => setOpenConfirm(true);
  const closeConfirmationDialog = () => setOpenConfirm(false);
  const handleDelete = () => {
    onDelete();
    closeConfirmationDialog();
  };

  const handleEditToggle = () => {
    setEditing(!editing);
  };

  const handleCancelEdit = () => {
    setEditing(false);
    setEditTitle(title);
    setEditFolder(folder.id);
  };

  const handleSave = async () => {
    const updatedMedia = {
      name: editTitle,
      folder: editFolder,
      url: originalUrl,
      venue: venueId,
    };

    try {
      const updatedMediaData = await updateMedia(id, updatedMedia, subdomain);
      onUpdate(updatedMediaData);
      setEditing(false);
    } catch (error) {
      console.error("Error updating media:", error);
    }
  };

  const isVideo = (url) => {
    const videoExtensions = ["mp4", "mpeg", "mov", "mkv"];
    const extension = url.split(".").pop().toLowerCase();
    return videoExtensions.includes(extension);
  };

  const canDelete =
    user &&
    (user.role === "manager" ||
      user.role === "owner" ||
      user.role === "superadmin");

  return (
    <Modal show={open} onCancel={onClose}>
      <MDBox p={2}>
        <Card>
          <CardContent>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDBox display="flex" alignItems="center" flexGrow={1}>
                <MDTypography variant="h5">
                  {editing ? (
                    <TextField
                      value={editTitle}
                      onChange={(e) => setEditTitle(e.target.value)}
                      fullWidth
                    />
                  ) : (
                    editTitle // Use editTitle here instead of title
                  )}
                </MDTypography>
              </MDBox>
              <MDBox>
                {editing ? (
                  <>
                    <IconButton
                      sx={{
                        color: darkMode ? "#90caf9" : "#0d47a1",
                        "&:hover": {
                          color: "#FF4081",
                        },
                      }}
                      onClick={handleSave}
                      color="primary"
                    >
                      <SaveIcon />
                    </IconButton>
                    <IconButton
                      sx={{
                        color: darkMode ? "#90caf9" : "#0d47a1",
                        "&:hover": {
                          color: "#FF4081",
                        },
                      }}
                      onClick={handleCancelEdit}
                      color="secondary"
                    >
                      <CancelIcon />
                    </IconButton>
                  </>
                ) : (
                  <IconButton
                    sx={{
                      color: darkMode ? "#90caf9" : "#0d47a1",
                      "&:hover": {
                        color: "#FF4081",
                      },
                    }}
                    onClick={handleEditToggle}
                    color="primary"
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </MDBox>
            </MDBox>
            <Divider sx={{ my: 2 }} />
            {isVideo(originalUrl) ? (
              <CardMedia
                component="video"
                src={mediaUrl}
                controls
                sx={{ maxHeight: 250, objectFit: "cover", margin: "0 auto" }}
              />
            ) : (
              <CardMedia
                component="img"
                image={mediaUrl}
                alt={title}
                sx={{ maxHeight: 250, objectFit: "cover", margin: "0 auto" }}
              />
            )}
            <MDBox display="flex" justifyContent="center" mt={2}>
              {editing ? (
                <FormControl fullWidth>
                  <InputLabel>Move media to folder</InputLabel>
                  <Select
                    value={editFolder}
                    onChange={(e) => setEditFolder(e.target.value)}
                    fullWidth
                    label="Move media to folder"
                    sx={{ height: "56px" }}
                  >
                    {folders.map((folder) => (
                      <MenuItem key={folder.id} value={folder.id}>
                        {folder.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <MDTypography variant="subtitle1" align="center">
                  Folder: {folder.name || "No folder"}
                </MDTypography>
              )}
            </MDBox>
          </CardContent>
        </Card>
        {canDelete && (
          <MDBox display="flex" justifyContent="center" mt={2}>
            <MDButton
              variant="contained"
              size="small"
              color="error"
              onClick={openConfirmationDialog}
            >
              DELETE
            </MDButton>
          </MDBox>
        )}
      </MDBox>

      <Dialog
        open={openConfirm}
        onClose={closeConfirmationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this media item? This action cannot
            be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleDelete} variant="contained" color="error">
            Delete
          </MDButton>
          <MDButton
            onClick={closeConfirmationDialog}
            color="dark"
            variant="contained"
          >
            Cancel
          </MDButton>
        </DialogActions>
      </Dialog>
    </Modal>
  );
};

export default MediaDetailModal;
