import React from "react";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";
import MediaDirectory from "features/media/components/MediaDirectory";
import PlaylistDroppableArea from "shared/components/UIElements/Dropzone/PlaylistDroppableArea";

function PlaylistMedia({ setMediaList, mediaList }) {
  const handleDrop = (droppedItem) => {
    const newItem = {
      id: droppedItem.id?.toString() || "",
      name: droppedItem.name || "",
      url: droppedItem.url || "",
      signedImageUrl: droppedItem.signedImageUrl || "",
      uniqueId: droppedItem.uniqueId || `${droppedItem.id}-${Date.now()}`,
      index: mediaList.length,
    };

    setMediaList((prevItems) => [...prevItems, newItem]);
  };

  const handleRemoveItem = (index) => {
    const updatedItems = mediaList.filter((_, idx) => idx !== index);
    setMediaList(updatedItems);
  };

  const moveItem = (dragIndex, hoverIndex) => {
    const updatedItems = [...mediaList];
    const [draggedItem] = updatedItems.splice(dragIndex, 1);
    updatedItems.splice(hoverIndex, 0, draggedItem);
    setMediaList(updatedItems);
  };

  return (
    <MDBox p={2}>
      <MDTypography variant="h5">Add Media</MDTypography>
      <MDBox mt={2}>
        <MediaDirectory
          onMediaClick={handleDrop}
          onDrop={handleDrop}
          mediaList={mediaList}
          setMediaList={setMediaList}
          mediaType="videos"
        />
      </MDBox>
      <MDBox mt={2}>
        <PlaylistDroppableArea
          items={mediaList}
          handleDrop={handleDrop}
          handleRemoveItem={handleRemoveItem}
          onMoveItem={moveItem}
        />
      </MDBox>
    </MDBox>
  );
}

export default PlaylistMedia;
