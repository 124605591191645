import MediaItem from "./MediaItem";

const MediaList = (props) => {
  const {
    item,
    spacing,
    selectedFolder,
    selectedItemId,
    folders,
    onMediaClick,
  } = props;

  return (
    <MediaItem
      id={item.id}
      name={item.name}
      url={item.url}
      signedImageUrl={item.signedImageUrl}
      folder={item.folder}
      venueId={item.venue}
      spacing={spacing}
      selectedFolder={selectedFolder}
      isSelected={item.id === selectedItemId}
      folders={folders}
      onMediaClick={onMediaClick}
    />
  );
};

export default MediaList;
