import React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  FilterButton,
  CreateButton,
  ExportButton,
  TopToolbar,
  TextInput,
} from "react-admin";
import ThreeDotsMenu from "../users/ThreeDotsMenu";

const ResourceFilters = [
  <TextInput label="Search by customer name" source="q" alwaysOn />,
];

// Custom list actions including Create, Export, and Filter
const ResourceListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const ResourceList = (props) => (
  <List
    {...props}
    filters={ResourceFilters}
    resource="tenant/resource"
    actions={<ResourceListActions />}
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="organizationName" label="Customer" />
      <NumberField source="currentScreens" label="Current Screens" />
      <NumberField source="maxScreens" label="Max Screens" />
      <NumberField source="currentVenues" label="Current Venues" />
      <NumberField source="maxVenues" label="Max Venues" />
      <NumberField source="currentUsers" label="Current Users" />
      <NumberField source="maxUsers" label="Max Users" />
      <NumberField source="currentContentItems" label="Current Content Items" />
      <NumberField source="maxContentItems" label="Max Content Items" />
      <NumberField source="totalMediaSize" label="Current Media Size (MB)" />
      <NumberField source="maxMediaSize" label="Max Media Size (MB)" />
      <ThreeDotsMenu resource="resource" />
    </Datagrid>
  </List>
);
