import React from "react";
import { useRecordContext, EditButton, DeleteButton } from "react-admin";
import { IconButton, Menu, MenuItem, Button } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";

const ThreeDotsMenu = ({ resource, onDelete }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const record = useRecordContext();

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleTenantDelete = (event) => {
    event.stopPropagation();
    handleCloseMenu(event);
    if (onDelete) {
      onDelete(record); // Pass tenant record to onDelete
    }
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            handleCloseMenu(event);
          }}
        >
          <EditButton record={record} label="Edit" />
        </MenuItem>
        {resource === "tenant" ? (
          <MenuItem onClick={handleTenantDelete}>
            <Button
              variant="text"
              color="error"
              startIcon={<DeleteIcon />}
              sx={{
                textTransform: "none",
                fontWeight: "bold",
              }}
            >
              Delete
            </Button>
          </MenuItem>
        ) : resource !== "billing" && resource !== "resource" ? (
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              handleCloseMenu(event);
            }}
          >
            <DeleteButton record={record} label="Delete" />
          </MenuItem>
        ) : null}
      </Menu>
    </>
  );
};

export default ThreeDotsMenu;
