import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";
import DashboardLayout from "shared/components/UIElements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/components/Navigation/DashboardNavbar";
import ScreenList from "features/screen/components/ScreenList";
import { useSelector } from "react-redux";
import MDButton from "shared/components/MDComponents/MDButton";
import VenueInfo from "../components/dashboard-items/VenueInfo";
import AssignedUsers from "../components/dashboard-items/AssignedUsers";
import { useNavigate } from "react-router-dom";
import { fetchVenueById } from "services/VenueService";
import { fetchScreensByVenue } from "services/ScreenService";
import { CircularProgress } from "@mui/material";

const VenueProfile = () => {
  const [venue, setVenue] = useState(null);
  const [screensData, setScreensData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false); // Track image loading state
  const [spacing] = useState(30);
  const [errorMessage, setErrorMessage] = useState("");
  const selectedVenue = useSelector((state) => state.venue.selectedVenue);
  const { user } = useSelector((state) => state.user);
  const venueId = selectedVenue ? selectedVenue.id : null;
  const navigate = useNavigate();
  const imgRef = useRef(null);
  const [overlayWidth, setOverlayWidth] = useState(0);
  const subdomain = useSelector((state) => state.subdomain.value);

  const updateOverlayWidth = () => {
    const img = imgRef.current;
    if (img && img.offsetWidth && img.offsetHeight) {
      const ratio = img.naturalWidth / img.naturalHeight;
      const height = img.offsetHeight;
      const computedWidth = Math.min(img.offsetWidth, height * ratio);
      setOverlayWidth(computedWidth);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateOverlayWidth);
    return () => window.removeEventListener("resize", updateOverlayWidth);
  }, []);

  useEffect(() => {
    if (imageLoaded) {
      // Delay the update slightly to ensure DOM has stabilized
      updateOverlayWidth();
    }
  }, [imageLoaded]);

  const fetchVenueData = async () => {
    try {
      const venue = await fetchVenueById(venueId, subdomain);
      setVenue(venue);
    } catch (error) {
      console.error("Error fetching venue:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    const fetchVenue = async () => {
      await fetchVenueData();
    };

    const fetchScreens = async () => {
      try {
        const data = await fetchScreensByVenue(venueId, subdomain);
        if (data.screens.length === 0) {
          setErrorMessage(data.message);
        } else {
          setScreensData(data.screens);
        }
      } catch (error) {
        console.error("Error fetching screens:", error);
        setErrorMessage("Error occurred while fetching screens");
        setScreensData([]);
      }
    };

    fetchVenue();
    fetchScreens();
  }, [venueId, subdomain]);

  const screensWithDefaultImage = screensData.map((screen) => ({
    ...screen,
    image:
      screen.image ||
      "https://static6.businessinsider.com/image/5c9911b07563b04a4b7c30bc-1487/screen-shot-2019-03-25-at-122108-pm.png",
  }));

  const adjustedSpacing = Math.max(1, Math.floor(spacing / 10));

  const canEdit =
    user && ["manager", "superadmin", "owner"].includes(user.role);

  if (loading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(80vh - 64px)",
          }}
        >
          <CircularProgress />
        </MDBox>
      </DashboardLayout>
    );
  }

  if (!venue) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox
          mt={10}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "800px",
          }}
        >
          <MDTypography variant="h5" color="text">
            Venue not found. Choose an available venue here.
          </MDTypography>
          <MDBox mt={2}>
            <MDButton
              variant="contained"
              color="dark"
              onClick={() => navigate(`/venues`)}
            >
              Go to Venues
            </MDButton>
          </MDBox>
        </MDBox>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <div
        style={{
          position: "relative",
          width: "100%",
          overflow: "hidden",
          maxHeight: "300px",
          minHeight: "300px",
        }}
      >
        {!imageLoaded && (
          <CircularProgress
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        )}
        <img
          ref={imgRef}
          src={venue?.signedBackgroundUrl || ""}
          onLoad={() => {
            setImageLoaded(true);
            updateOverlayWidth();
          }}
          style={{
            width: "100%",
            maxHeight: "300px",
            objectFit: "contain",
            objectPosition: "left center",
            display: imageLoaded ? "block" : "none",
          }}
          alt="Venue background"
        />
        {imageLoaded && (
          <MDBox
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              background: "rgba(0, 0, 0, 0.5)",
              padding: "16px",
              width: `${overlayWidth}px`,
              textAlign: "left",
            }}
          >
            <MDTypography variant="h4" color="white">
              {venue?.title || "Venue Title"}
            </MDTypography>
          </MDBox>
        )}
      </div>
      <MDBox mt={5} mb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <VenueInfo
              venue={venue}
              onUpdate={fetchVenueData}
              canEdit={canEdit}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AssignedUsers
              venue={venue}
              onUpdate={fetchVenueData}
              canEdit={canEdit}
            />
          </Grid>
        </Grid>
        <MDBox mt={5}>
          <MDTypography variant="h6" fontWeight="medium">
            Screens in this venue
          </MDTypography>
          <MDBox mt={2}>
            {screensWithDefaultImage.length > 0 ? (
              <Grid container spacing={adjustedSpacing}>
                {screensWithDefaultImage.map((screen) => (
                  <Grid item key={screen.id}>
                    <ScreenList item={screen} spacing={spacing} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <MDBox
                mt={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  maxWidth: "800px",
                }}
              >
                <MDTypography variant="h5" color="text">
                  {errorMessage}
                </MDTypography>
                <MDBox mt={2}>
                  <MDButton
                    variant="contained"
                    color="dark"
                    onClick={() => navigate(`/newscreen`)}
                  >
                    Create Screen
                  </MDButton>
                </MDBox>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
};

export default VenueProfile;
