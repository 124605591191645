const API_BASE_URL = process.env.REACT_APP_API_URL;

export const fetchFolderById = async (folderId, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/folder/${folderId}`, {
      headers: {
        "X-Subdomain": subdomain,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch folder");
    }
    const folder = await response.json();
    return folder;
  } catch (error) {
    console.error("Error fetching folder:", error);
    throw error;
  }
};

export const fetchFolders = async (venueId, subdomain) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/folder/venue/${venueId}`,
      {
        headers: {
          "X-Subdomain": subdomain,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch folders: ${response.status}`);
    }
    const folders = await response.json();
    return folders;
  } catch (error) {
    console.error("Error fetching folders:", error);
    throw error;
  }
};

export const createFolder = async (venueId, folderName, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/folder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Subdomain": subdomain,
      },
      body: JSON.stringify({ venue: venueId, name: folderName }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to create folder");
    }

    const data = await response.json();
    return data.folder;
  } catch (error) {
    console.error("Error creating folder:", error);
    throw error;
  }
};

export const updateFolder = async (folderId, folderData, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/folder/${folderId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-Subdomain": subdomain,
      },
      body: JSON.stringify(folderData),
    });

    if (!response.ok) {
      throw new Error("Failed to update folder");
    }

    const data = await response.json();
    return data.folder;
  } catch (error) {
    console.error("Error updating folder:", error);
    throw error;
  }
};

export const deleteFolder = async (folderId, newFolderId, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/folder/${folderId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "X-Subdomain": subdomain,
      },
      body: JSON.stringify({ newFolderId }),
    });

    if (!response.ok) {
      throw new Error("Failed to delete folder");
    }

    return await response.json();
  } catch (error) {
    console.error("Error deleting folder:", error);
    throw error;
  }
};
