import React from "react";
import { Grid } from "@mui/material";
import {
  Edit,
  SimpleForm,
  TextInput,
  useDataProvider,
  useNotify,
  useRedirect,
} from "react-admin";
import CustomToolbar from "../../utils/CustomToolbar";

export const AdminEdit = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit = async (values) => {
    try {
      const { name, email, role } = values;

      const formData = {
        name,
        email,
        role,
      };

      // Call the update API
      await dataProvider.update("superadmins", {
        id: values.id,
        data: formData,
      });

      notify("Admin updated successfully", { type: "info" });
      redirect("list", "superadmins");
    } catch (error) {
      notify(`Error updating admin: ${error.message}`, { type: "error" });
    }
  };

  return (
    <Edit {...props} resource="superadmins">
      <SimpleForm
        toolbar={<CustomToolbar resource="superadmins" />}
        onSubmit={handleSubmit} // Attach the custom handleSubmit
        redirect="list"
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextInput source="name" fullWidth label="Full Name" />
          </Grid>
          <Grid item xs={6}>
            <TextInput source="email" fullWidth label="Email" readOnly />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};
