import React from "react";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  TopToolbar,
  TextInput,
  FilterButton,
  ExportButton,
} from "react-admin";
import { Box } from "@mui/material";
// import DateRangeInput from "admin/utils/DateRangeInput";

// Define the filters, including the custom date range filter
const PaymentFilters = [
  <TextInput label="Search by tenant name" source="q" alwaysOn />,
  //   <DateRangeInput label="Payment Date Range" />, // Use DateRangeInput here
];

const PaymentListActions = () => (
  <TopToolbar>
    <FilterButton />
    <ExportButton />
  </TopToolbar>
);

const statusColors = {
  succeeded: "green",
  failed: "red",
};

const PaymentStatusBubble = ({ record }) => {
  const status = record.paymentStatus;
  const color = statusColors[status] || "grey";

  return (
    <Box
      component="span"
      sx={{
        backgroundColor: color,
        color: "white",
        padding: "4px 8px",
        borderRadius: "12px",
        fontSize: "0.875rem",
        textTransform: "capitalize",
      }}
    >
      {status}
    </Box>
  );
};

const formatAmount = (amount, currency) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(amount / 100);
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return `${formattedTime}, ${formattedDate}`;
};

export const PaymentList = (props) => (
  <List
    {...props}
    filters={PaymentFilters}
    resource="payment"
    actions={<PaymentListActions />}
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="tenant.organizationName" label="Tenant Name" />
      <FunctionField
        label="Amount Paid"
        render={(record) => formatAmount(record.amountPaid, record.currency)}
      />
      <TextField source="currency" label="Currency" />
      <TextField source="invoiceId" label="Invoice ID" />
      <FunctionField
        label="Status"
        render={(record) => <PaymentStatusBubble record={record} />}
      />
      <FunctionField
        label="Payment Date"
        render={(record) => formatDate(record.paymentDate)}
      />
    </Datagrid>
  </List>
);
