import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { performGlobalSearch } from "services/SearchService";
import {
  Box,
  TextField,
  CircularProgress,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import useDebounce from "shared/hooks/useDebounce";
import { styled, useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";

const StyledBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "100%",
  left: 0,
  right: 0,
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[3],
  zIndex: 10,
  maxHeight: "200px",
  overflowY: "auto",
  width: "100%",
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(1),
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  color: theme.palette.text.primary,
  fontSize: "1rem", // Match text field font size
}));

const ItemText = styled(Typography)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  color: theme.palette.text.primary,
  fontSize: "inherit", // Inherit font size from StyledListItem
}));

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

function GlobalSearch() {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(searchQuery, 500);
  const navigate = useNavigate();
  const theme = useTheme();
  const subdomain = useSelector((state) => state.subdomain.value);
  const currentVenue = useSelector((state) => state.venue.selectedVenue);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setLoading(true);
      performGlobalSearch(debouncedSearchTerm, subdomain, currentVenue?.id)
        .then((results) => {
          setSearchResults(results.slice(0, 10));
          setLoading(false);
        })
        .catch((error) => {
          console.error("Search failed:", error);
          setSearchResults([]);
          setLoading(false);
        });
    } else {
      setSearchResults([]);
      setLoading(false);
    }
  }, [debouncedSearchTerm]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleClickResult = (result) => {
    const state = { selectedItemId: result.id, folderName: result.folder };

    switch (result.type) {
      case "Schedule":
        navigate("/schedules", { state });
        break;
      case "Media":
        navigate("/media", { state });
        break;
      case "Playlist":
        navigate("/playlists", { state });
        break;
      case "Slideshow":
        navigate("/slideshows", { state });
        break;
      case "Screen":
        navigate("/screens", { state });
        break;
      default:
        console.log("Unknown type:", result.type);
    }
    setSearchResults([]);
  };

  return (
    <Box
      position="relative"
      width="100%"
      maxWidth={{ xs: "100%", lg: "600px" }}
    >
      <TextField
        label="Search here"
        value={searchQuery}
        onChange={handleSearchChange}
        fullWidth
        InputProps={{
          endAdornment: loading ? (
            <CircularProgress size={20} color="inherit" />
          ) : null,
        }}
        sx={{ width: "100%" }}
      />
      {searchQuery && (
        <StyledBox theme={theme}>
          {loading ? (
            <List>
              <StyledListItem theme={theme}>
                <CircularProgress size={24} />
              </StyledListItem>
            </List>
          ) : searchResults.length > 0 ? (
            <List>
              {searchResults.map((result, index) => (
                <StyledListItem
                  button
                  key={index}
                  onClick={() => handleClickResult(result)}
                  theme={theme}
                >
                  <ItemText theme={theme}>
                    <span>{truncateText(result.title, 25)}</span>
                    <span>{truncateText(result.type, 25)}</span>
                  </ItemText>
                </StyledListItem>
              ))}
            </List>
          ) : (
            <List>
              <StyledListItem theme={theme}>No results found</StyledListItem>
            </List>
          )}
        </StyledBox>
      )}
    </Box>
  );
}

export default GlobalSearch;
