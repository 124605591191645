import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "shared/components/Navigation/Sidenav";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import routes from "routes";
import { useMaterialUIController } from "./context/ComponentContext";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ScreenStream from "features/screen/pages/ScreenStream";
import ProtectedRoute from "auth/components/ProtectedRoute";
import Unauthorized from "auth/pages/Unauthorized";
import VerifyQRCode from "auth/components/VerifyQR";
import QRLogin from "auth/components/QRLogin";
import VerifyEmail from "auth/pages/VerifyEmail";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Logout from "auth/components/logout";
import { useAuthManager } from "shared/hooks/useAuthManager";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { setSubdomainAndFetchTenant } from "features/tenant/store/SubdomainSlice";
import RegisterTenant from "features/tenant/pages/RegisterTenant";
import TenantMismatch from "features/tenant/pages/TenantMismatch";
import DomainNotFound from "features/tenant/pages/DomainNotFound";
import { useSubdomainValidation } from "shared/hooks/useSubdomainValidation";
import RegistrationSuccess from "features/tenant/pages/RegistrationSuccess";
import AdminProvider from "context/AdminContext";
import PaymentCancelled from "features/stripe/pages/PaymentCancelled";
import PaymentSuccess from "features/stripe/pages/PaymentSuccess";
import RegisterUser from "features/tenant/pages/RegisterUser";
import PageNotFound from "shared/components/Navigation/PageNotFound/PageNotFound";

export default function App() {
  const [controller] = useMaterialUIController();
  const { layout, sidenavColor, darkMode } = controller;
  const { pathname } = useLocation();
  const { subdomainChecked, subdomainValid } = useSubdomainValidation();
  const { loading, authLoading } = useAuthManager(subdomainValid);
  const { isAuthenticated, isLoading } = useAuth0();
  const dispatch = useDispatch();

  const [isLoggingOut, setIsLoggingOut] = useState(false);

  useEffect(() => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split(".")[0];
    const token = localStorage.getItem("token");
    dispatch(setSubdomainAndFetchTenant(subdomain, token));
  }, [dispatch]);

  const isPageNotFound = pathname === "/404";
  const isAdminRoute = pathname.startsWith("/admin");
  const isScreenStreamRoute = pathname.startsWith("/stream/");
  const isVerifyQRRoute = pathname.startsWith("/verify-qr");
  const isUnauthorizedRoute = pathname === "/unauthorized";
  const isVerifyEmailRoute = pathname === "/verify-email";
  const isLogoutRoute = pathname === "/logout";
  const isRegisterRoute = pathname.startsWith("/register");
  const isTenantMismatchRoute = pathname === "/tenant-mismatch";
  const isDomainNotFoundRoute = pathname === "/domain-not-found";
  const isRegistrationSuccessRoute = pathname.startsWith(
    "/registration-success"
  );
  const isPaymentCancelledRoute = pathname === "/payment-cancelled";
  const isPaymentSuccessRoute = pathname === "/payment-success";

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    if (isLogoutRoute) {
      setIsLoggingOut(true);
    }
  }, [isLogoutRoute]);

  if (!subdomainChecked || isLoggingOut) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (subdomainValid && (loading || authLoading || isLoading)) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const renderRoutes = () => (
    <Routes>
      <Route path="/admin" element={<Navigate to="/admin/dashboard" />} />
      <Route path="/" element={<Navigate to="/venues" replace />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      {routes.map((route) =>
        route.collapse
          ? route.collapse.map((subRoute) => (
              <Route
                path={subRoute.route}
                element={
                  <ProtectedRoute requiredRoles={subRoute.requiredRoles}>
                    {subRoute.component}
                  </ProtectedRoute>
                }
                key={subRoute.key}
              />
            ))
          : route.type !== "divider" && (
              <Route
                path={route.route}
                element={
                  <ProtectedRoute requiredRoles={route.requiredRoles}>
                    {route.component}
                  </ProtectedRoute>
                }
                key={route.key}
              />
            )
      )}
      <Route path="/qr-login" element={<QRLogin />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/404" element={<PageNotFound />} />
      <Route path="/logout" element={<Logout />} />{" "}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );

  if (
    isAuthenticated &&
    !isLogoutRoute &&
    !isScreenStreamRoute &&
    !isPageNotFound
  ) {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <DndProvider backend={HTML5Backend}>
          {layout === "dashboard" &&
            !isAdminRoute &&
            !isUnauthorizedRoute &&
            !isLogoutRoute &&
            !isVerifyQRRoute &&
            !isVerifyEmailRoute &&
            !isPaymentCancelledRoute &&
            !isPaymentSuccessRoute && (
              <Sidenav
                color={sidenavColor}
                brand={""}
                brandName="SeriouslyDS"
                routes={routes}
              />
            )}
          {isAdminRoute ? (
            // Wrap admin routes with AdminProvider
            <AdminProvider>{renderRoutes()}</AdminProvider>
          ) : (
            renderRoutes()
          )}
        </DndProvider>
      </ThemeProvider>
    );
  }

  // Handle special routes like logout, verify email, etc.
  if (
    isScreenStreamRoute ||
    isVerifyQRRoute ||
    isLogoutRoute ||
    isRegisterRoute ||
    isTenantMismatchRoute ||
    isDomainNotFoundRoute ||
    isRegistrationSuccessRoute ||
    isPaymentCancelledRoute ||
    isPaymentSuccessRoute
  ) {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <DndProvider backend={HTML5Backend}>
          <Routes>
            <Route path="/stream/:screenId" element={<ScreenStream />} />
            <Route
              path="/verify-qr"
              element={
                <ProtectedRoute>
                  <VerifyQRCode />
                </ProtectedRoute>
              }
            />
            <Route path="/logout" element={<Logout />} />
            <Route path="/register" element={<RegisterTenant />} />
            <Route path="/register-user" element={<RegisterUser />} />
            <Route path="/tenant-mismatch" element={<TenantMismatch />} />
            <Route path="/domain-not-found" element={<DomainNotFound />} />
            <Route path="/payment-cancelled" element={<PaymentCancelled />} />
            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route
              path="/registration-success"
              element={<RegistrationSuccess />}
            />
          </Routes>
        </DndProvider>
      </ThemeProvider>
    );
  }

  // Fallback rendering if no conditions match
  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <DndProvider backend={HTML5Backend}>
        <Routes>
          <Route path="*" element={<PageNotFound />} /> {/* Wildcard route */}
        </Routes>
      </DndProvider>
    </ThemeProvider>
  );
}
